
.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  height: 150px;
  position: relative;

}

   .footer-divider {
    border-top: 2px solid #bca6b5; /* הגדרת צבע וגובה הקו */
    margin-top: 20%;
  
  }
   
  
 .footer-newsletter-wrapper{
       margin-bottom: -30%;
   } 

   
   .footer-newsletter {
    text-align: center;
  }
  
  .footer-newsletter-title {
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .footer-newsletter-description {
    font-size: 14px;
    color: #000000;
  }
  
  .footer-newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .footer-newsletter-input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .footer-newsletter-button {
    padding: 10px 20px;
    font-size: 14px;
    color: #ffffff;
    background-color: #9DC08B;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: "Varela Round", sans-serif;
  }
  
  .footer-newsletter-button:hover {
    background-color: #fffff2;
    color: #003161;
    }
  

  .footer-newsletter-input {
    direction: rtl; /* טקסט יתחיל מימין */
    text-align: right; /* מיושרים לימין */
    font-family: "Varela Round", sans-serif;

  }
  

   .footer-logo {
    width: 90px;
    height: auto;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  .pg-footer {
    color: #000000;
  font-family: "Varela Round", sans-serif;
  }
  
  .footer {
    color: #000000;
    background-color: #f5f7fc;
    border-top-left-radius: 50px; /* רדיוס בפינה השמאלית העליונה */
    border-top-right-radius: 50px; /* רדיוס בפינה הימנית העליונה */
    overflow: hidden; /* מוודא שהרקע לא יחרוג */
    border-top: 2px solid #e0e5f1; /* מסגרת רק בחלק העליון */

}

  
  /* .footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
  }
  .footer-wave-path {
    fill: #e0e5f1;
  } */


  
  .footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #000000;
  }
  
  .footer-content-column ul li a {
    color: #000000;
    text-decoration: none;
  }
  
  .footer-logo-link {
    display: inline-block;
  }
  .footer-menu {
    margin-top: 30px;
  }
  
  .footer-menu-name {
    direction: rtl;
    text-align: center;
    color: #003161;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: 0.1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  #menu-get-started{
    direction: rtl;
  }
  .footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
  }
  .footer-menu-list li {
    margin-top: 5px;
  }
  
  .footer-call-to-action-description {
    color: #000000;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: #003161;
  }
  .button:last-of-type {
    margin-right: 0;
  }
  .footer-call-to-action-button {
    background-color:#9DC08B;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.2s;
    cursor: pointer;
    position: relative;
  }
  .footer-call-to-action {
    margin-top: 30px;
  }
  .footer-call-to-action-title {
    color: #003161;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  .footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #000000;
    text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
    color: #000000;
    text-decoration: none;
  }






  .footer-social-links {
    display: flex;
    justify-content: center; /* ממקם את האייקונים במרכז */
    align-items: center;
    gap: 20px; /* מרווח שווה בין האייקונים */
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    height: 54px;
}

.footer-social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* גודל אחיד לכל כפתור */
    height: 40px;
    background-color: #f5f7fc; /* רקע לבן */
    border-radius: 50%; /* רקע עגול */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* אפקט צל */
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    border: 1px solid transparent; /* מסגרת שקופה כברירת מחדל */
}

/* מסגרת לכל כפתור לפני ה-hover */
.footer-social-link.linkedin {
    border-color: #0077b5;
}
.footer-social-link.facebook {
    border-color: #1877f2;
}
.footer-social-link.instagram {
    border-color: #f64bb7;
}
.footer-social-link.youtube {
    border-color: #ff0000;
}

.footer-social-icon {
    color: #333; /* צבע ברירת מחדל */
    font-size: 20px; /* גודל אחיד לכל אייקון */
    transition: color 0.3s ease-in-out;
}

/* שינוי צבעים בהובר */
.footer-social-link.linkedin:hover {
    background-color: #0077b5;
}
.footer-social-link.linkedin:hover .footer-social-icon {
    color: white;
}

.footer-social-link.facebook:hover {
    background-color: #1877f2;
}
.footer-social-link.facebook:hover .footer-social-icon {
    color: white;
}

.footer-social-link.instagram:hover {
    background-color: #f64bb7;
}
.footer-social-link.instagram:hover .footer-social-icon {
    color: white;
}

.footer-social-link.youtube:hover {
    background-color: #ff0000;
}
.footer-social-link.youtube:hover .footer-social-icon {
    color: white;
}


  

.footer-copyright {
  background: linear-gradient(to top, #e0e5f1c6 30%, #f5f7fc 100%);
  color: #000000;
  padding: 15px 30px;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

  
  .footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    
  }
  
  .footer-copyright-text {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .footer-copyright-link {
    color: #000000;
    text-decoration: none;
  }
  



   @media (min-width: 320px) and (max-width: 479px) {
    
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      position: relative;
      height: 100%;

    }
    .footer-newsletter-wrapper{
      margin-bottom: 0;
  } 
  .footer-copyright{
    padding: 0px;
  }
  .footer-newsletter{
    height: 180px;
  }
 }
  @media (min-width: 480px) and (max-width: 599px) {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      /* padding: 40px 15px 1050px; */
      position: relative;
    }
    .footer-copyright{
      padding: 0px;
    }
    .footer-newsletter{
      height: 180px;
    }
  }
  @media (min-width: 600px) and (max-width: 800px) {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      /* padding: 40px 15px 1050px; */
      position: relative;
      height: 100%;

    }
    .footer-copyright{
      padding: 0px;
    }
    .footer-newsletter{
      height: 180px;
    }
  }
  @media (min-width: 801px) {
  }
  @media (min-width: 1025px) {
  }
  @media (min-width: 1281px) {
  }
  
  @media (min-width: 760px) {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 300px;
      position: relative;
    }
  
    .footer-wave-svg {
      height: 50px;
    }
  
    .footer-content-column {
      width: 24.99%;
    }
  }
