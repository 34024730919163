
.plan-selection-container {
    /* border: 1px solid #9DC08B; 
       border-radius: 12px;*/
    text-align: center;
    direction: rtl;
    width: 100%;
    max-width: 100%;
    padding-right: 80px;
    padding-left: 80px;
    padding-bottom: 100px;
}

.plan-selection-wrapper {
    display: flex;
    justify-content: center; /* מרכז אופקית */
    align-items: center; /* מרכז אנכית */
}

.plan-selection-container-h2{
    margin-top: -2%;
    font-size: 25px;
}

.plan-buttons {
    margin: 20px 0;
}

.plan-button {
    font-family: "Varela Round", sans-serif;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1.1em;
    cursor: pointer;
    border: 2px solid #78B3CE;
    border-radius: 10px;
    background-color: #F5ECD5;
    transition: background-color 0.3s;
    color: var(--logoColor);
}

.plan-button:hover {
    background-color: #78B3CE;
    color: white;
}

.plan-selection-container-selected-phonenumber-value {
    direction: rtl;
}

.phone-number-list {
    margin-top: 20px;
}

.next-button {
    font-family: "Varela Round", sans-serif;
    margin-top: 20px;
    padding: 12px 40px;
    font-size: 16px;
    color: #fff;
    background-color: #578E7E;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none;
}

.next-button:hover {
    background-color: #86A788;
}

.next-button:active {
    transform: scale(0.96);
}

.invalid-input {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 0.9em;
}

.terms-checkbox-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: right; /* יישור לימין */
    gap: 10px; /* רווח בין האלמנטים */
    width: 100%;
    padding: 10px;
    border-radius: 8px; /* פינות מעוגלות */
}


.terms-checkbox-container input {
    width: 22px;
    height: 22px;
    cursor: pointer;
    vertical-align: middle; 
}

.terms-label {
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.terms-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    margin-right: 4px;
}

.terms-link:hover {
    text-decoration: underline;
}


.terms-link:hover {
    text-decoration: underline;
}

.next-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.plan-selected-highlight {
    font-size: 22px; 
    font-weight: bold;
    color: #003092; 
}




/* עיצוב לכרטיסיות חבילות מחירים */
.plans-container-selection {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .plan-card-selection {
    background: white;
    border: 2px solid #ddd;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
 /* הכרטיסייה האמצעית (המומלצת ביותר) תהיה גדולה יותר */
.plan-card-selection.recommended-selection {
    border: 2px solid #77B254;
    transform: scale(1.1); 
    z-index: 10;
    width: 310px; 
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .recommended-badge-selection {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: #c8ebc5;
    color: #004a5e;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .plan-name-selection {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .plan-description-selection {
    color: #666;
  }
  
  .plan-price-selection {
    direction: rtl;
    font-size: 2rem;
    font-weight: bold;
    color: #004a5e;
  }
  
  .plan-yearly-selection {
    font-size: 0.9rem;
    color: #666;
  }
  
  .try-button-selection {
    font-family: "Varela Round", sans-serif;
    background: #77B254;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 10px;
    transition: background 0.3s;
  }
  
  .try-button-selection:hover {
    background: #003645;
  }
  
  .plan-features-selection {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .plan-features-selection li {
    text-align: right;
    padding: 5px 0;
    font-size: 0.9rem;
    margin-bottom: 5%;
  }
  


/* הסתרת בינתיים המידע נוסף על כל חבילה */
.plan-features-selection {
    display: none; 
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

