.Menu {
  font-family: 'Outfit', sans-serif;
  width: 210px; 
  background-color: #F6F5F2; 
  transition: width 0.3s ease; 
  min-height: 100vh; 
  flex-direction: column;
  justify-content: space-between; 
  padding-top: 30px;
  overflow: hidden;
  position: relative;
}

.menuIconPersonalArea {
  font-size: 22px !important;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu.collapsed {
  width: 50px; 
}

.Menu.collapsed .Menu-items,
.Menu.collapsed .menuLogoContainer,
.Menu.collapsed .logoutBtn {
  display: none; 
}

.Menu.collapsed .CloseMenubtn {
  position: absolute;
  top: 10px;
  right: 10px; 
  display: flex; 
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.Menu-items {
  flex-grow: 1; 
  overflow-y: auto; 
  overflow-x: hidden;
  max-height: calc(100vh - 60px); 
}

.Menu-items::-webkit-scrollbar {
  width: 10px; 
  background-color: #f0f0f0; 
}

.Menu-items::-webkit-scrollbar-track {
  background-color: #ededed; 
  border-radius: 5px; 
}

.Menu-items::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 5px; 
  border: 2px solid #f0f0f0; 
}

.Menu-items::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}


.Menu-items ul {
  font-family: 'Outfit', sans-serif !important;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.Menu-items ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Menu-items ul li a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  gap: 10px; 
  white-space: nowrap; 
}

.Menu-items ul li a span {
  position: relative;
  display: inline-block;
  color: inherit !important;
  text-decoration: none;
  font-family: 'Outfit', sans-serif !important;
}

.Menu-items ul li a span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; 
  width: 0%;
  height: 2px;
  background-color: #76ABAE; 
  transition: width 0.3s ease-in-out;
}

.Menu-items ul li a:hover span::after {
  width: 100%;
}


.CloseMenubtn {
  position: absolute;
  top: 10px; 
  right: 10px; 
  background: #7a7a7a;
  border: none;
  padding: 8px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  z-index: 10; 
}

.CloseMenubtn:hover {
  background-color: #555; 
}

.CloseMenubtn .MuiSvgIcon-root {
  font-size: 20px; 
}

.logoutBtn {
  font-size: 18px;
  color: black;
  background-color: rgba(240, 255, 255, 0);
  border: none;
  display: flex;
  align-items: center;
  gap: 25px; 
  cursor: pointer;
  padding: 10px 15px; 
}

.logoutIcon{
  transform: rotate(180deg);
}



.userMenu {
  font-family: 'Outfit', sans-serif !important;
  font-size: 17px; 
  color: #333; 
}


.menuLogoContainer {
display: flex;
justify-content: center;
align-items: center;
margin-top: -8%; 
}

.menuLogo {
width: 70px; 
height: auto;
border-radius: 50px;
}

.menu-spacer {
  flex-grow: 1; 
  height: 20px; 
}


