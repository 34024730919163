.chatpage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #d7dbd6;
    position: relative;
    overflow-x: hidden; 
    overflow-y: hidden; 
    
}


.chatpage-container {
    width: 100%;
    height: 100%;
    z-index: 100; /* Ensure this is above the ::before element */
    display: flex;
}


.chatpage-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sidebar {
    flex: 1;
    max-width: 25%; /* Adjust to your preferred width */
    background-color: #f1f1f1;
    border-right: 1px solid #ddd;
}

.chat-container {
    flex: 2;
    min-width: 50%; /* Ensure it takes at least half the space */
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chat-contacts-timeline {
    flex: 1;
    max-width: 25%; /* Adjust to your preferred width */
    border-left: 1px solid #ddd;
}
