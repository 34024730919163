/* Container for the widget button and widget itself */
.waba-chat-widget-container {
    position: fixed;
    top: 84%; /* Move widget to the top */
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Default alignment to the right */
  }
  
  /* Position when it's on the right */
  .right-position {
    right: 10px;
  }
  
  /* Position when it's on the left */
  .left-position {
    left: 10px; /* Align the widget to the left */
  }
  
  .waba-chat-widget-container.left-position {
    left: 20px;
  }
  
  .waba-chat-header.left-position {
    right: 200px;
  }
  
  .waba-chat-widget-open.left-position {
    left: 5px;
  }
  
  .waba-chat-widget-open.right-position {
    right: 30px;
  }
  
  .waba-chat-widget-button.right-position {
    right: 20px;
  }
  
  .waba-chat-widget-button.left-position {
    left: 20px;
  }
  
  /* The header (bubble text) should stay above the button */
  .waba-chat-bubble-header {
    display: flex;
    flex-direction: row;
    padding: 5px;
    cursor: pointer;
    align-items: center;
    position: absolute;
    top: -60px; /* Adjust this value based on how far you want the widget to be above the button */
  }
  
  .waba-chat-bubble-header.right-position {
    right: 10px;
  }
  
  /* Updated layout for the header, remove button on the opposite side of the text */
  .waba-chat-bubble-header.left-position-header {
    flex-direction: row-reverse; /* Switch the order when the position is left */
    left: 5px;  /* Adjust position for left alignment */
    gap: 8px;  /* Adjust gap between text and remove button */
    max-width: 250px; /* Allow the header width to adjust dynamically */
    text-align: left; /* Ensure text aligns correctly */
  }
  
  /* Styling for the text inside the bubble */
  .waba-chat-bubble-text {
    color: var(--LogoColor);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    background: white;
    border: 2px solid var(--LogoColor);
    border-radius: 5px;
    max-width: 200px; /* Prevent the text from overflowing */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Remove the icon inside the bubble */
  .remove-chat-bubble {
    font-size: 25px;
    cursor: pointer;
    margin-left: 10px; /* Give some space between remove button and the text */
  }
  
  /* Chat widget button */
  .waba-chat-widget-button {
    background-color: #25d366;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: relative;
    top: 0; /* Adjust widget button's position */
    right: 15px; /* Adjust position on the right */
  }
  
  /* Change the icon to a down arrow when the widget is open */
  .waba-chat-widget-button.open .waba-whatsapp-icon {
    transform: rotate(180deg); /* Rotate the icon to show a downward arrow */
  }
  
  /* Icons */
  .waba-whatsapp-icon {
    font-size: 40px;
    color: white;
    transition: transform 0.3s ease-in-out; /* Smooth icon transition */
  }
  
  /* Chat widget open state */
  .waba-chat-widget-open {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -180px; /* Position the widget above the button */
    right: 10px;
  }
  
  /* Styling for the minimized state */
  .waba-chat-widget-minimized {
    width: 50px;
    height: 50px;
    background-color: #25d366;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  /* Expand button inside minimized widget */
  .waba-expand-button {
    background: transparent;
    border: none;
    font-size: 22px;
    color: white;
    cursor: pointer;
  }
  
  /* Class for the Open Form Button */
  .open-form-button {
    padding: 10px 20px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .open-form-button-text {
    color: var(--openFormButtonTextColor, #000000);
  }
  
  .open-form-button-icon {
    color: #fff;
  }
  
  .open-form-button:hover {
    opacity: 0.9;
  }
  
  /* Powered by Gambot text and image alignment */
  .powered-by-section {
    text-align: center;
    font-size: 14px;
    color: #808080;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .powered-by-section a {
    color:var(--LogoColor) !important;
    text-decoration: none;
    font-weight: bold;
    padding-left: 5px;
  }
  
  .powered-by-section a:hover {
    color: #1a5b8e !important;
  }
  
  .powered-by-gambot-logo {
    width: 20px;
    height: auto;
  }
