
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.app-container {
  display: flex; 
  height: 100vh; 
}

html, body {
  margin: 0;
  padding: 0;
  height: auto;/* שיניתי פה שהגובה יהיה אוטומטי ולא מאה אחוז */
  overflow-x: hidden;
}

.App {
  text-align: center;
  height: 100%; 
  display: flex; 
  flex-direction: column; 
  overflow-x: hidden; 
  overflow-y: hidden; 
}

#root {
  height: 100%; 
}


body{
  overflow-x: hidden; 
  overflow-y: hidden; 
  height: 100%;
  width: 100%;
}
.content-column {
  flex: 1; 
  width: 100%;
  overflow-y: auto; 

}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
