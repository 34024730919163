/* ChatTimelineMessage.css */
.chat-timeline-message {
    width: 30%;
    align-self: center;
    background-color: #d6ccc1;
    border-radius: 18px;
    margin: 10px 0;
    padding: 10px;
    text-align: center;
    font-style: italic;
    color: #555;
}

.chat-timeline-message-timestamp {
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
    
}
