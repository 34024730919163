
 .menu-logo { 
    width: 60px;
    height: auto;
    margin-right: 10px;
    background-color: transparent; 
    border: none; 
    margin-top: 10%;
    border-radius: 50%;
}


.nav-text a {
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: var(--LogoColor);
}


.nav-text a:hover {
    background-color: transparent; 
}

.nav-text a span {
    display: inline-block;
    background-color: transparent; 
    padding: 12px 20px;
    transition: background-color 0.3s ease;
}

.nav-text a:hover span {
    color: #9DC08B;
}

.nav-link.active span::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: var(--LogoColor); 
    transition: width 0.3s ease;
}

.nav-link span {
    position: relative;
}

.nav-link.active span::after {
    width: 100%;
}

.nav-text a:hover .menu-logo,
.nav-link.active .menu-logo {
    background-color: transparent !important;
    border: none !important;
    pointer-events: none !important;
}


.NavbarMenu {
    background-color: #ffffff;
    position: fixed; 
    margin-top: 0px; 
    left: 0; 
    width: 100%; 
    height: 80px; 
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Varela Round", sans-serif;
    z-index: 1000; 
}

.nav-menu-items {
    display: flex;
    align-items: center;
    justify-content: space-between; /* פיזור שווה של הקישורים */
    list-style: none;
    margin: 0;
    padding: 0;
    direction: rtl; /* מתקן את הסדר בעברית */
    width: 100%; /* מבטיח שהלינקים יתפרשו על כל רוחב הניווט */
  }
  

  .nav-text.login a span,
.nav-text.CreateAccount a span {
    border: 1px solid #9DC08B; 
    padding: 10px 15px;
    border-radius: 8px; /* הופך את הפינות לעגולות */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}





  .nav-text.dropdown .nav-link {
    display: flex;
    align-items: center;
    justify-content: center; 
    cursor: pointer;
    font-family: "Varela Round", sans-serif;
    text-align: center; 
    padding: 10px 15px; 
    width: 100%; 
}


  .dropdown-arrow {
    margin-left: 10px;
    margin-right: 2%;
    transition: transform 0.3s ease;
  }
  
  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  .dropdown-menu {
    /* border: 1px solid #ADA991; 
    border-radius: 5px; */
    background-color: white;
    list-style: none;
    font-family: "Varela Round", sans-serif;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    width: 150px;
  }
  
  .dropdown-menu li {
    padding: 10px;
    font-family: "Varela Round", sans-serif;
  }
  
  .dropdown-menu .dropdown-link {
    font-size: 14px;
    border: none;
    font-family: "Varela Round", sans-serif;
    background-color: white;
    text-decoration: none;
    color: #000000;
    display: block;
    padding: 8px 12px;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;
    position: relative;
}

.dropdown-menu .dropdown-link::after {
    content: "";
    display: block;
    width: 50%; /* רוחב הפס */
    height: 3px; /* גובה הפס */
    background-color: #9DC08B; /* צבע הפס */
    border-radius: 50px; /* הופך את הפס לחצי עגול */
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); /* מיישר את הפס לאמצע */
    transition: width 0.3s ease, background-color 0.3s ease;
    opacity: 0;
}

.dropdown-menu .dropdown-link:hover::after {
    width: 80%; /* מגדיל את הפס בעת מעבר עכבר */
    opacity: 1;
}

  
  .NavbarMenu .dropdown-menu .dropdown-link:hover {
    color: #9DC08B;
  }
  







/* For the logo on mobile view */
.navbar-mobile-logo{
    position: absolute;
    top: 20px; /* Adjust based on where you'd like it */
    right: 20px; /* Position it to the right */
    z-index: 1010;
}

.navbar-mobile-logo img {
    width: 40px; /* Adjust the size of the logo */
    height: auto;
    border-radius: 50%; /* Make the logo round if needed */
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .hamburger-menu {
        display: block;
        position: absolute;
        top: 1rem;
        left: 1rem; /* Position the hamburger on the left */
        z-index: 1010;
        cursor: pointer;
    }

    .nav-menu-items.desktop {
        display: none;
    }

    .nav-menu-items.mobile {
        display: flex;
        flex-direction: column;
        padding-top: 3rem; /* Adjust spacing */
    }

    .navbar-mobile-logo {
        display: block; /* Show logo on mobile */
    }
}






/* תפריט נייד */
.nav-menu-items.mobile {
    background-color: #ffffff;
    margin-right: auto;
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    align-items:flex-start;
    text-align: right;
    z-index: 1000;
    opacity: 0;
    overflow-y: auto;
}

.nav-menu-items.mobile.open {
    transform: scaleX(1);
    opacity: 1;
}


.hamburger-menu {
    display: none;
    position: absolute;
    top: 1rem;
    z-index: 1010;
    cursor: pointer;
    margin-right: 85%;
}

.mobile {
    gap: 2%; 
}


@media (max-width: 768px) {
    .nav-text.login a span,
    .nav-text.CreateAccount a span {
        border: none; /* מסיר את המסגרת */
        padding: 10px 15px; /* שומר על גודל כפתור */
    }
}

@media (min-width: 1921px) {
    .menu-logo { 
        width: 100px; 
    }

    .nav-text a {
        font-size: 20px; 
        padding: 18px 25px;
    }

    .nav-menu-items {
        gap: 3%; 
        margin-right: 5%;
    }
}

@media (max-width: 480px) {
    .nav-menu-items.mobile li {
        font-size: 20px; 
        padding: 0.15rem 0; 
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .nav-menu-items.mobile li {
        font-size: 20px; 
        padding: 0.2rem 0;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .nav-menu-items.mobile li {
        font-size: 20px; 
        padding: 0.3rem 0;
    }
}

@media (max-width: 768px) {
    .hamburger-menu {
        display: block;
    }

    .nav-menu-items.desktop {
        display: none;
    }

    .nav-menu-items.mobile {
        display: flex;
    }
}

@media (max-width: 360px) {
    .nav-menu-items.mobile {
        font-size: 20px; 
    }

    .nav-menu-items.mobile li {
        font-size: 20px; 
    }

    .hamburger-menu {
        top: 0.1rem; 
        right: 0.6rem; 
        font-size: 30px; 
    }

    .nav-menu-items.mobile a {
        font-size: 20px; 
        margin: 0; 
    }
}

@media (max-width: 540px) {
    .nav-menu-items.mobile {
        font-size: 20px; 
        padding-top: 0.1rem; 
    }

    .nav-menu-items.mobile li {
        padding: 0.0001rem 0; 
        font-size: 20px; 
    }

    .hamburger-menu {
        top: 1rem;
        right: 0.8rem; 
        font-size: 30px; 
    }

    .nav-menu-items.mobile a {
        padding: 0.001rem 0.6rem; 
        font-size: 20px; 
    }
}


/* מסך מחשב */
@media (min-width: 1600px) and (max-width: 1920px) {
    .menu-logo { 
        height: 65px;
        width: 65px; 
        margin-top: 2;
    }

    .nav-text a {
        font-size: 16px; 
    }

    .nav-menu-items {
     
        margin-right: 3%; 
    }
}

