/* Ensure the modal itself has a fixed height, and allow scrolling inside the modal */
.form-flow-run-history-modal {
    position: fixed;
    top: 40%;
    left: 56%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust width as needed */
    max-height: 70%; /* Adjust height as needed */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 20px;
    overflow: hidden; /* Ensure content doesn't overflow the modal */

}

/* Content area for table should be scrollable */
.form-flow-run-history-modal-content {
    background: #ededed;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-height: 400px; /* Set the maximum height for the scrollable area */
    overflow-y: auto; /* Allow vertical scrolling */
}

/* Table and other elements stay the same */
.form-flow-run-history-modal-history-table {
    width: 100%;
    border-collapse: collapse;
}

.form-flow-run-history-modal-history-table th, .form-flow-run-history-modal-history-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

/* Table headers for better visibility */
.form-flow-run-history-modal-history-table th {
    background-color: #f4f4f4;
}

.form-flow-run-history-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.form-flow-run-history-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
