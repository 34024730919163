.editor-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.editor-textarea {
  width: 100%;
  border: none;
  resize: none;
  font-size: 14px;
  box-sizing: border-box;
  height: calc(100% - 30px); /* Adjusted height for the textarea */
}
.max-length-info {
  font-size: 0.6rem;
  color: #555;
  text-align: left;
  
}
.emoji-picker {
  position: absolute;
  bottom: 0; /* Adjust according to your layout */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000; /* Ensure it's above other content */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.max-length-info span {
  color: red;
}
.toolbar-bodytext {
  background-color: gray;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: right;
  border-top: none;
  margin-top: 0; /* Remove negative margin */
  height: 15px; /* Adjust the toolbar height */
  color: var(--LogoColor);
}

.toolbar-bodytext button {
  background: none;
  border: none;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
}

.toolbar-bodytext button:hover {
  background-color: #e0e0e0;
}

.emoji-picker {
  display: none;
  position: absolute;
  z-index: 2; /* Ensure it's above other elements but below modal overlays */
  bottom: 100%; /* Position above the toolbar */
}

.emoji-picker.active {
  display: block;
}

/* Ensure the last child inside the textarea doesn't create extra space */
.editor-textarea > *:last-child {
  margin-bottom: 0;
}
