.sim-selection-container {
    margin-top: 0;
    margin: 20px;
    text-align: center;
    direction: rtl;
    padding-right: 80px;
    padding-left: 80px;
    font-family: "Varela Round", sans-serif;
}

.sim-selection-container h2{
    margin-top: -6%;
    text-align: center;
    position: relative;
    display: inline-block;
    padding-bottom: 5px; 
    border-bottom: 1px solid #9DC08B; 
}



.sim-selection-button {
    font-family: "Varela Round", sans-serif;
    padding: 12px 40px;
    font-size: 16px;
    color: #fff;
    background-color: #578E7E;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none;
    margin: 10px;
}

.sim-selection-button:hover {
    background-color: #86A788;
}

.sim-selection-button:active {
    transform: scale(0.96);
}


.sim-selection-phone-number-input .invalid-input {
    border-color: red;
}

.sim-selection-phone-number-input .sim-selection-error-message {
    color: red;
    font-size: 0.9em;
}

.sim-selection-phone-number-list {
    margin-top: 20px;
}

.sim-selection-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between; /* Space between buttons */
}

.sim-selection-prev-button, .sim-selection-next-button {
    font-family: "Varela Round", sans-serif;
    padding: 12px 40px;
    font-size: 16px;
    color: #fff;
    background-color: #578E7E;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none;
}

.sim-selection-prev-button:hover, .sim-selection-next-button:hover {
    background-color: #86A788;
}

.sim-selection-prev-button:active, .sim-selection-next-button:active {
    transform: scale(0.96);
}


.sim-selection-phone-number-input-container{
    display: flex;
    width: 100%; 
    position: relative;
    justify-content: flex-end;
    align-items: center; 
    
}



.sim-selection-phone-number-input-field {
    font-family: "Varela Round", sans-serif;
    text-align: left;  
    border-radius: 8px;
    border: 1px solid #ccc;
}
.country{
    justify-content: flex-end;
}
.search-box{
    text-align: left;
}

.forwarding-number-input-container {
    display: flex;
    width: 100%; /* נותן לרכיב לתפוס את כל הרוחב */
    position: relative;
    justify-content: flex-end; /* מיישר את כפתור קידומת המדינה לשמאל */
    align-items: center; /* ממורכז אנכית */
}

.forwarding-number-input {
    width: 100%;
}

.forwarding-number-input-container input {
    flex-grow: 1; /* מגדיל את שדה הקלט שיתפוס את כל הרוחב */
    width: 100%;
    min-width: 300px; /* מונע ממנו להיות קטן מדי */
    max-width: 100%;
    padding: 10px; /* הופך את הקלט ליותר קריא */
    font-size: 16px; /* מגדיל את הטקסט */
    border-radius: 5px; 
    border: 1px solid #ccc; 
}


.forwarding-number-select{
    border-radius: 5px; 
    border: 1px solid #ccc; 
    font-family: "Varela Round", sans-serif;
    width: 25%;
}


/* מוודא שגם ה-container יתפרס במידת הצורך */
.forwarding-number-input-container .react-tel-input {
    width: 100%;
}


.reset-phone-number-button {
    font-family: "Varela Round", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #DCD7C9; 
    font-size: 14px;
    font-weight: bold;
    padding: 12px 20px;
    border: none;
    border-radius: 8px; 
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    margin-top: 10px;
    width: auto;
}


.reset-phone-number-button span {
    margin-left: 10px; 
}



.selected-phone-number {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    direction: rtl; 
}

.phone-wrapper {
    display: flex;
    align-items: center;
    direction: ltr; 
    gap: 1px; 
}

.phone-prefix {
    font-weight: bold;
    white-space: nowrap;
}

.phone-number {
    direction: ltr; 
    font-weight: normal;
}


/* רספונסיביות - במסכים קטנים הכפתור יהיה בגודל מלא */
@media (max-width: 600px) {
    .reset-phone-number-button {
        width: 100%;
    }
}



/* @media (max-width: 600px) {
    .sim-selection-phone-number-input-container{
        margin-left: 0%;
    }
} */
