.message-preview-container {
  font-family: 'Helvetica Neue', sans-serif;
  max-width: 300px;
  background-color: #e5ddd5; /* WhatsApp background color */
  border-radius: 5px;
  margin: 20px auto; /* Center horizontally */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.message-preview-container h3 {
  background-color: #075E54; /* WhatsApp header color */
  color: white;
  margin: 0;
  padding: 8px 15px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.message-preview {
  background-color: #ffffff; /* White background for the message */
  padding: 10px 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  min-height: 60px; /* Give some space for the text */
  text-align: inherit; /* Inherit text alignment from parent */
}

/* Header Styles */
.header-text {
  color: #444; /* Dark grey text, similar to WhatsApp */
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;
  width: 100%; /* Ensure the header text takes full width */
}

/* Media Content Styles */
.media-content img {
  max-width: 100%;
  height: auto;
}

/* Body Styles */
.body-text {
  color: #444; /* Dark grey text, similar to WhatsApp */
  font-size: 14px;
  line-height: 1.4;
}

/* Footer Styles */
.footer-text {
  display: block;
  color: gray; /* Customize the color for the footer text */
  margin-top: 8px;
  font-size: 12px;
}

/* Buttons Styles */
.buttons-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
  margin-top: 10px;
}

.message-button {
  background-color: #34B7F1; /* Example button color, similar to WhatsApp */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 4px 0; /* Space between buttons */
  cursor: pointer; /* Change mouse cursor on hover */
  font-size: 14px;
  width: auto; /* Adjust width based on content */
  min-width: 80%; /* Minimum width */
}
.media-content video, .media-content img {
  max-width: 100%;
  max-height: 500px;
}

.media-content a {
  display: block;
  max-width: 100%;
  color: #007bff;
  text-decoration: none;
}

.media-content a:hover {
  text-decoration: underline;
}
.location-content {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1; /* Change to match the background color */
  border-radius: 10px;
  text-align: left;
}

.location-icon {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  margin-right: 10px;
}

.location-details {
  display: flex;
  flex-direction: column;
}

.location-details p {
  margin: 0;
  font-size: 14px; /* Adjust size as needed */
  color: #333; /* Change to match the text color */
}