/* Styles for the modal */
.new-contact-form-modal {
    font-family: 'Outfit', sans-serif !important;
    color: var(--LogoColor);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Higher z-index to display above other content */
    justify-content: center;
    align-items: center;
    display: flex; /* Center content horizontally and vertically */
  }
  
  /* Styles for the modal content (the form) */
  .new-contact-form-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 30%;
    height: auto;
  }
  
  /* Style for the close button */
  .new-contact-form-close-btn {
    color: var(--LogoColor);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .new-contact-form-header {
    color: var(--LogoColor);
    text-align: center;
    position: relative; /* מאפשר הוספת אלמנטים יחסית לכותרת */
    margin-bottom: 5px; /* מוסיף ריווח קטן בין הכותרת לפס */
    padding: 0; /* ביטול ריווחים נוספים */
}

.new-contact-form-header::after {
    content: ""; /* הוספת אלמנט ריק עבור הפס */
    display: block; /* מאפשר שליטה בגובה ובמיקום */
    width: 70%; /* אורך הפס, מותאם לרוחב הכותרת */
    height: 1px; /* גובה הפס */
    background-color: var(--LogoColor); /* צבע הפס */
    margin: 0 auto; /* יישור הפס למרכז */
    margin-top: 0px; /* מבטל ריווח עליון נוסף */
    margin-bottom: 5%;
}


  .new-contact-form-close-btn {
    margin-top: -7%;
    margin-right: -2%;
    color: #BF3131;
    background: none; /* ללא רקע */
    border: none; /* ללא מסגרת */
    font-size: 30px; /* גודל אייקון סגירה */
    cursor: pointer; /* סמן עכבר לחיץ */
  }
  
  .new-contact-form-close-btn:hover {
    color: #6c4736; /* שינוי צבע במעבר עכבר */
    background-color: #cccccc00;
  }

  .new-contact-keys{
    text-align: left;
  }

  .new-contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* max-width: 300px; */
    width: 90%;
    text-align: left;
  }


  .error {
    color: red;
  }

  .form-group input,
  .phone-input-field {
    font-family: 'Outfit', sans-serif !important;
    font-size: 15px;
    text-align: left;
    width: 100%; /* התאמת הרוחב לכל השדה */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box; /* כולל padding בחישוב הרוחב */
  }

  .phone-input-container {
    display: flex;
    align-items: center;
    width: 100%; /* התאמה לרוחב השדה */
    gap: 10px; /* רווח בין הרשימה הנפתחת לשדה הטלפון */
  }

  .phone-input-container .phone-input-field {
    flex-grow: 1; /* שדה הטלפון יתפוס את כל השטח הזמין */
  }

  .phone-input-container .country-list {
    width: auto; /* הרשימה הנפתחת תתאים את עצמה */
  }

  .phone-input-container .country {
    margin: 0; /* ביטול ריווחים פנימיים */
    padding: 8px;
    text-align: left;
  }

  .phone-input-container .search-box {
    width: 100%;
    box-sizing: border-box;
  }

  .form-group input:hover,
.phone-input-field:hover {
  background-color: #f7f7f7; /* צבע רקע בהיר בעת מעבר עכבר */
  transition: background-color 0.3s ease; /* אפקט מעבר חלק */
}

.form-group input:focus,
.phone-input-field:focus {
  background-color: #FAF7F0; /* צבע רקע ייחודי בעת מיקוד */
  border: 1px solid #B17457; /* הדגשת מסגרת */
  outline: none; /* ביטול מסגרת ברירת מחדל */
  transition: background-color 0.3s ease, border 0.3s ease; /* אפקט מעבר */
}


.new-contact-form-submit {
  font-family: 'Outfit', sans-serif !important;
  color: rgb(255, 255, 255);
  margin-top: 5%;
  font-size: 18px; /* גודל פונט קטן אך קריא */
  background-color: #27548A; /* רקע אפור ניטרלי */
  border: none; /* ללא מסגרת */
  border-radius: 4px; /* פינות מעט מעוגלות */
  padding: 8px 15px; /* ריווח פנימי נעים */
  cursor: pointer; /* סמן עכבר משתנה ללחיץ */
  text-align: center; /* יישור טקסט למרכז */
  transition: background-color 0.3s ease; /* מעבר חלק */
}

/* .new-contact-form-submit:hover {
  color: rgb(255, 255, 255);
  background-color: #578FCA; 
} */





 /* label {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    color: #2f001c;
    font-family: "Delius", serif;
  }
           */