.textarea-container {
    position: relative;
    width: 100%;
  }
  
  .textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
  }
  
  .character-counter {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 12px;
    color: #888;
  }
  