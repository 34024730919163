.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 130vh; */
    padding-top: 20px;
  }
  
  .integration-form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .integrations-form-row {
    margin-bottom: 15px;
  }
  
  /* label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  } */
  
  /* button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  } */
  
  /*
  button:hover {
    background-color: #0056b3;
  }
*/

  .integrations-form-container {
    /* Your container styles */
  }
  
  .integrations-form-header {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the h2 element */
  }
  
  .integrations-form-header h2 {
    flex-grow: 1; /* Allow h2 to take up remaining space */
    text-align: center; /* Center the text */
  }
  
  .integrations-form-header .MuiIconButton-root {
    margin-right: auto; /* Push the icon button to the left */
  }
  .integrations-form-row {
    margin-bottom: 15px;
  }
  
  .integrations-form-row button {
    margin-right: 10px; /* Add margin between the buttons */
  }
  
  .integrations-form-row button:last-child {
    margin-right: 0; /* Remove margin from the last button */
  }
  .integrations-form-ping{
    background-color: var(--LogoColor);
  }