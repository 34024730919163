/* 
  .titleHowWorks {
    display: inline;
  }
  
  .titleHowWorks .highlight {
    margin-right: 5px; 
  }
  

.containerHowWorks {
    background-color: rgba(245, 241, 245, 0.606);
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleHowWorks {
    direction: rtl;
    font-family: "Varela Round", sans-serif;
    --_margin: 3%; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    font-size: 2.2em;
    font-weight: 700;
    position: relative; 
    width: 100%; 
    margin: 20px 0; 

    &::before,
    &::after {
        content: "";
        flex: 1; 
        border: 1px solid #000;
    }

    &:not(:empty)::before {
        margin-right: var(--_margin);
    }

    &:not(:empty)::after {
        margin-left: var(--_margin);
    }
}

  


  
  
.progress-wrap {
    max-width: 100%;
    width: 700px; 
    margin-bottom: 50px; 
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.progress-wrap::before {
    content: "";
    width: 100%;
    height: 6px; 
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
    transition: 0.3s ease-in-out;
    background-color: #e6e6e6;
}

.progress {
    width: 0; 
    height: 6px; 
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
    transition: width 0.3s ease-in-out; 
    background-color: #78B3CE;
}

.step {
    font-family: "Varela Round", sans-serif;
    width: 60px;
    height: 60px;
    font-size: 1.2em; 
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color: #F5ECD5; 
    border: 4px solid #e6e6e6; 
    border-radius: 50%; 
    transition: 0.3s ease-in-out;
    position: relative;
}

.step.active {
    font-size: 1.5em; 
    color: #22177A;
    border-color: #578E7E; 
    background-color: #FFFAEC; 
}


.containerHowWorks-step-content {
    direction: rtl;
    background: linear-gradient(135deg, #C9E6F0, rgba(132, 34, 95, 0.05)); 
    border-radius: 20px;
    padding: 25px; 
    margin-top: 30px; 
    width: 90%; 
    max-width: 800px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); 
    text-align: center; 
    font-family: "Varela Round", sans-serif;
    color: #605EA1; 
    animation: fadeIn 0.3s ease-in-out; 
}

.step-content h2 {
    font-size: 2em; 
    font-weight: bold; 
    margin-bottom: 15px; 
    color: #22177A; 
}

.step-content p {
    font-size: 1.1em; 
    line-height: 1.8; 
    margin: 0; 
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}




.button-container {
    display: flex;
    justify-content: center; 
    gap: 15px;
}

.btn {
    margin: 0;
    padding: 12px 40px; 
    font-size: 16px; 
    color: #fff;
    background-color: #578E7E;
    border-radius: 12px; 
    border: 0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none;
}

.btn:hover {
    background-color: #86A788;
}

.btn:active {
    transform: scale(0.96);
}

.btn:disabled {
    color: #666;
    background-color: #e6e6e6;
    cursor: not-allowed;
}

  .HowWorksH1{
    font-family: "Varela Round", sans-serif;
}




.create-account-btn {
    font-family: "Varela Round", sans-serif;
    background-color:#578E7E;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.2s;
    cursor: pointer;
    position: relative;
}

.create-account-btn:hover {
    background-color: #B6CBBD; 
}

.create-account-btn:active {
    transform: scale(0.96); 
}




.step-icon {
    font-size: 3em; 
    color: #000000; 
    margin-bottom: 10px; 
    animation: fadeIn 0.5s ease-in-out; 
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
   */



   .titleHowWorks {
    direction: rtl;
    --_margin: 10px; 
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2.2em;
    font-weight: 700;
    padding-inline: 1%; 
    gap: 20px; 
  
    &::before,
    &::after {
      content: "";
      flex: 1; 
      border: 1px solid #000;
    }
  
    &:not(:empty)::before {
      margin-right: var(--_margin);
    }
  
    &:not(:empty)::after {
      margin-left: var(--_margin);
    }
  }
  


#HowWorks {
  font-family: "Varela Round", sans-serif;
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);
  min-height: 100vh;
  display: grid;
  align-content: center;
  gap: 2rem;
  padding: 2rem;
  color: var(--color);
  /* background: var(--bgColor); */
}




.highlight {
  color: var(--accent-color, #FBCA3E);
}

.timeline-cards {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
.timeline-cards::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* row gaps */
.timeline-cards li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.timeline-cards li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.timeline-cards li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timeline-cards li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.timeline-cards li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.timeline-cards li .title2HowWorks,
.timeline-cards li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
.timeline-cards li .title2HowWorks {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 800;
}
.timeline-cards li .descr {
  direction: rtl;
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.timeline-cards li .title2HowWorks::before,
.timeline-cards li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.timeline-cards li .title2HowWorks::before {
  bottom: calc(100% + 0.125rem);
}

.timeline-cards li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  .timeline-cards {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .timeline-cards::before {
    grid-column: 2;
  }
  .timeline-cards li:nth-child(odd) {
    grid-column: 1;
  }
  .timeline-cards li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .timeline-cards li:nth-child(2) {
    grid-row: 2/4;
  }

  .timeline-cards li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .timeline-cards li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .timeline-cards li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}
