
 .ContactUsContainer {
    font-family: "Varela Round", sans-serif;
    height: 100vh;
    display: grid;
    align-content: center;
  }
 
  .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .infoCon {
    margin-top: -5%;
    border-radius: 50%; 
    width: 150px;
    height: 150px;
  }
  
    .ContactUsTitel{
        direction: rtl;
        --_margin: 15px; 
        display: flex;
        align-items: center; 
        justify-content: center;
        font-size: 2.2em;
        font-weight: 700;
        padding-inline: 1%;
        gap: var(--_margin); 
        white-space: nowrap; 
    }
    
    .ContactUsTitel::before,
    .ContactUsTitel::after {
        content: "";
        width: 350px; 
        border: 1px solid #000;
        flex-shrink: 0; 
    }

  .contact-form {
    margin-left: 15%;
    flex: 0 0 25%;
    padding: 20px;
    /* border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  .contact-form h2 {
    font-family: "Varela Round", sans-serif;
    margin-bottom: 15px;
    color: #447063;
  }
  
  .ContactUsContainer-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .ContactUsContainer-form-group label {
    text-align: right;
    font-family: "Varela Round", sans-serif;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .messageConUs{
    width: 97.5%;
  }
  
  .ContactUsContainer-form-group input,
  .ContactUsContainer-form-group textarea {
    width: 98%;
    direction: rtl;
    font-family: "Varela Round", sans-serif;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .ContactUsContainer-form-group textarea {
    resize: vertical;
    min-height: 80px;
    width: 100.5%;
  }
  
  .ContactUsContainer-submit-btn {
    width: 50%;
    font-family: "Varela Round", sans-serif;
    background-color: #578E7E;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-btn:hover {
    background-color: #578e7e94;
  }
  


  .PCon{
    direction: rtl;
    margin-bottom: 25px;
    font-size: 14px;
  }

  .additional-info {
    margin-right: 15%;
    flex: 0 0 25%; 
    padding: 20px;
  }
  
  .H2Con{
    margin-bottom: 25px;
    color: #447063;
  }
  
  .additional-info ul {
    text-align: center;
    list-style: none; /* מסיר עיגולים או סימני רשימה */
    padding: 0;
  }
  
  .additional-info li {
    display: flex;
    align-items: center;
    justify-content: center; /* מרכז אופקית */
    margin-bottom: 10px;
    font-size: 12px;
    border-bottom: 1px solid #447063; /* פס שחור מתחת */
    padding-bottom: 5px; /* מרווח בין הטקסט לפס */
  }
  
  .additional-info li svg {
    margin-left: 10px; /* מוסיף רווח בין הטקסט לאייקון */
    font-size: 1.2rem; /* שולט על גודל האייקון */
  }
  

  .additional-info li:last-child {
    border-bottom: none; /* מסיר את הפס השחור מהפריט האחרון */
  }



.H2Icon {
  justify-content: center;
  display: flex;
  gap: 10px; /* מרווח בין האייקונים */
}

.H2Icon svg {
  font-size: 30px; /* גודל אייקונים */
  transition: color 0.3s ease; /* אנימציה חלקה למעבר צבע */
}

.H2Icon svg:hover {
  cursor: pointer; /* מצביע העכבר משתנה */
}

/* צבעים לכל אייקון */
.H2Icon .instagram:hover {
  color: #E1306C; /* צבע Instagram */
}

.H2Icon .facebook:hover {
  color: #1877F2; /* צבע Facebook */
}

.H2Icon .linkedin:hover {
  color: #0A66C2; /* צבע LinkedIn */
}

.H2Icon .youtube:hover {
  color: #FF0000; /* צבע YouTube */
}



  /* התאמה כללית למכשירים קטנים */
@media (max-width: 768px) {
  .ContactUsContainer {
    height: auto; /* גובה מותאם */
    padding: 10px; /* ריווח פנימי */
    display: flex;
    flex-direction: column; /* הפיכת הגריד לטור */
    align-items: center; /* ממרכז את התוכן */
  }

  .contact-container {
    flex-direction: column; /* סידור אלמנטים אחד מתחת לשני */
    gap: 15px; /* מרווחים קטנים יותר */
  }

  .infoCon {
    width: 100px; /* גודל קטן יותר לעיגול */
    height: 100px;
    margin-top: 0; /* התאמה מחדש */
  }

  .ContactUsTitel {
    font-size: 1.8em; /* גודל קטן יותר */
    gap: 10px; /* מרווחים קטנים יותר */
  }

  .ContactUsTitel::before,
  .ContactUsTitel::after {
    width: 150px; /* קיצור הפסים */
  }

  .contact-form {
    width: 90%; /* התאמה לרוחב המסך */
    margin-left: 0;
    padding: 15px; /* ריווח קטן יותר */
  } 

  .form-group input,
  .form-group textarea {
    width: 100%; /* התאמה לרוחב המכשיר */
  }

  .additional-info {
    margin-right: 0; /* אין צורך במרווח צדדי */
    width: 90%; /* התאמה לרוחב המסך */
    padding: 15px;
  }

  .H2Icon {
    font-size: 1.5rem; /* גודל קטן יותר לאייקונים */
    gap: 10px; /* ריווח קטן יותר */
  }

  .additional-info li {
    font-size: 10px; /* טקסט קטן יותר */
    padding-bottom: 3px;
  }

  .submit-btn {
    font-size: 14px; /* כפתור קטן יותר */
    padding: 8px;
  }
}

/* התאמה למכשירים ממש קטנים (עד 480px) */
@media (max-width: 480px) {
  .ContactUsTitel {
    font-size: 1.5em;
  }

  .ContactUsTitel::before,
  .ContactUsTitel::after {
    width: 100px; /* קיצור נוסף של הפסים */
  }

  .infoCon {
    width: 80px; /* עיגול קטן יותר */
    height: 80px;
  }

  .contact-form {
    padding: 10px;
  }

  .H2Icon {
    font-size: 1.2rem; /* גודל קטן לאייקונים */
    gap: 5px; /* ריווח מינימלי */
  }

  .additional-info li {
    font-size: 9px; /* טקסט ממש קטן */
    padding-bottom: 2px;
  }
}
