.user-profile {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 0.5px solid #8B6F4F;
    cursor: pointer;
    background-color: #fbfaf6;
}

.user-profile:hover {
    background-color: #ECE8DD;
}

.user-image {
    width: 45px;
}

.user-image > img {
    width: 100%;
    height: 45px;
    border-radius: 100%;
}

.user-info {
    flex: 1;
    text-align: left;
    margin-left: 10px;
}

.user-name {
    text-transform: capitalize;
    font-size: 15px;
}

.user-lastmessage {
    font-size: 13px;
    margin-left: 1px;
    margin-top: 2px;
    color: gray;
    max-height: 40px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.user-image .initial-placeholder {
    width: 40px;
    height: 40px;
    background-color: #C9B194; 
    color:#48423b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
    border: 1px solid #8B6F4F;
}

.read-more-btn {
    font-size: 12px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}
