
/* עיצוב למחירון הראשי */
.price-list-container {
    margin-top: 5%;
    font-family: "Varela Round", sans-serif;
    text-align: center;
    padding: 30px;
  }
  
  .price-list-title {
    font-size: 2rem;
    color: #004a5e;
    font-weight: bold;
  }
  
  .price-list-subtitle {
    color: #666;
    margin-bottom: 20px;
  }
  
  .billing-toggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .billing-toggle button {
    font-family: "Varela Round", sans-serif;
    background: none;
    border: 2px solid #004a5e;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 20px;
    transition: all 0.3s ease;
    background-color: #ddd;
    color: #004a5e;
  }
  
  .billing-toggle .active {
    background: #004a5e;
    color: white;
  }
  
  .billing-toggle button:hover {
    background: #c2dfe8;
    color: rgb(0, 0, 0);
  }
  
  .plans-container {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .plan-card {
    background: white;
    border: 2px solid #ddd;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
 /* הכרטיסייה האמצעית (המומלצת ביותר) תהיה גדולה יותר */
.plan-card.recommended {
    border: 2px solid #50ab06;
    transform: scale(1.1); 
    z-index: 10;
    width: 310px; 
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .recommended-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: #c8ebc5;
    color: #004a5e;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .plan-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .plan-description {
    color: #666;
  }
  
  .plan-price {
    direction: rtl;
    font-size: 2rem;
    font-weight: bold;
    color: #004a5e;
  }
  
  .plan-yearly {
    font-size: 0.9rem;
    color: #666;
  }
  
  .try-button {
    font-family: "Varela Round", sans-serif;
    background: #77B254;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 10px;
    transition: background 0.3s;
  }
  
  .try-button:hover {
    background: #003645;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .plan-features li {
    text-align: right;
    padding: 5px 0;
    font-size: 0.9rem;
    margin-bottom: 5%;
  }
  



/* עיצוב לסעיפים הכלולים בכל החבילות */
  .pricing-features-container {
    direction: rtl;
    background: linear-gradient(135deg, #dfffee, #fff8df);
    padding: 40px 20px;
    text-align: center;
    margin-top: 9%;
    border-radius: 10px; 
  }
  
  .pricing-features-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #000;
  }
  
  .pricing-features-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px 70px; 
    justify-content: center;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .pricing-feature-item {
    display: flex;
    align-items: center; 
    justify-content: start;
    gap: 10px;
    font-size: 1.2rem;
  }
  
  .feature-icon {
    font-size: 1.4rem;
    color: #008000;
  }
  



   /* עיצוב לתוספת מחיר לכמות שיחות מעל 1,000 */
  .chatbot-addon-wrapper {
    margin-top: 8%;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .chatbot-addon-container {
    background: linear-gradient(135deg, #f6e4a4, #fff8df);
    border-radius: 10px;
    border-left: 4px solid #FFB22C;
    border-bottom: 4px solid #FFB22C;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    text-align: center;
    width: 80%;
  }
  
  
  .chatbot-addon-title {
    font-size: 35px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .chatbot-addon-subtitle {
    font-size: 25px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .chatbot-addon-p{
    font-size: 15px;
    color: #555;
    margin-bottom: 10px;
  }

  .chatbot-addon-dropdown select {
    background-color: #55AD9B;
    color: white;
    font-weight: bold;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    font-family: "Varela Round", sans-serif;
  }
  
  .chatbot-addon-dropdown select:focus {
    outline: none;
    background-color: #55ad9b88;
  }
  
  .chatbot-addon-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  .chatbot-addon-subtext {
    font-size: 1rem;
    color: #555;
    margin-top: 15px;
  }
  


   /* AI עיצוב לתוספת מחיר לכמות שיחות עם צ'אט בינה מלאכותית  */
   .chatbotAI-addonAI-wrapperAI {
    margin-top: 5%;
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .chatbotAI-addonAI-containerAI {
    background: linear-gradient(135deg, #D8EFD3, #F1F8E8);
    border-radius: 10px;
    border-right: 4px solid #55AD9B; 
    border-bottom: 4px solid #55AD9B; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    text-align: center;
    width: 80%;
    direction: rtl;
  }
  

  .chatbotAI-addonAI-titleAI {
    font-size: 35px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .chatbotAI-addonAI-subtitleAI {
    font-size: 25px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .chatbotAI-addonAI-pAI{
    font-size: 15px;
    color: #555;
    margin-bottom: 10px;
  }

  .chatbotAI-addonAI-dropdownAI select {
    background-color: #FFB22C;
    color: white;
    font-weight: bold;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    font-family: "Varela Round", sans-serif;
  }
  
  .chatbotAI-addonAI-dropdownAI select:focus {
    outline: none;
    background-color: #ffb22c8e;
  }
  
  .chatbotAI-addonAI-priceAI {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  .chatbotAI-addonAI-subtextAI {
    font-size: 1rem;
    color: #555;
    margin-top: 15px;
  }



/* תשלום לפי שיחה, לא הודעה - הסבר על התשלום  למטא */
  .whatsapp-pricing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 7%;
    padding: 20px;
  }
  
  .whatsapp-pricing-icon {
    font-size: 2rem;
    color: #FFB22C;
  }
  
  .highlight-icon {
    font-size: 2.5rem;
  }
  
  .whatsapp-pricing-content {
    max-width: 900px;
    text-align: right;
  }
  
  .whatsapp-pricing-title {
    font-size: 2rem;
    font-weight: bold;
    color: #222;
    text-align: right;
    position: relative;
    display: inline-block;
    padding-right: 15px; 
    direction: rtl; 
    margin-right: -1.5%;
  }
  
  .whatsapp-pricing-title::before {
    content: "";
    position: absolute;
    right: -10px; 
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: #FFB22C;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  
  .whatsapp-pricing-highlight {
    color: #00d26a; 
    font-weight: bold;
    font-size: 2.2rem;
  }
  
  
  .whatsapp-pricing-description {
    color: #444;
    font-size: 24px;
    text-align: right;
    direction: rtl;
    margin-top: -2%;
  }
  
  .whatsapp-pricing-button {
    margin-top: 10px;
    font-family: "Varela Round", sans-serif;
    padding: 10px 15px;
    border: 2px solid black;
    background-color: #00d26a;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    margin-top: -1%;
  }
  
  .whatsapp-pricing-button:hover {
    background-color: #009f4d;
  }
  