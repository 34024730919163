.ExamplesContainer-Examples {
    direction: rtl;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: "Varela Round", sans-serif;
  }
  
  .HeaderContainer-Examples {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .HeaderOption-Examples {
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #e6f7f8;
    color: #333;
    font-size: 14px;
    cursor: pointer;
  }
  
  .HeaderOptionActive-Examples {
    background-color: #d5f5d5;
  }
  

  .ExamplesContainerP1{
    font-size: 45px;
  }

  .ExamplesContainerP2{
    font-size: 20px;
  }

  .HighlightedText-Examples {
    color: rgb(128, 6, 149);
    font-weight: bold;
  }
  
  .ContentSection-Examples {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .Content-Examples {
    flex: 1.5;
    text-align: right;
  }
  
  .Content-Examples h1 {
    text-align: center;
    font-size: 30px;
    color: #003366;
    margin-bottom: 20px;
  }
  
  .Content-Examples p {
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  

  .ExampleBox-Examples {
    flex: 1.5;
    /* background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px; */
    padding: 20px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
 
  .VideoBox-Examples {
    width: 50%;
    max-width: 600px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  



  .ToggleContainer-Examples {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .ToggleButton-Examples {
    font-family: "Varela Round", sans-serif;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.3s ease;
  }
  
  .ToggleButton-Department {
    background-color: #f0f8ff; 
    color: #004085;
  }
  
  .ToggleButton-Field {
    color: #004085;
    background-color: #f8f5f0; 
  }
  
  .ToggleButton-Examples.active {
    border-color: #d5f5d5; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .ToggleButton-Examples:hover {
    opacity: 0.9;
    transform: scale(1.05);
    background-color: #c0b7838d;
  }
  

  .UnderlineText-Examples {
    display: inline-block;
    position: relative;
    color: inherit; /* שומר על הצבע הקיים */
  }
  
  .UnderlineText-Examples::after {
    content: "";
    display: block;
    width: 100%; /* רוחב הפס תואם לרוחב הטקסט */
    height: 4px; /* גובה הפס */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='4' viewBox='0 0 200 4'%3E%3Cpath d='M0 2 Q10 0 20 2 T40 2 T60 2 T80 2 T100 2 T120 2 T140 2 T160 2 T180 2 T200 2' fill='none' stroke='black' stroke-width='0.5'/%3E%3C/svg%3E");
    background-size: 200px 4px; /* התאמה מדויקת לגובה ולרוחב */
    background-repeat: repeat-x; /* חזרה אופקית */
    position: absolute;
    bottom: -4px; /* המרחק בין הטקסט לפס */
    left: 0;
  }
  