.gambot-action {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    /* color: #66023C;  */
}

.form-group input,
.form-group select,
.react-select-container {
    min-width: 250px; /* Set a minimum width for inputs and selects */
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}



.mt-3 {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .gambot-action {
        flex-direction: column;
        gap: 10px;
    }

    .form-group {
        width: 100%;
    }

    .form-group input,
    .form-group select,
    .react-select-container {
        width: 100%;
    }
}
