.waba-onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.waba-onboarding-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    margin-bottom: 20px;
}

.waba-onboarding-left {
    width: 48%;
    padding-right: 20px;
    margin-top: 10px;
}


.waba-onboarding-right h2 {
    font-size: 1.6em;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.waba-onboarding-right p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
}

.waba-onboarding-right-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.waba-onboarding-right-list li {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.6;
}

.waba-onboarding-right {
    width: 50%;
    padding: 10px;
    direction: rtl;

}
.waba-onboarding-left-list li,
.waba-onboarding-right-list li {
  margin-bottom: 5px; /* or any spacing you prefer */
  font-size: 16px;
  line-height: 1.5;
}
.waba-onboarding-left-video {
    width: 100%;
    height: 300px; /* Adjusted to ensure the video is visually pleasing */
    border-radius: 8px;
}

.waba-onboarding-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .fb-login-center {
    width: 300px;
    text-align: center;
  }
  
  .prev-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
  } 
  .waba-onboarding-left-list li{
    text-align: right;
  }

.waba-onboarding-prev-button {
    padding: 12px 30px;
    font-size: 16px;
    background-color: #578E7E;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px;
    min-width: 100px;
}

.waba-onboarding-prev-button:hover {
    background-color: #86A788;
}

.waba-onboarding-prev-button:active {
    transform: scale(0.96);
}
