/* Integrations.css */

.integration-list-container {
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}

.integration-list-header {
    color: var(--LogoColor); /* Adjust color as needed */
    font-size: 24px;
    margin-bottom: 20px;
}

.integration-list-table-container {
    max-height: 400px; /* Set a maximum height for the table container */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.integration-list-table {
    width: 100%;
    border-collapse: collapse;
}

.integration-list-table th,
.integration-list-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.integration-list-table th {
    background-color: #f4f4f4;
}

.integration-edit-button,
.integration-delete-button {
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 0.9em;
}

.integration-edit-button {
    background-color: rgb(0, 103, 239); /* Adjust hover color as needed */
}

.integration-delete-button {
    background-color: #c82333; /* Adjust hover color as needed */
}

.add-new-integration-button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--LogoColor);
    margin-top: 10px;
    color: white;
    cursor: pointer;
}
