.fb-login-button {
  background-color: #4267B2; /* Facebook blue */
  color: #fff; /* Ensure the text is white */
  font-size: 16px;
  padding: 12px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.fb-login-button:hover {
  background-color: #365899; /* Darker blue on hover */
}

.fb-login-button:active {
  transform: scale(0.98);
}
