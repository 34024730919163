/* Botomation.css */

.botomation-list-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px; 
}

.botomation-list-container input{
  width: 99%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.botomation-list-header {
    color: var(--LogoColor); 
    font-size: 40px;
    margin-bottom: 20px;
}

.botomation-list-table-container {
    border-radius: 8px; 
    max-height: 540px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-right: 1px solid #ddd; 
    border-left: 1px solid #ddd; 
    border-bottom: 1px solid #ddd; 
    border-top: 1px solid #ddd; 
}

.botomation-list-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
}


/* מסירים קו תחתון מהשורה האחרונה */
.botomation-list-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  /* עיגול לפינות חיצוניות */
  .botomation-list-table thead th:first-child {
    border-top-left-radius: 8px;
  }
  
  .botomation-list-table thead th:last-child {
    border-top-right-radius: 8px;
  }
  
  .botomation-list-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  
  .botomation-list-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  
.botomation-list-table th {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #f4f4f4; /* שמירה על רקע */
    z-index: 2; /* מבטיח שהכותרות יהיו מעל הנתונים */
    padding: 12px;
    font-weight: bold;
    text-align: left;
}


.add-new-botomation-button {
    color: rgb(255, 255, 255);
    background-color: #295F98;
    padding: 8px 20px;
    margin-top: 1%; 
    border: none;
    border-radius: 8px; 
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 1%;
}

.add-new-botomation-button:hover {
    color: #ffffff;
    background-color: #578FCA;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

.botomation-edit-button {
    background-color: rgba(0, 104, 239, 0); 
    color: #3674B5;
    font-size: 20px;
    border: none;
  }
  
  .botomation-delete-button {
    background-color: #c8233300; 
    color: #A31D1D;
    font-size: 20px;
    border: none;
  }

  .botomation-history-button {
    background-color: #c8233300; 
    color: #77B254;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

.botomation-list-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.botomation-list-table th {
    background-color: #f4f4f4;
    white-space: nowrap; 
}

.botomation-list-table td.templates-column {
    max-width: 200px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    cursor: pointer; 
}

.botomation-list-table td.templates-column:hover {
    overflow: visible; 
    white-space: normal; 
    word-break: break-word; 
    background-color: #f9f9f9; 
}



  .botomation-list-container::-webkit-scrollbar {
    width: 7px;
  }
  
  .botomation-list-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
  
  .botomation-list-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    min-height: 15%;
  }
  
  .botomation-list-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  