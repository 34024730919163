.botomation-container {
  font-family: 'Outfit', sans-serif !important;
  /* color: var(--LogoColor); */
}

.botomation-form-header {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-bottom: 20px;
}

.botomation-ArrowBackIcon {
  position: absolute;
  left: 15%; 
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.botomation-form-header-h2 {
  margin: 0;
  font-size: 32px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

/* .botomation-form-header-h2::after {
  content: "";
  display: block;
  width: 90%;
  height: 2px;
  margin: 8px auto 0 auto;
  background: #A7B49E;
  border-radius: 2px;
} */



.botomation-ArrowBackIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%; 
}



.botomationForm {
  border: 1px solid #dad7d7ce; 
  border-radius: 10px;
  max-width: 1000px;
  height: auto; 
  min-height: 400px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
}

.botomation-form-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.botomation-form-row label {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
  margin-right: 10px;
}

.botomation-form-row-name-input {
  font-family: 'Outfit', sans-serif !important;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px;
}


.botomation-form-submit {
  font-family: 'Outfit', sans-serif !important;
  display: block;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  width: 19%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: #295F98;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* .botomation-form-submit:hover {
  color: #ffffff;
  background-color: #578FCA;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
} */

