.update-keys-form-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .update-keys-form-modal.show {
    display: block;
  }
  
  .update-keys-form-modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  
  .update-keys-form-close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    background: none;
    border: none;
  }
  
  .update-keys-form-close-btn:hover,
  .update-keys-form-close-btn:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .update-keys-form-save {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-keys-form-save:hover {
    background-color: #45a049;
  }
  