/* HeaderTypeSelector.css */
.HeaderTypeSelector-container {
  text-align: center; /* Center the content */
  width: 100%;
}

.HeaderTypeSelector-container select {
  width: 20%;
  text-align: center;
  display: block; /* Ensure the element is block level */
  margin: 0 auto; /* Center the select element */
  color: var(--LogoColor);
}

.HeaderTypeSelector-header {
  text-align: center;
}
