.campaign-list-container {
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Outfit', sans-serif !important;
}



.campaign-list-container input{
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: 'Outfit', sans-serif !important;
}

.campaign-list-header {
    color: var(--LogoColor); /* Adjust color as needed */
    font-size: 40px;
    margin-bottom: 20px;
}

.campaign-list-table-container {
    border-radius: 8px; /* עיגול פינות */
  max-height: 540px; 
  overflow-y: auto; 
  overflow-x: hidden;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd; 
  border-bottom: 1px solid #ddd; 
  border-top: 1px solid #ddd; 
}

.search-bar {
    margin-bottom: 20px;
    margin-right: 20px;
}

.campaign-list-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures the table does not exceed container width */
}

.campaign-list-table th,
.campaign-list-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word; /* Ensures content wraps within cells */
}

.campaign-list-table th {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #f4f4f4; /* שמירה על רקע */
    z-index: 2; /* מבטיח שהכותרות יהיו מעל הנתונים */
    padding: 12px;
    font-weight: bold;
    text-align: left;
}

.campaign-edit-button,
.campaign-delete-button,.campaign-play-button,.campaign-result-button{
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 0.8em;
    margin: 2px;
}
.campaign-scheduled-button{
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 0.8em;
    margin: 2px;
}


.campaign-result-button{
    background-color: rgba(0, 104, 239, 0); 
    color: #77B254;
    font-size: 20px;
  }
  

.campaign-edit-button{
    background-color: rgba(0, 104, 239, 0); 
    color: #3674B5;
    font-size: 20px;
  }
  
  .campaign-delete-button {
    background-color: #c8233300; 
    color: #A31D1D;
    font-size: 20px;
  }

  .campaign-play-button {
    background-color: #c8233300; 
    color: #B771E5;
    font-size: 20px;
  }


.add-new-campaign-button {
    color: rgb(255, 255, 255);
    background-color: #27548A;
    padding: 8px 20px;
    margin: 0; /* מסיר רווחים נוספים */
    border: none;
    border-radius: 8px; /* מסיר את הפינות המעוגלות */
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 2%;
    font-family: 'Outfit', sans-serif !important;
}

/* .add-new-campaign-button:hover {
    color: #ffffff;
    background-color: #578FCA;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  } */



  .campaign-list-table-container::-webkit-scrollbar {
    width: 7px;
  }
  
  .campaign-list-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
  
  .campaign-list-table-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    min-height: 15%;
  }
  
  .campaign-list-table-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }  


@media (max-width: 768px) {
    .previewRun-Container,
    .campaign-list-container {
        width: 100%; /* Mobile width */
        padding: 10px; /* Adjust padding */
    }
}
