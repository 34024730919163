

.font-arial { font-family: Arial !important; }
.font-courier { font-family: "Courier New" !important; }
.font-georgia { font-family: Georgia !important; }
.font-tahoma { font-family: Tahoma !important; }
.font-verdana { font-family: Verdana !important; }
.font-trebuchet { font-family: "Trebuchet MS" !important; }
.font-comic { font-family: "Comic Sans MS" !important; }
.font-times { font-family: "Times New Roman" !important; }
.font-lucida { font-family: "Lucida Console" !important; }
.font-segoe { font-family: "Segoe UI" !important; }
.font-outfit { font-family: "Outfit" !important; }
.font-sourgummy { font-family: "Sour Gummy" !important; }


/* * {
  font-family: 'Outfit', sans-serif;
} */

  .chat-widget-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-top: 2%;
  width: 100%;
  font-family: 'Outfit', sans-serif !important;
}
.chat-widget-config-header{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    max-width: 1400px;
    padding: 10px 20px;
    position: relative;
    margin: 0 auto;
}
.chat-widget-config-header h2{
  font-size: 40px;
  font-weight: bold;
  color: var(--LogoColor);
  flex-grow: 1;
  text-align: center;
}

.slash-color{
  color: #3F7D58;
}

.hashtag-color{
  color: #3F7D58;
}

.chat-color {
  font-size: 40px;
  color: #A3D1C6;
  font-weight: bold;
  font-family: 'Sour Gummy', sans-serif !important;
}

.widget-color {
  font-size: 40px;
  color: #B3D8A8;
  font-weight: bold;
  font-family: 'Sour Gummy', sans-serif !important;
}


.chat-widget-config-heade-iconButton{
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0% !important;
}
/* Form container */
.chat-widget-config-form {
  width: 65%;
  max-width: 1400px;
  padding: 20px;
  border-radius: 25px;
  border-right: 5px solid #6A9C89;
  border-top: 5px solid #6A9C89; 
  border-bottom: 3px double #F0A04B;
  margin-bottom: 3%;
  background-color: #f9fbf9;
}

/* Form header */
.chat-widget-config-form h3 {
  font-family: Outfit, sans-serif !important;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 3px double #F0A04B;

}

/* Form inputs and labels */
.chat-widget-form-label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}


.chat-widget-form-checkbox {
  width: auto;
  margin-right: 10px;
}

.chat-widget-form-select {
  background-color: #fff;
}

/* New flex row for the first group of inputs (Brand Color, Chat Button, Icon Color) */
.chat-widget-form-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between the inputs */
}






/* New flex row for Font and Text Color inputs */
.chat-widget-form-row .chat-widget-form-input,
.chat-widget-form-row .chat-widget-form-select {
  width: auto; /* Allow width to adjust based on content */
  flex-grow: 1; /* Make inputs take the remaining space */
}



/* Buttons */
.chat-widget-preview-toggle button{
  margin-top: 10px;
  cursor: pointer;
  background-color: none !important;
}

.chat-widget-preview-toggle {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

/* Preview toggle button */
.chat-widget-preview-toggle label {
  margin-right: 10px;
}

.chat-widget-preview-toggle input {
  margin-top: 0;
}

/* Style for widget container when preview is enabled */
.chat-widget-preview {
  margin-top: 30px;
  width: 100%;
}


/* Positioning and other layout styles for widget */
.chat-widget-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}

.chat-widget-button {
  background-color: #25d366;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chat-bubble p {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.submit-widget-button{
  font-family: 'Outfit', sans-serif;
    margin-left: 30%;
    color: #ffffff;
    background-color: #3F7D58;
    padding: 8px 20px;
    border: none;
    border-radius: 8px; 
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 2%;
}

.generate-embed-button{
  font-family: 'Outfit', sans-serif;
  margin-left: 10%;
  color: #ffffff;
  background-color: #3F7D58;
  padding: 8px 20px;
  border: none;
  border-radius: 8px; 
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  text-decoration: none;
  margin-bottom: 2%;
}


.submit-widget-button:hover,.generate-embed-button:hover{
  color: #ffffff;
  background-color: #99BC85;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.submit-widget-button:hover:hover{
  color: #ffffff;
  background-color: #99BC85;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.chat-widget-open {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.minimize-button {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.chat-body {
  margin-top: 10px;
}

.whatsapp-button {
  background-color: #25d366;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.chat-widget-minimized {
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.left-position {
  left: 10px;
}

.right-position {
  right: 10px;
}

/* Section container with rounded dividers */
.section-container {
  padding: 20px;
  margin-bottom: 20px;
  /* border-bottom: 3px double #6A9C89; */
}

/* Form actions (Create Widget and Generate Embed Code) */
.form-actions {
  display: flex;

}

.embed-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.embed-popup-content {
  background-color: #eefdf7;
  padding: 25px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.embedCodeTextarea-copy-button {
  margin-top: 3%;
  background-color: #25d366;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}


.embedCodeTextarea-close-button {
  margin-top: 3%;
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.embed-popup-content textarea {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}

.embed-popup-content textarea::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}


.Pre-filled-chat-widget-form-row {
  display: flex;
  flex-direction: column; /* Align the children (textarea and buttons) vertically */
  gap: 10px; /* Space between elements */
}

.pre-filled-button-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap; /* Ensure buttons wrap if the container is small */
}

.pre-filled-insert-button {
  background-color: #25d366;  /* Green background */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  flex: 1; /* Allow buttons to stretch within their container */
  min-width: 100px; /* Ensure buttons have a minimum width */
}








/* עיצוב כללי אחיד  */
.chat-widget-form-input,
.chat-widget-form-select {
  font-family: 'Outfit', sans-serif;
  margin-bottom: 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #c0ffe8;
  padding: 10px 14px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 14px;
  color: #1a1a1a;
}



.chat-widget-form-input[type="color"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  border: 2px solid #6A9C89 !important;
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background: none !important; 
  cursor: pointer !important;
  outline: none !important;
}

.chat-widget-form-input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0 !important;
  border-radius: 50% !important;
}

.chat-widget-form-input[type="color"]::-webkit-color-swatch {
  border: none !important;
  border-radius: 50% !important;
}











/* עיצוב לסקשיין הראשון */
/* Layout container for two sides */
.widget-name-layout {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px; /* ריווח אחיד בין צד שמאל לימין */
  flex-wrap: wrap;
}

/* שמאל - שני שדות אחד מתחת לשני */
.left-side-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 300px;
}

/* ימין - סטטוס */
.right-side-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 200px;
}

/* סגנון אחיד */
.widget-name-section {
  background-color: #e6fff4;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #b2f0d7;
  margin-bottom: 40px;
}

/* כותרת */
.widget-section-title {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}



/* תוויות */
.chat-widget-form-label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
  text-align: left;
}








/* עיצוב לסקשיין השני */
.button-settings-section {
  background-color: #e6fff4;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #b2f0d7;
  margin-bottom: 40px;
}

.button-settings-section h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
}


.button-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.chat-widget-form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.aaaWidget{
  width: 90% !important;
  margin-left: -10%;
}


.bbbWidget {
  float: left;
}






/* עיצוב לסקשיין השלישי */

.open-form-section3 .chat-widget-form-input,
.open-form-section3 .chat-widget-form-select,
.open-form-section3 .chat-widget-form-textarea {
  min-width: 30px;
  margin-bottom: 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #c0ffe8;
  padding: 10px 14px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 14px;
  color: #1a1a1a;
}


.open-form-section3 {
  background-color: #e6fff4;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #b2f0d7;
  margin-bottom: 40px;
}


.open-form-section3 h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
}


.open-form-layout {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.left-side-fields,
.right-side-field {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  min-width: 300px;
}



.chat-widget-form-textarea {
  resize: vertical;
  min-height: 100px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.pre-filled-button-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.pre-filled-insert-button {
  background-color: #6A9C89;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
  transition: 0.2s;
}

.pre-filled-insert-button:hover {
  background-color: #4f7c6c;
}














/* עיצוב לסקשיין הרביעי */
.position-settings-section {
  background-color: #e6fff4;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #b2f0d7;
  margin-bottom: 40px;
}

.position-settings-section h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
}

.position-settings-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.position-settings-section .form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 280px;
}

.position-settings-section .chat-widget-form-input,
.position-settings-section .chat-widget-form-select {
  margin-bottom: 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #c0ffe8;
  padding: 10px 14px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 14px;
  color: #1a1a1a;
}

.position-settings-section .chat-widget-form-checkbox {
  transform: scale(1.2);
  margin-top: 8px;
}






.circleCheckboxx {
  all: unset;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width:30px !important;
  height: 30px !important;
  border: 2px solid #6A9C89 !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  vertical-align: middle !important;
  min-height: 30px !important;
  min-width: 30px !important;
}


.circleCheckboxx:checked::after {
  content: "✔" !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  font-size: 14px !important;
  color: rgb(0, 0, 0) !important;
}
