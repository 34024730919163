.timeline-container {
    background-color: #fbfaf6;
    margin: 0 auto;
}


.add-timeline-note {
    display: flex;
    flex-direction: column;
}

.add-timeline-note textarea {
    background-color: #fbfaf6;
    resize: none;
    padding: 0.5rem;
    border: 0.5px solid #8B6F4F;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    margin-left: 2%;
    margin-right: 2%;
}

/* .add-timeline-note button {
    align-self: flex-end;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
} */

.add-timeline-note button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


.timeline-list {
    background-color: #f9f9f9;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: calc(100vh - 300px); /* Adjust for other elements */
    overflow-y: auto;
    padding-right: 0.5rem;
}

.timeline-entry {
    background-color: #f9f9f9;
    border: 0.5px solid #8B6F4F;
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: 2%;
}

.timeline-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.timeline-icon {
    font-size: 1.5rem;
    color: #007bff;
}

.timeline-info {
    flex: 1;
}

.timeline-meta {
    font-size: 0.8rem;
    color: #666;
}

.timeline-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

/* Optional: Add styling for scrollbar */
.timeline-list::-webkit-scrollbar {
    width: 8px;
}

.timeline-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.timeline-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}


.add-timeline-button {
    width: 70%;
    background-color: #336D82;
    color: #ffffff;
    font-size: 14px; 
    margin: 4px auto;
    padding: 8px 10px; 
    border: none; 
    border-radius: 10px; 
    cursor: pointer; 
    display: block; 
}
