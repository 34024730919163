/* VariableInput.css */
.variable-input-container {
    font-family: Arial, sans-serif;
    
  }
  
  .variable-input-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .variable-input-container > div {
    display: flex;
    margin-bottom: 8px;
  }
  
  .variable-input-container input[type="text"] {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
  }
  
  .variable-input-container button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .variable-input-container button:hover {
    background-color: #0056b3;
  }
  
  .variable-input-container button:active {
    background-color: #003d82;
  }
  
  .variable-input-container ul {
    list-style: none;
    padding: 0;
  }
  
  .variable-input-container li {
    background-color: #f2f2f2;
    padding: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .variable-input-container li button {
    padding: 4px 8px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .variable-input-container li button:hover {
    background-color: #c82333;
  }
  
  .variable-input-container li button:active {
    background-color: #a71d2a;
  }
  