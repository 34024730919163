.send-http-request-action {
    margin: 20px;
  }
  
  .send-http-request-action-header-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .send-http-request-action-header-row input {
    margin-right: 10px;
  }
  
  .send-http-request-action-header-row button {
    background-color: #dc3545 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    cursor: pointer !important;
  }
  
  .send-http-request-action-header-row button:hover {
    background-color: #c82333 !important;
  }
  
  .send-http-request-action button[type="button"] {
    margin-top: 10px;
    background-color: var(--LogoColor);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .send-http-request-action button[type="button"]:hover {
    background-color: #0056b3;
  }
  
  .send-http-request-action-remove {
    background-color: #dc3545 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    cursor: pointer !important;
  }
  
  .send-http-request-action-remove:hover {
    background-color: #c82333 !important;
  }
  .send-http-request-action-json-schema-textarea.invalid {
    border: 1px solid red;
  }
  
  .send-http-request-action-error-text {
    color: red;
    font-size: 0.9em;
    margin-top: 4px;
  }
  /* General Styling for JSON Schema Textarea */
.json-schema-textarea  {
  width: 100%;
  height: 150px;
  font-family: monospace;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: vertical;
}

.json-schema-textarea.invalid {
  border: 1px solid red;
}

.webhook-trigger-error-text,
.send-http-request-action-error-text {
  color: red;
  font-size: 0.9em;
  margin-top: 4px;
}

.inline-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.inline-form-group label {
  min-width: 150px;
  margin-right: 10px;
}

.inline-form-group input {
  flex: 1;
  padding: 5px;
  font-size: 14px;
}
