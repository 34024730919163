.form-flows-generator-container {
    display: flex;
    height: 100%;
  }
  
  .FormFlowsScreens-section, .FormFlowsEdit-content-section, .FormFlowsPreview-section {
    width: 33%;
    padding: 20px;
    border-right: 1px solid #ccc;
  }
  
 .FormFlowsScreens-section {
    /* background-color: #fffbfb; */
  }


.FormFlowsPreview-section {
    /* background-color: #fffbfb; */
  }
  
  .FormFlowsScreens-list {
    margin-top: 20px;
  }
  
  .screen-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    gap: 8px; 
}

  .screen-item span {
      flex-grow: 1;
      display: flex;
      align-items: center; 
  }

  .screen-item button {
      background: none;
      border: none;
      color: red;
      cursor: pointer;
      font-size: 1.2em;
      display: flex;
      align-items: center; 
      padding: 2px;
  }

  .screen-item:hover {
      background-color: #e9e9e9;
  }

  

  
  .FormFlowsEdit-content-section {
    background-color: #fff;
  }
  

  
  .FormFlowsPreview-content {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .FormFlowsPreview-content h3 {
    margin-top: 0;
  }
  
  .buttonFormFlowsScreens {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    background-color: #3674B5;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
}
  
  .buttonFormFlowsScreens:hover {
    background-color: #578FCA;
  }
  
  .button-icon {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  
  .button-text {
    display: flex;
    align-items: center;
  }
  
  .h2FormFlowsScreens{
    text-align: center;
    margin-top: 0;
    padding-bottom: 5px; 
    border-bottom: 1px solid black; 
    justify-content: center;
    
  }

  
/* .FormFlowsScreens-section, .FormFlowsEdit-content-section, .FormFlowsPreview-section {
  width: 33.33%;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.FormFlowsScreens-section {
  background-color: #f9f9f9;
}

.FormFlowsScreens-list {
  margin-top: 20px;
}

.screen-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.screen-item span {
  flex-grow: 1;
}

.screen-item button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.2em;
}

.screen-item:hover {
  background-color: #e9e9e9;
} 

 .FormFlowsScreens-section {
  width: 20%; 
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.FormFlowsEdit-content-section,
.FormFlowsPreview-section {
  width: 40%;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.FormFlowsEdit-content-section {
  background-color: #fff;
}

.FormFlowsPreview-section {
  background-color: #f1f1f1;
} */