/* 
  #our-features {
    height: 120vh;
    margin-top: 2%;
    text-align: center;
    padding: 20px;
    font-family: "Varela Round", sans-serif;
  }
  
  .title {
    --_margin: 57px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2.2em;
    font-weight: 700;
  
    &::before,
    &::after {
      content: "";
      flex: 1;
      border: 1px solid #000;
    }
  
    &:not(:empty)::before {
      margin-right: var(--_margin);
    }
  
    &:not(:empty)::after {
      margin-left: var(--_margin);
    }
  }
  
  
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
    perspective: 1000px;
  }
  
  .carousel-item {
    position: absolute;
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    transform-origin: center;
  }
  
  .carousel-item img {
    margin-top: 25%;
    width: 350px;
    height: 430px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }
  
  .carousel-item.center {
    opacity: 1;
    transform: translateX(0) scale(1);
    z-index: 2;
  }
  
  .carousel-item.left {
    opacity: 0.2;
    transform: translateX(-150px) scale(0.8);
    z-index: 1;
  }
  
  .carousel-item.right {
    opacity: 0.2;
    transform: translateX(150px) scale(0.8);
    z-index: 1;
  }
  

  .carousel-item.hidden {
    opacity: 0;
    transform: scale(0);
    z-index: 0;
  }
  

  .content-template {
    background-color: #f5f5f5; 
    border: 2px solid #ddd; 
    border-radius: 8px; 
    padding: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    text-align: center; 
    width: 700px; 
    margin: 0 auto; 
  }
  
  .h3title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333; 
  }
  
  .content-template p {
    font-size: 1em;
    color: #666; 
    line-height: 1.5; 
  }
  
  
.carousel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #578e7ec3;
    color: white;
    border: none; 
    border-radius: 50%; 
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    z-index: 10;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .carousel-button:hover {
    background: #468597c3; 
    transform: translateY(-50%) scale(1.1); 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); 
  }
  
  .carousel-button.prev {
    left: 20px; 
  }
  
  .carousel-button.next {
    right: 20px; 
  }
  
  .carousel-button svg {
    font-size: 2rem; 
    transition: transform 0.3s ease;
  }
  
  .carousel-button:hover svg {
    transform: scale(1.2); 
  }
  */


  /* .our-feature-img {
    position: absolute;
    bottom: 10%;
    right: 10%;
    width: 40px;
  } */

#our-features {
  font-family: "Varela Round", sans-serif;
  margin-top: 8%;
  text-align: center;
}

  .our-feature-icon{
    position: absolute;
    bottom: -10%;
    right: 2%;
    width: 50px;
    font-size: 30px;
  }


.our-features-title {
  direction: rtl;
    --_margin: 10px; 
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2.2em;
    font-weight: 700;
    padding-inline: 1%; 
    gap: 20px; 
  
    &::before,
    &::after {
      content: "";
      flex: 1; 
      border: 1px solid #000;
    }
  
    &:not(:empty)::before {
      margin-right: var(--_margin);
    }
  
    &:not(:empty)::after {
      margin-left: var(--_margin);
    }
}




.our-features-main {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.our-features-section {
  max-width: 1200px;
}

.our-features-section-title {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.5em;
}

.our-features-section-title span {
  font-weight: 600;
}

.our-features-section-desc {
  color: hsl(229, 6%, 66%);
  margin: 1rem auto;
  font-size: 1rem;
}

.our-feature-title {
  font-size: 1.2rem;
}

.our-feature-desc {
  margin-top: 0.5rem;
  color: hsl(240, 1%, 58%);
}


.our-feature-card {
  direction: rtl;
  position: relative;
  width: 330px;
  height: 160px;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
  background-color: white;
}

.our-features-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}


.our-feature-card:hover {
  transform: scale(1.09);
}

.our-feature-one {
  border-top: 5px solid hsl(180, 62%, 55%);
}

.our-feature-two {
  border-top: 5px solid hsl(0, 78%, 62%);
}

.our-feature-three {
  border-top: 5px solid hsl(34, 97%, 64%);
}

.our-feature-four {
  border-top: 5px solid hsl(212, 86%, 64%);
}

.our-feature-five {
  border-top: 5px solid hsl(307, 86%, 64%);
}

.our-feature-six {
  border-top: 5px solid hsl(50, 86%, 64%);
}

.our-feature-seven {
  border-top: 5px solid hsl(315, 58%, 51%);
}
.our-feature-eight {
  border-top: 5px solid hsl(283, 72%, 38%);
}

.our-feature-nine {
  border-top: 5px solid hsl(104, 86%, 64%);
}
.our-features-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}




