.phone-numbers-list-container {
    padding: 20px;
    text-align: center;
}



.phone-numbers-list-table {
    width: 100%;
    table-layout: fixed; /* מבטיח שרוחב העמודות יהיה שווה */
    border-collapse: collapse;
}


.phone-numbers-list-table-container-phonenumber-value{
    direction: ltr;
}


.phone-numbers-list-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.phone-numbers-list-country-select {
    font-family: "Varela Round", sans-serif;
    margin: 20px auto; /* Center horizontally */
    width: 55%;
    text-align: center; /* Center text inside the element */
}



#countryCode-select {
    font-family: "Varela Round", sans-serif;
}

.phone-numbers-list-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

.phone-numbers-list-table-container {
    border: 1px solid #ddd;
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden; /* למנוע גלילה אופקית */
}


/* עיצוב הגלילה ל-Webkit (Chrome, Edge, Safari) */
.phone-numbers-list-table-container::-webkit-scrollbar {
    width: 8px;
}

.phone-numbers-list-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* צבע הרקע */
    border-radius: 10px;
}

.phone-numbers-list-table-container::-webkit-scrollbar-thumb {
    background: #888; /* צבע פס הגלילה */
    border-radius: 10px;
}

.phone-numbers-list-table-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* צבע כהה יותר כשעוברים עם העכבר */
}






.phone-numbers-list-table th, 
.phone-numbers-list-table td {
    padding: 5px; /* ריווח פנימי */
    border: 1px solid #ddd;
    text-align: center; /* יישור תוכן למרכז */
    height: 20px; /* גובה מינימלי קבוע לכל תא */
    vertical-align: middle; /* ממרכז את התוכן אנכית */
    word-wrap: break-word; /* שובר מילים ארוכות */
}


.phone-numbers-list-table th {
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    width: 33.33%;
}

.phone-numbers-list-table td {
    text-align: center;
    background-color: #f9f9f9;
}

.phone-numbers-list-buy-button {
    font-family: "Varela Round", sans-serif;
    width: 80%; /* הכפתור יתפוס 80% מרוחב התא */
    padding: 8px 0; /* התאמה לרוחב הכפתור */
    font-size: 14px;
    background-color: #DCD7C9;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block; /* גורם לכפתור לתפוס את הרוחב שהוגדר */
    margin: 0 auto; /* ממרכז את הכפתור בתוך התא */
    text-align: center;
}



.phone-numbers-list-buy-button:disabled {
    background-color: #e6e6e6;
    color: #aaa;
    cursor: not-allowed;
}

.phone-numbers-selected {
    color: #578E7E;
}

.phone-numbers-list-selected {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #4CAF50;
}


.swal2-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swal2-confirm {
    background-color: #28a745 !important; 
    color: white !important;
    font-size: 18px;
    font-family: "Varela Round", sans-serif;
}

.swal2-cancel {
    background-color: #dc3545 !important; 
    color: white !important;
    font-size: 18px;
    font-family: "Varela Round", sans-serif;
}



.phone-number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px; /* Adjust as needed for spacing */
}

.phone-wrapper {
    display: flex;
    align-items: center;
    direction: ltr;
    gap: 2px;
}

.phone-prefix {
    font-weight: bold;
    white-space: nowrap;
}

.phone-number {
    direction: ltr;
    font-weight: normal;
}


