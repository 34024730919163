.start-conversation-by-template {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.start-conversation-by-template h2 {
    margin-bottom: 20px;
}

.start-conversation-by-template .template-selection {
    margin-bottom: 20px;
    width: 100%;
}

.start-conversation-by-template button {
    margin: 5px;
}
.start-conversation-by-template-header{
    color: var(--logoColor);

}
.startConversation-Submit-Send-message{
    color: #ffffff;
    background-color: #295F98;
    border: none;
    border-radius: 8px; 
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 25px;
}
.startConversation-Cancel-message{
    color: #ffffff;
    background-color: #982929;
    border: none;
    border-radius: 8px; 
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 25px;
}
.start-conversation-by-template-close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    color: #888;
    cursor: pointer;
    padding: 5px;
    transition: color 0.3s;
}

.start-conversation-by-template-close-popup:hover {
    color: #000;
}