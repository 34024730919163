.condition-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px; /* Add space between items */
    flex-wrap: wrap; /* Allow items to wrap if needed */
  }
  
  /* .condition-row select,
  .condition-row input{
    flex: 1; 
  }
   */
  .condition-row .action-input-wrapper {
    flex: 3; /* Make the last input larger */
    min-width: 250px; /* Ensure it doesn't shrink too much */
    max-width: 100%; /* Prevent it from exceeding the container */
  }
  
  /* .condition-row .action-input-wrapper input {
    width: 100%;
  } */
  
  .condition-row .fa-trash {
    font-size: 20px;
    cursor: pointer;
    color: #ff0000;
    margin-left: 10px;
    transition: transform 0.2s ease, color 0.2s ease;
  }
  
  .condition-row .fa-trash:hover {
    transform: scale(1.2);
    color: #d10000;
  }

  .condition-row .action-input-wrapper {
    margin-right: 8px;
  }
  .condition-row select,
  .condition-row input {
    margin-right: 8px;
  }
  