

  .why-whatsapp {
    margin-top: 10%;
    font-family: "Varela Round", sans-serif;
    text-align: center;
  }


  .main-title {
    --_margin: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2.2em;
    font-weight: 700;
    text-align: center;
    padding-inline: 50px;
  
    &::before,
    &::after {
      content: "";
      flex: 1; 
      border: 1px solid #000;
    }
  
    &:not(:empty)::before {
      margin-right: var(--_margin);
    }
  
    &:not(:empty)::after {
      margin-left: var(--_margin);
    }
  }
  
  .icon-container {
    font-size: 30px; 
}


  .curved-line {
    margin-top: -40px;
    display: flex;
    justify-content: center;
    margin-bottom: -30px;
    margin-left: 5%;
    margin-right: 5%;
  }

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 5%;
}

.feature-card {
  margin-bottom: 5%;
  border-radius: 5%;
  direction: rtl;
  padding: 15px;
  width: 230px;
  height: 400px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  perspective: 1000px; 
}


.feature-card::before {
  content: "";
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top left, rgba(255, 255, 255, 0.5), transparent);
  border-radius: inherit;
  z-index: -1;
  transform: scale(1.1);
}





.feature-card:nth-child(1n) {
  background: url(../../../assets/israel2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-card:nth-child(2n) {
  background: url(../../../assets/openMes.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-card:nth-child(3n) {
  background: url(../../../assets/ai3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-card:nth-child(4n) {
  background: url(../../../assets/service.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-card:nth-child(5n) {
  background: url(../../../assets/communication.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}


.feature-card:hover {
  transform: translateY(-25px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* הוספת השכבה השקופה */
.feature-card::before {
  content: "";
  position: absolute;
  top: 3%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.84); 
  opacity: 0; 
  transition: opacity 0.3s ease;
}

.feature-card:hover::before {
  opacity: 1; 
}



.PiStarOfDavidThin{
  font-size: 2.5rem;
  color: #fff;
  background: linear-gradient(150deg, #4862c2, #ffffff);
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}


.LuMessageSquare{
  font-size: 2.5rem;
  color: #fff;
  background: linear-gradient(150deg, #48c25a, #ffffff);
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.FaRobot{
  font-size: 2.5rem;
  color: #fff;
  background: linear-gradient(150deg, #b1b7cf, #ffffff);
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}


.feature-title {
  background-color: #e6e4e4e1;
  margin-top: 35%;
  font-size: 1.2rem;
  color: #36594f;
}

.feature-description {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
}


.footerText {
  margin-top: 30px;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}




.read-more-btn{
  border: none;
  background-color: #578E7E;
  font-family: "Varela Round", sans-serif;
}








