.contacts-container {
  font-family: 'Outfit', sans-serif !important;
  margin: 20px;
  background-color: #fff;
}

.ContactPageOf{
  font-family: 'Outfit', sans-serif !important;
}

.contacts-search-input {
  font-family: 'Outfit', sans-serif !important;
  margin-right: 2%;
  align-items: center;
  font-size: 20px; /* גודל פונט גדול */
  padding: 12px 15px; /* ריווח פנימי גדול יותר */
  margin-bottom: 15px; /* מרווח מתחת */
  width: 100%; /* רוחב שדה החיפוש */
  box-sizing: border-box; /* חישוב מרווחים נכון */
  border: 1px solid #ccc; /* צבע מסגרת עדין */
  outline: none; /* הסרת המסגרת הדיפולטית */
  transition: all 0.3s ease; /* אנימציה לחלקי העיצוב */
  height: 45px; /* גובה השדה */
}

.contacts-search-input:focus {
  border-color: #76ABAE; /* צבע מסגרת בזמן פוקוס */
  box-shadow: 0 0 5px rgba(118, 171, 174, 0.5); /* צל בזמן פוקוס */
}

.contacts-search-input::placeholder {
  color: #aaa; 
  font-size: 15px; 
}


.contacts-search-input:hover {
  border-color: #888; /* שינוי צבע המסגרת בזמן ריחוף */
}


.contacts-table {
  width: 100%;
  border-collapse: collapse;
}


.contacts-table th,
.contacts-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.contacts-table th {
  background-color: #f4f4f4;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 2;
}


.contact-action-buttons {
  margin-top: 0px;
}

.contact-button {
  padding: 8px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 8px;
  background-color: #27548A;
  cursor: pointer;
  font-size: 16px !important;
  transition: all 0.3s ease;
  font-weight: normal !important;
}



.contact-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}


.contact-buttons-container {
  display: flex;
  justify-content: flex-start; /* מיישר לשמאל */
  align-items: center;
  gap: 10px; /* רווחים בין הכפתורים */
}

.template-contact-button,
.contact-button {
  font-family: 'Outfit', sans-serif !important;
  color: rgb(255, 255, 255);
  background-color: #27548A;
  padding: 8px 20px;
  margin: 0; /* מסיר רווחים נוספים */
  border: none;
  border-radius: 8px; /* מסיר את הפינות המעוגלות */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  text-decoration: none;
}

/* .template-contact-button:hover,
.contact-button:hover {
  color: #ffffff;
  background-color: #578FCA;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
} */

.template-contact-button:active,
.contact-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.template-contact-button a {
  text-decoration: none; 
  color: inherit; 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
}

.contacts-header {
  color: var(--LogoColor);
  text-align: center;
  align-items: center;
  font-size: 40px;
  position: relative; 
  display: inline-block; 
}


.contact-edit-buttons {
  display: flex;
  gap: 10px;
}

.contacts-edit-button{
  border: #ffffff00;
  background-color: white;
  color: #3674B5;
  margin: 2px;
  cursor: pointer;
}


.contacts-delete-button {
  border: #ffffff00;
  background-color: white;
  color: #A31D1D;
  margin: 2px;
  cursor: pointer;
}

.contact-edit-button,
.contact-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.contacts-edit-button:hover,
.contacts-delete-button:hover{
  background-color: transparent !important; 
  box-shadow: none !important; 
}

.contacts-edit-button svg,
.contacts-delete-button svg {
  width: 20px;
  height: 20px; 
}



.contacts-table th:nth-child(1),
.contacts-table td:nth-child(1) {
  width: 5%;
}

.contacts-table th:nth-child(2),
.contacts-table td:nth-child(2) {
  width: 15%;
}

.contacts-table th:nth-child(3),
.contacts-table td:nth-child(3) {
  width: 10%;
}

.contacts-table th:nth-child(4),
.contacts-table td:nth-child(4) {
  width: 15%;
}

.contacts-table th:nth-child(5),
.contacts-table td:nth-child(5) {
  width: 10%;
}

.contacts-table th:nth-child(6),
.contacts-table td:nth-child(6) {
  width: 30%;
}

.contacts-table th:nth-child(7),
.contacts-table td:nth-child(7) {
  width: 7%;
}

.contact-counts {
  margin-top: 1%;
  text-align: left;
  color: var(--LogoColor);
  padding-right: 2%;
  font-size: 18px;
  font-weight: bold;
}



.custom-checkbox {
  appearance: none; 
  -webkit-appearance: none; 
  width: 22px; 
  height: 22px;
  border: 2px solid #27548A; 
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #d4d8d8; 
  border-color: #AAB99A; 
}

.custom-checkbox:checked::after {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #3d5a5c; 
}

.custom-checkbox:hover {
  border-color: #74447b;
  background-color: #f4f4f4;
}




.contacts-table-container {
  max-height: 500px; /* הגבלת גובה הטבלה */
  overflow-y: auto; /* גלילה אנכית */
  overflow-x: hidden; /* אין גלילה אופקית */
  border: 1px solid #ddd; /* מסגרת מסביב לטבלה */
  border-radius: 8px; /* עיגול פינות */
  background-color: #fff; /* רקע הטבלה */
}

/* עיצוב פס הגלילה */
.contacts-table-container::-webkit-scrollbar {
  width: 10px; /* רוחב פס הגלילה */
  background-color: #f0f0f0; /* צבע רקע המסילה */
}

.contacts-table-container::-webkit-scrollbar-track {
  background-color: #e6e6e6; /* צבע המסילה */
  border-radius: 5px; /* עיגול קצוות המסילה */
}

.contacts-table-container::-webkit-scrollbar-thumb {
  background-color: #888; /* צבע הידית */
  border-radius: 5px; /* עיגול קצוות הידית */
  border: 2px solid #f0f0f0; /* מסגרת מסביב לידית */
}

.contacts-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* צבע כהה יותר בזמן ריחוף */
}


.pagination-button{
  cursor: pointer;
}



.toggle-keys-button {
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}






.contacts-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}








.contact-keys-wrapper {
  position: relative;
}

.contact-keys-tooltip {
  display: none; /* מוסתר כברירת מחדל */
  position: absolute;
  top: 100%; /* תצוגה מתחת לתוכן */
  left: 50%; /* ממורכז לפי האלמנט */
  transform: translateX(-50%); /* יישור למרכז */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 10;
  width: 650px; /* רוחב מקסימלי כדי להציג יותר מפתחות */
  white-space: normal; /* מאפשר שבירת שורות */
}

.contact-keys-wrapper:hover .contact-keys-tooltip {
  display: flex; /* תצוגה בהובר */
  flex-wrap: wrap; /* מאפשר שבירת שורות */
  gap: 10px; /* מרווח בין הפריטים */
  justify-content: center; /* מרכז את התוכן */
}

.contact-keys-tooltip span {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin: 2px;
}

.contact-keys-container {
  width: 460px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}




.keys-filter-container {
  position: relative;
  cursor: pointer;
}





















.keys-filter-dropdown-container {
  position: fixed; /* קובע את האלמנט מעל הכל */
  left: 70%; /* ממקם את הדרופדאון באמצע המסך */
  top: 400px; /* גובה הדפדוף למעלה כדי לא להיחתך */
  transform: translateX(-50%); /* ממרכז את הדרופדאון בצורה מושלמת */
  background-color: white;
  border: 1px solid #000000;
  width: 300px;
  max-height: 320px; /* גובה מרבי עם גלילה */
  overflow-y: auto; /* גלילה לאורך */
  overflow-x: hidden; /* מניעת גלילה לרוחב */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* מעל כל האלמנטים */
  padding: 10px;
}


/* Customize scrollbar */
.keys-filter-dropdown-container::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.keys-filter-dropdown-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 4px;
}

.keys-filter-dropdown-container::-webkit-scrollbar-thumb {
  background-color: #888; /* צבע הידית */
  border-radius: 4px;
}

.keys-filter-dropdown-container::-webkit-scrollbar-thumb:hover {
  background-color: #626060; /* צבע הידית */
}


.close-dropdown-btn{
  margin-left: 85%;
  color: #B17457;
  background: none; /* ללא רקע */
  border: none; /* ללא מסגרת */
  font-size: 20px; /* גודל אייקון סגירה */
  cursor: pointer; /* סמן עכבר לחיץ */
}

.close-dropdown-btn:hover {
  color: #6c4736; /* שינוי צבע במעבר עכבר */
  background-color: #cccccc00;
}


.keys-filter-options label {
  display: block;
  font-size: 16px;
}

.search-keys-input {
  margin-left: 13%;
  align-items: center;
  width: 70%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.keys-filter-options input[type="checkbox"] {
  margin-right: 8px; 
}






.contact-button-bulk-actions{
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  gap: 10px; 
}

.contact-button-update-keys,
.contact-button-delete-button { 
  color: black;
  background-color: #AAB99A;
  padding: 8px 20px;
  margin-top: 2%; 
  border: none;
  border-radius: 8px; 
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  text-decoration: none;
}


.contact-button-delete-button:hover,
.contact-button-update-keys:hover {
  color: #000000;
  background-color: #E1EACD;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}


.keys-filter-options input[type="checkbox"] {
  width: 18px; /* רוחב ה-checkbox */
  height: 18px; /* גובה ה-checkbox */
  margin-right: 15px; /* ריווח מהטקסט */
  accent-color: #6c4736; /* צבע מותאם ל-checkbox */
  cursor: pointer; /* חוויית לחיצה טובה יותר */
}

.keys-filter-options input[type="checkbox"]:hover {
  transform: scale(1.2); /* הגדלה קלה במעבר עכבר */
  transition: transform 0.2s ease; /* מעבר חלק */
}
