
/* PostCard.css */

.post-card {
    width: 100%;
    max-width: 355px;
    background: white;
    padding: 20px;
    border-radius: 12px;
    text-align: right;
    direction: rtl;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    border-right: 2px solid #00FF9C; 
    border-bottom: 5px solid #00FF9C; 
    background-color: #F5F7F8; 
}




.post-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.post-content {
    padding: 15px;
}

.post-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #222;
}

.post-description {
    color: #555;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 12px;
}

/* פרטי הכותב ותאריך */
.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-top: 12px;
    color: #777;
}

/* תגיות */
.post-tags {
    display: flex;
    flex-wrap: wrap; 
    gap: 5px; 
    margin-top: 10px;
}

.tag {
    font-family: "Varela Round", sans-serif;
    background: #e3f2fd;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #007bff;
    font-weight: bold;
    white-space: nowrap; 
}

/* קישור לקריאה נוספת */
.read-more {
    display: inline-block;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
}

.read-more:hover {
    text-decoration: underline;
}

.post-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }