/* MediaOptions.css */
.mediaoptions-container {
  height: auto;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 30px; /* Adjust padding for larger screens */
  color: gray;
  margin: 10px;
}

/* Media options container */
.mediaoptions-items {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line when needed */
  justify-content: space-between; /* Distribute space evenly */
}

/* Style for individual media option button */
.mediaoptions-items div {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  flex: 1 0 22%; /* Each button takes 22% of the container width */
  box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
}

/* On hover, highlight the button */
.mediaoptions-items div:hover {
  border-color: var(--LogoColor);
  background-color: #f0f0f0;
}

/* Active state styles */
.mediaoptions-items div.active {
  border-color: var(--LogoColor);
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.2);
}

/* Icon styles if needed */
.mediaoptions-items svg {
  margin-bottom: 4px;
}

/* Text styles */
.mediaoptions-items div > div {
  font-size: 0.8rem;
}

.mediaoptions-file-size-info {
  font-size: 0.8em;
  color: #3f3f3f;
  margin-top: 5px;
  display: block;
}


/* Media options container (for button row) */
.mediaoptions-container {
  height: auto;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 30px; /* Adjust padding for larger screens */
  color: gray;
  margin: 10px;
}

.mediaoptions-items {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line when needed */
  justify-content: space-between; /* Distribute space evenly */
}

.mediaoptions-items div {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  flex: 1 0 22%; /* Each button takes 22% of the container width */
  box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
}

.mediaoptions-items div:hover {
  border-color: var(--LogoColor);
  background-color: #f0f0f0;
}

/* Active state styles */
.mediaoptions-items div.active {
  border-color: var(--LogoColor);
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.2);
}

.mediaoptions-file-size-info {
  font-size: 0.8em;
  color: #3f3f3f;
  margin-top: 5px;
  display: block;
}
/* Reduce padding for smaller screens */
@media (max-width: 768px) {
  .mediaoptions-items div {
      flex: 1 0 45%; /* Each button takes 45% of the container width on smaller screens */
  }
 
}
.template-generator-container-confirm{
  display: block; /* Make the button a block element */
  width: 30%; /* Set the button's width */
  margin: 0 auto; /* Horizontally center the button */
  text-align: center; /* Ensure the text inside the button is centered */
  padding: 10px 20px; /* Padding for the button */
  cursor: pointer; /* Make the button clickable */
}



/* Ensure the parent container takes full height */
.template-generator-container {
  display: flex;
  justify-content: space-between;
  
  align-items: flex-start;
  height: 100vh; /* Ensure the container takes up the full height of the screen */
}

/* Left side container */
.left-side {
  flex: 1;
  padding: 20px;
  background-color: white;
  width: 70%;
  height: auto; /* Auto height for left side */
}

/* Right side container */
.right-side {
  flex: 1;
  padding: 20px;
  background-color: #ece5dd; /* Right side background color */
  width: 30%;
  min-height: 200%; /* Ensures the background stretches across the full height */
  display: flex;
  flex-direction: column; /* Ensure content is stacked vertically */
  box-sizing: border-box; /* Prevent padding from affecting size */
  
}

/* For small screens */
@media (max-width: 768px) {
  .template-generator-container {
      flex-direction: column; /* Stack left and right sides vertically */
  }

  .left-side,
  .right-side {
      width: 100%; /* Full width on small screens */
  }
}