
.form-flows-generator-container {
  height: auto; /* גובה מקסימלי לפני שהגלילה מופעלת */
  overflow-y: auto; /* מאפשר גלילה רק אנכית אם יש צורך */
  overflow-x: hidden; /* מונע גלילה אופקית */
}





.h2FormFlowsPreview{
  text-align: center;
  margin-top: 0;
  padding-bottom: 5px; 
  border-bottom: 1px solid black; 
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2%;
}

.FormFlowsPreview-section {
    text-align: left;
}

.form-flows-preview-short-answer-preview-container {
    margin-bottom: 15px;
}
  
.form-flows-preview-short-answer-preview-container input {
    width: 90%;
    padding: 12px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 8px; /* Rounded corners */
    font-family: Arial, sans-serif;
    font-size: 16px;
}
  
.form-flows-preview-short-answer-preview-container-instruction-p {
    font-size: 14px;
    color: #666;
    margin-top: -5px; /* Slight overlap with the input field */
    margin-left: 10px; /* Indentation to align with input text */
    font-family: Arial, sans-serif;
}

.FormFlowsPreview-paragraph-preview-container textarea {
    width: 100%; /* Adjust width as needed */
    height: 100px; /* Set the height */
    resize: none; /* Disable resizing */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    padding: 10px; /* Add padding inside the textarea */
    border: 1px solid #ccc; /* Border similar to your example */
    border-radius: 5px; /* Rounded corners */
    font-size: 14px; /* Adjust font size if needed */
    font-family: Arial, sans-serif; /* Match font style */
    box-sizing: border-box; /* Ensure padding is included in width/height */
}

.FormFlowsPreview-paragraph-preview-container p {
    margin-top: 5px;
    font-size: 12px;
    color: #666; /* Text color similar to your example */
}

.form-flows-preview-image-preview-container {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
  }
.form-flows-preview-image-preview-container img{
    width: 100%; 
    align-items: center;
}

.FormFlowsPreview-single-choice-preview-container-option-item, .FormFlowsPreview-multiple-choice-preview-container-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding-right: 30px;
  }
  
  .FormFlowsPreview-single-choice-preview-container-option-label ,.FormFlowsPreview-multiple-choice-preview-container-option-label{
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis to indicate the overflow */
    flex-grow: 1;
    margin-right: 10px; /* Add some space between the label and the input */
  }
  
  .FormFlowsPreview-single-choice-preview-container-radio-input , .FormFlowsPreview-multiple-choice-preview-container-checkbox-input{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  /* Dropdown Container */
.FormFlowsPreview-dropdown-preview-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; /* Adjust spacing as needed */
  }
  
  /* Dropdown Label */
  .FormFlowsPreview-dropdown-preview-container-label {
    font-weight: bold;
    margin-bottom: 8px; /* Space between label and dropdown */
  }
  
  /* Dropdown Select */
  .FormFlowsPreview-dropdown-preview-container-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 14px;
    cursor: not-allowed; /* Shows that it's disabled */
  }
  
  /* Dropdown Option */
  .FormFlowsPreview-dropdown-preview-container-option {
    font-size: 14px;
  }
  
  /* Edit Mode Styles */

/* Opt-in Container */
.FormParamContent-optin-container {
    padding: 12px;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  
  /* Text Area for Opt-in Text */
  .FormParamContent-optin-textarea {
    width: 100%;
    height: 100px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  /* Character Count Display */
  .FormParamContent-optin-char-count {
    font-size: 12px;
    color: #666;
    text-align: right;
    margin-top: 4px;
  }
  
  /* Link Container */
  .FormParamContent-optin-link-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Link Label */
  .FormParamContent-optin-link-label {
    font-size: 12px;
    color: #999;
  }
  
  /* Buttons for editing/deleting link */
  .FormParamContent-optin-edit-link-button,
  .FormParamContent-optin-delete-link-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
  }
  
  .FormParamContent-optin-edit-link-button:hover,
  .FormParamContent-optin-delete-link-button:hover {
    border-color: #999;
  }
  
  /* Preview Mode Styles */
  
  /* Opt-in Preview Container */
  .FormFlowsPreview-optin-preview-container {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
  
  /* Checkbox */
  .FormFlowsPreview-optin-checkbox {
    margin-right: 8px;
  }
  
  /* Label with Opt-in Text */
  .FormFlowsPreview-optin-preview-label {
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
  }
  .form-flows-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-back-button {
    display: flex;
    align-items: center;
  }
  
  .header-back-button h2 {
    margin-left: 8px; /* Adjust spacing between icon and title */
  }
  
  /* Read More Link */
  .FormFlowsPreview-optin-read-more-link {
    margin-left: 8px;
    font-size: 14px;
    color: #28a745;
    text-decoration: none;
  }
  
  .FormFlowsPreview-optin-read-more-link:hover {
    text-decoration: underline;
  }
  .FormFlowsPreview-section-continue-btn,.FormFlowsPreview-section-continue-btn:hover {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    background-color: #3674B5;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  .FormFlowsPreview-datePicker-preview-container {
    display: flex;
    flex-direction: column; /* Arrange label, date input, and instruction vertically */
    margin-bottom: 15px; /* Space between date picker and other elements */
    text-align: left; /* Align text to the left */
}

.FormFlowsPreview-datePicker-preview-container span {
    font-weight: bold; /* Bold for the label */
    font-size: 16px; /* Adjust font size */
}

.custom-date-input {
    width: 100%;
    padding: 12px; /* Padding for better touch target */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.FormFlowsPreview-datePicker-preview-container-instruction {
    font-size: 14px; /* Adjust font size for instruction */
    color: #666; /* Color for instructions */
    margin-top: 5px; /* Space above instruction */
    text-align: left; /* Ensure instruction is also left-aligned */
}
.form-flows-preview-media-picker-custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Blue color */
  color: green;
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  border-radius: 24px solid green; /* Rounded corners */
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  width: 100%;
}



.form-flows-preview-media-picker-icon {
  margin-right: 8px; /* Space between icon and text */
  font-size: 18px; /* Adjust icon size if needed */
}