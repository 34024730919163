.incoming-message-trigger {
    padding: 20px;
    font-family: 'Outfit', sans-serif !important;
  }
  
  .incoming-message-trigger label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    color: #123458;
  }
  

  
  .pMessageType {
    display: inline-block;                  
    border-bottom: 1px solid #8bbcf0;     
    padding-bottom: 6px;            
    margin-top: 10px;                  
    width: 20%;                           
    text-align: center;                  
  }
  
  
  .MessageTypeIncomingMessageTrigger select {
    margin-top: 2%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .condition-groups {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* .condition-group-wrapper {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
  } */
  
  .add-group-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
  }
  
  
  .condition-action-add-group-wrapper {
    width: 15%;
    font-family: 'Outfit', sans-serif !important;
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
/*   
  .condition-action-add-group-wrapper:hover {
    background-color: #45a049;
  } */
  
  .group-relation-select {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .incoming-message-trigger-history-mode {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .incoming-message-trigger-history-mode label {
    font-weight: 600;
    color: #333;
  }
  