.workflow-designer {
  font-family: 'Outfit', sans-serif !important;
  margin: 20px;
  padding: 20px;
}
.workflow-steps-container {
  width:100%;
  font-family: 'Outfit', sans-serif !important;
}

.delete-action-button {
  padding: 10px;
  font-size: 20px;
  margin: 10px 0;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #E83F25;
}

.add-action-button {
  font-family: 'Outfit', sans-serif !important;
  display: block;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: #295F98;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}


.add-action-button:hover{
  color: #ffffff;
  background-color: #578FCA;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}



.workflow-step {
  margin-bottom: 20px;
  padding: 10px;
  /* border: 3px solid #ddd; */
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.step-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 5px double #8bbcf0;
}

.expand-delete-container {
  display: flex;
  align-items: center;
}

.step-header {
  font-size: 20px;
  flex-grow: 1;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #123458;
}

.trigger-selector,
.action-selector {
  font-family: 'Outfit', sans-serif !important;
  margin-bottom: 10px;
}

.trigger-selector h3,
.action-selector h3 {
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #123458;
}

.trigger-selector select,
.action-selector select {
  font-family: 'Outfit', sans-serif !important;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}
/* 
.incoming-message-trigger .condition-groups .condition-group-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
} */

.add-group-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.group-relation-select {
  margin-left: 10px;
}

.expand-button{
  border: none;  
  cursor: pointer;
  background-color: #00000000;
  font-size: 20px;
}

.condition-group label {
  display: block;
  margin-bottom: 10px;
}

.condition-group button {
  margin-top: 10px;
}

.templates-button-clicked-handler {
  margin-top: 20px;
}



.add-trigger-button{
  font-family: 'Outfit', sans-serif !important;
  display: block;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: #295F98;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;

}

.add-trigger-button:hover {
  color: #ffffff;
  background-color: #578FCA;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}








