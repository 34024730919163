@import './FormParam.css';

.form-flows-generator-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.form-flows-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-color: #fffbfb; */
  border-bottom: 1px solid #dee2e6;
}

.flow-name-category h2 {
  color: #5C7285;
  margin: 0px;
  font-size: 28px;
  padding-bottom: 5px; 
  /* border-bottom: 1px solid #497D74;  */
}

.form-flows-generator-container-save-button-container {
  text-align: right;
}

.form-flows-generator-container-save-button {
  background-color: #3674B5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.form-flows-generator-container-save-button:hover {
  background-color: #578FCA;
}

.form-flows-body {
  display: flex;
  flex-grow: 10;
  overflow: hidden;
}

.FormFlowsEdit-content-section {
  background-color: #fff;
}

.FormFlowsPreview-section {
  /* background-color: #f1f1f1; */
}

.FormFlowsPreview-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.FormFlowsPreview-content h3 {
  margin-top: 0;
}

.FormFlowsPreview-content button {
  margin-top: 10px;
}
.form-flows-body {
  display: flex;
  height: calc(100% - 60px); /* Adjust this to fit header height */
}



/* .FormFlowsScreens-section, .FormFlowsEdit-content-section, .FormFlowsPreview-section {
  width: 33.33%;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.FormFlowsScreens-section {
  background-color: #f9f9f9;
}

.FormFlowsScreens-list {
  margin-top: 20px;
}

.screen-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.screen-item span {
  flex-grow: 1;
}

.screen-item button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.2em;
}

.screen-item:hover {
  background-color: #e9e9e9;
} 

 .FormFlowsScreens-section {
  width: 20%; 
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.FormFlowsEdit-content-section,
.FormFlowsPreview-section {
  width: 40%;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.FormFlowsEdit-content-section {
  background-color: #fff;
}

.FormFlowsPreview-section {
  background-color: #f1f1f1;
} */