/* Home.css */

.home-container {
    width: 100%;
    height: 100%;
    max-width: 1366px;
    display: flex;
    z-index: 1;
}

.menu {
    width: 20%; /* Adjust as needed */
    /* Other styling for menu */
}

.chatpage {
    width: 80%; /* Adjust as needed */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #d7dbd6;
    position: relative;
    z-index: 1;
}
:root{
    --LogoColor: #2f001c;
}