.previewRun-Container {
  
  display: flex;
  flex-direction: column; /* Flex direction for the entire component */
  padding: 20px; /* Padding for the container */
}

.previewRun-ContentContainer {
  display: flex;
  flex-direction: row; /* Row direction ensures side-by-side layout */
  justify-content: space-between; /* Spread items apart */
  align-items: stretch; /* Align items to stretch full height */
  gap: 20px; /* Add spacing between the containers */
  margin-top: 20px;
  width: 100%;
}
.previewRun-Container-dialog-title{
  text-align: center;
  font-weight: bolder;
}
/* Styling for message preview section */
.message-preview-container {
  flex-grow: 1; /* Allow this container to expand and take equal space */
  min-width: 350px; /* Ensure minimum width for message preview */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  height: 100%; /* Ensure it takes up the full height */
  overflow: hidden; /* Prevent overflow */
}

.template-preview-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ddd;
  height: 100%;
}

/* Styling for the contacts table */
.previewRun-RecipientTabl {
  flex-grow: 1; /* Allow this container to expand and take equal space */
  padding: 20px;
  min-width: 350px; /* Ensure minimum width for the contacts table */
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 100%; /* Ensure it takes up the full height */
  overflow-y: auto; /* Enable scrolling for overflow */
}

.previewRun-Container-dialog-tbl {
  width: 100%;
  border-collapse: collapse;
}

.previewRun-Container-dialog-tbl th {
  background-color: #f2f2f2;
  color: #333;
  padding: 10px;
  font-weight: bold;
  text-align: left;
}

.previewRun-Container-dialog-tbl td {
  border: 1px solid #ddd;
  padding: 8px;
}

.previewRun-Container-dialog-tbl tr:hover {
  background-color: #f5f5f5;
}
