.campaign-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-top: 2%;
  font-family: 'Outfit', sans-serif !important;
}

.pCampaign{
  font-size: 17px;
}

.campaign-form {
  width: 100%;
  max-width: 1400px;
  padding: 20px;
}

.campaign-form-row {
  margin-bottom: 15px;
}

.campaign-container label {
  display: block;
  margin-bottom: -2px;

}

.pCampaigninput {
  border: none;
  width: 58.5%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center; 
  display: block; 
  margin: 0 auto;
  font-family: 'Outfit', sans-serif !important;
}



.campaign-container select {
  width: 60%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: 'Outfit', sans-serif !important;
}  



.campaign-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 70%;
  max-width: 1400px;
  padding: 10px 20px;
  position: relative;
  margin: 0 auto;
}

.IconButtonCampaign {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0% !important; 
}

.campaign-form-header h2 {
  font-size: 40px;
  font-weight: bold;
  color: var(--LogoColor);
  flex-grow: 1;
  text-align: center;
}



 .MuiIconButton-root {
  margin-right: auto;
}

.campaign-form-row button {
  margin-right: 10px;
}

.campaign-form-row button:last-child {
  margin-right: 0;
}

.templatesHandler-container-tbl {
  overflow-x: auto;
  margin-top: 20px;
}

.templatesHandler-tbl {
  width: 100%;
  border-collapse: collapse;
}

.templatesHandler-tbl th,
.templatesHandler-tbl td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.templatesHandler-tbl th {
  background-color: #f2f2f2;
}


.campaignButton{
    margin-left: 47%;
    color: #ffffff;
    background-color: #27548A;
    padding: 8px 20px;
    border: none;
    border-radius: 8px; 
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 2%;
}

/* .campaignButton:hover {
    color: #ffffff;
    background-color: #578FCA;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  } */


  .campaignSelectType{
    width: 61% !important;
  }


  .TypeAMessage{
    width: 60.5% !important;
  }