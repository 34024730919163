/* Flex container for the catalog page */
.catalog-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Spacing between the left and right sections */
    padding: 20px;
}

/* Styling for the left section */
.catalog-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.catalog-left h2{
    text-align: center;
    color: var(--LogoColor);
}
/* Styling for the right section */
.catalog-right {
    flex: 1;
    display: flex;
    padding-top: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Ensure the iframe (video) takes up most of the right section */
.catalog-right iframe {
    max-width: 100%;
    height: 315px; /* Fixed height for the video */
}
