/* Modal Background */
.credit-card-info-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content Box */
.credit-card-info-modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Allow absolute positioning of close button */
}

/* Close Button Styling */
.credit-card-info-close-btn {
    color: red;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}



/* Rest of the CSS remains the same */
.credit-card-info-form {
    display: flex;
    flex-direction: column;
}

.credit-card-info-form-group {
    margin-bottom: 20px;
}

.credit-card-info-label {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
}

.credit-card-info-input-container {
    position: relative;
}

.credit-card-info-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    letter-spacing: 2px;
}

.credit-card-info-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.credit-card-info-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
}

.credit-card-info-form-group-flex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.credit-card-info-expiration,
.credit-card-info-cvv {
    width: 48%;
}

.credit-card-info-submit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Varela Round", sans-serif;
}

.credit-card-info-submit-btn:hover {
    background-color: #0056b3;
}

/* Adjusting error message positions */
.credit-card-info-form-group-flex .credit-card-info-expiration,
.credit-card-info-form-group-flex .credit-card-info-cvv {
    position: relative;
    
}

.credit-card-info-expiration .credit-card-info-error,
.credit-card-info-cvv .credit-card-info-error {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 12px;
    color: red;
}

.credit-card-info-form-group-flex {
    margin-bottom: 50px;
}

.credit-card-info-brand-message {
    text-align: left;
    margin-bottom: 20px; /* Increase the gap between the brand message and card number */
}

.credit-card-info-brand-message span {
    margin-right: 10px;
}

.credit-card-info-modal-header{
    text-align: center;
}

 

  
 

  .credit-card-info-checkbox-termofpayments {
    font-size: 14px;
    color: #333;
    text-align: left; /* Ensures the label is aligned left */
    
}
.credit-card-info-checkbox-termofpayments{
    width: 100%;
}


.credit-card-info-checkbox-termofpayments a {
    color: #007bff; /* Color for the link */
    text-decoration: none;
}

.credit-card-info-checkbox-termofpayments a:hover {
    text-decoration: underline;
}









  
.credit-card-info-form-group-checkbox-conatiner {
    display: flex;
    gap: 8px; /* מרווח בין ה-checkbox לטקסט */
}

.credit-card-info-form-group-checkbox-input {
    width: 16px; /* גודל checkbox */
    height: 16px;
    cursor: pointer;
    margin: 0;
    vertical-align: middle; /* ממקם טוב יותר עם הטקסט */
}

.credit-card-info-checkbox-termofpayments {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    display: flex;
    align-items: center; /* מיישר את כל האלמנטים לאורך המרכז */
}

.credit-card-info-checkbox-termofpayments a {
    color: #007bff;
    text-decoration: none;
    margin-left: 5px;
}

.credit-card-info-checkbox-termofpayments a:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .credit-card-info-form-group-flex {
        gap: 40px;
    }
    .credit-card-info-cvv{
        /* margin-top: 20px; */
    }
}


/* 
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    width: 60px;
    height: 60px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #578E7E;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .credit-card-info-error-popup {
    background-color: #ffdddd;
    color: #d8000c;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #d8000c;
  }
  
  .credit-card-info-error-close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #d8000c;
  }
  



  */