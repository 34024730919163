
.cw-accordion {
    direction: rtl; /* קובע את כיוון הטקסט מימין לשמאל */
  }
  
  .cw-accordion__item .cw-label {
    text-align: right; /* יישור כותרת לימין */
  }
  
  .cw-accordion__item .cw-acordion-cont {
    text-align: right; /* יישור התוכן לימין */
    direction: rtl; /* כיוון מימין לשמאל */
  }
  

.faq-contact{
    direction: rtl;
}



.cw-fix {
    max-width: 800px !important;
    margin: auto;
    width: 100%;
    padding: 40px 0px;
    font-family: "Varela Round", sans-serif;
   }
   
   .cw-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
   }
   
   .cw-section__title {
    font-size: 30px; 
    text-align: center;
    margin-bottom: 10px;
    color: #5b32b4; 
    background: linear-gradient(90deg, #a8c8bf, #578E7E);
    -webkit-background-clip: text; 
    background-clip: text; 
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px; 
  }
  
  .faq-top p {
    font-size: 15px; 
    text-align: center;
    color: #333;
    margin: 5px 0;
    line-height: 1.6;
    direction: rtl;
  }
  
  .faq-top {
    padding: 10px 15px;
    background: #f9f8ff;
    border-radius: 8px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    position: relative;
    overflow: hidden;
  }
  
  .faq-top:before {
    content: "";
    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, #578E7E, transparent 70%);
    opacity: 0.2;
    z-index: 0;
  }
  
  .faq-top h2 {
    position: relative;
    z-index: 1;
    animation: fadeIn 1.2s ease-in-out; 
  }
  
  .faq-top p {
    position: relative;
    z-index: 1;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

   
   .cw-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
   }
   
   .cw-accordion__item {
    padding: 0.8rem 1rem;
    background: #fff;
    position: relative;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 4px 10px #ede9fe;
    border: #cdcdcd 1px solid;
    border-radius: 25px;
   }
   
   .cw-accordion__item .cw-label {
    padding-left: 0px;
    font-size: 20px;
    position: relative;
    width: 100%;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
   }
   
   .cw-accordion__item .cw-label:after {
    color: #00bef0;
    background-color: #fffff2;
    position: absolute;
    left: 1%;
    top: 50%; 
    transform: translate(-50%, -50%);
    content: "+"; 
    font-size: 14px;
    border: #000 1px solid; 
    width: 24px; 
    height: 24px; 
    border-radius: 50%; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    line-height: 1; 
    text-align: center; 
    transition: all 0.4s ease; 
  }
  

  .cw-accordion__item.cw-open .cw-label:after {
    content: "-"; /* שינוי למינוס */
    transform: translateY(-50%) rotate(180deg); /* רוטציה מלאה למינוס */
  }
  
  .cw-accordion__item .cw-acordion-cont {
    max-height: 0; /* גובה אפס במצב סגור */
    overflow: hidden; /* מניעת גלילה */
    opacity: 0; /* התוכן לא נראה */
    transition: max-height 0.6s ease, opacity 0.6s ease; /* מעבר חלק לפתיחה */
    font-size: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .cw-accordion__item.cw-open .cw-acordion-cont {
    max-height: 500px; /* גובה מספיק לתוכן מלא */
    opacity: 1; /* התוכן נראה */
    padding-top: 15px;
    padding-bottom: 15px;
    transition: max-height 0.6s ease, opacity 0.6s ease, padding 0.6s ease;
  }
  
  .cw-accordion__item:not(.cw-open) .cw-acordion-cont {
    transition: none; /* מבטל אנימציה בזמן סגירה */
    max-height: 0; /* מוודא שהבלוק נסגר מיד */
    opacity: 0; /* מוודא שהבלוק הופך לבלתי נראה מיד */
    padding-top: 0;
    padding-bottom: 0;
  }
  


 .cw-accordion__item .cw-acordion-cont ul {
    padding-left: 20px;
   }
   
   .cw-accordion__item .cw-acordion-cont ul li {
    margin: 7px 0px;
   }

   .faq-contact {
    text-align: center;
    padding: 10px 0;
   }
   
   .faq-contact h3 {
    margin: 0px 0px 15px 0px;
   }
   
   .faq-contact .contact-btn {
    background-color:#9DC08B;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.2s;
    cursor: pointer;
    position: relative;
   }
   
   .faq-contact .contact-btn:hover {
    background-color: #fffff2;
    color: #00bef0;
   }





/* התאמה למסכים עד 768px */
@media (max-width: 768px) {
    .cw-accordion__item {
      max-width: 85%; /* הרוחב מוגבל ל-90% מרוחב המסך */
      padding: 0.6rem 0.8rem; /* הפחתת ריווח פנימי */
      border-radius: 15px; /* פינות עגולות יותר */
      margin: 0 auto; /* מרכז את הבלוק */
    }
  }
  
  /* התאמה למסכים קטנים מאוד עד 480px */
  @media (max-width: 480px) {
    .cw-accordion__item {
      max-width: 85%; /* הרוחב מוגבל ל-95% מרוחב המסך */
      padding: 0.5rem 0.6rem; /* ריווח פנימי קטן יותר */
      border-radius: 10px; /* פינות עגולות קטנות יותר */
      margin: 0 auto; /* מרכז את הבלוק */
    }
  }
  

/* התאמה למסכים עד 768px */
@media (max-width: 768px) {
    .cw-fix {
      padding: 20px 10px;
    }
  
    .cw-section__title {
      font-size: 1.8rem;
      letter-spacing: 1px;
    }
  
    .faq-top {
      padding: 15px;
      border-radius: 6px;
    }
  
    .faq-top p {
      font-size: 14px;
      line-height: 1.4;
    }
  
    .cw-accordion__item {
      padding: 0.6rem 0.8rem;
      border-radius: 15px;
    }
  
    .cw-accordion__item .cw-label {
      font-size: 14px;
    }
  
    .cw-accordion__item .cw-label:after {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  
    .cw-accordion__item .cw-acordion-cont {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .faq-contact {
      padding: 10px 0;
    }
  
    .faq-contact .contact-btn {
      font-size: 10px;
      padding: 10px 20px;
      border-radius: 15px;
    }
  }
  
  /* התאמה למסכים קטנים מאוד עד 480px */
  @media (max-width: 480px) {
    .cw-fix {
      padding: 10px 5px;
    }
  
    .cw-section__title {
      font-size: 1.5rem;
      letter-spacing: 0.5px;
    }
  
    .faq-top {
      padding: 10px;
      border-radius: 4px;
    }
  
    .faq-top p {
      font-size: 12px;
      line-height: 1.3;
    }
  
    .cw-accordion__item {
      padding: 0.5rem 0.6rem;
      border-radius: 10px;
    }
  
    .cw-accordion__item .cw-label {
      font-size: 12px;
    }
  
    .cw-accordion__item .cw-label:after {
      width: 18px;
      height: 18px;
      font-size: 10px;
    }
  
    .cw-accordion__item .cw-acordion-cont {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .faq-contact .contact-btn {
      font-size: 9px;
      padding: 8px 15px;
      border-radius: 10px;
    }
  }
  