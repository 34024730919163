/* WebhookTrigger.css */

.webhook-trigger {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  font-family: 'Outfit', sans-serif !important;
}
  
  .inline-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center; 
  }
  
  .inline-form-group label {
    text-align: center;
    font-size: 18px;
    flex-grow: 1;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    color: #123458;
  }
   
  .formGroupWebhook{
    text-align: left;
    font-size: 15px;
    font-weight: normal !important;
    margin-bottom: 10px;
    color: #123458;
    font-family: 'Outfit', sans-serif !important;
  }

  .inline-form-group input {
    flex: 1;
    padding: 5px;
    font-size: 14px;
    font-family: 'Outfit', sans-serif !important;
  }

  .json-schema-textarea.invalid {
    border: 1px solid red;
  }
  
  .webhook-trigger-error-text {
    color: red;
    font-size: 0.9em;
    margin-top: 4px;
  }
  