.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-container-header {
    background-color: #ebe7e1;
    border-left: 1px solid #8B6F4F;
    border-right: 1px solid #8B6F4F;
    padding: 0px 5px;
  }
  
  .header-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;
        padding: 10px 0;
      }

      .row-categorization > div {
        flex: 1;
        min-width: 200px;
        display: flex;
        align-items: center;
        gap: 10px;
      }

.chat-user-details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-info {
    display: flex;
    flex-direction: column;
  }

.chat-user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    gap: 4px; 
}
.chat-container-controls {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    gap: 8px; 
    align-items: center;
}


.chat-container-chat-search {
    display: flex;
    align-items: center;
    background-color: #ebe7e1;
    padding: 6px 10px;
    border-radius: 8px;
    width: 85%;
  }
  
  .chat-container-chat-search .search-icon {
    font-size: 20px;
    color: #8B6F4F;
    margin-bottom: 1px;
  }
  
  .chat-container-chat-search input {
    border-radius: 6px;
    border: none;
    outline: none;
    border: 0.5px solid #8B6F4F;
    background-color: #fbfaf6;
    color: #333;
    font-size: 14px;
    margin-left: 8px;
    padding: 4px 5px;
    flex: 1;
  }
  
  

.chat-user-img {
    width: 44px;
    margin: 0 15px;
}

.chat-user-img > img {
    width: 100%;
    border-radius: 50%;
}

.chat-container-header-btn {
    margin-right: 10px;
}

.chat-display-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* background-image: url('../../assets/chat3.jpg'); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center; */
    background-color: #fbfaf6;
    border: 1px solid #8B6F4F;
}


.chat-input {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures the buttons are spread across */
    padding: 12px;
    background-color: #fbfaf6;
    position: relative;
    border: 1px solid #8B6F4F;
    border-top: none;
    border-bottom: none;
}

.chat-input-row {
    display: flex;
    gap: 10px; /* Adjust space between buttons */
    align-items: center;
}

.chat-input-btn {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between rows */
}

.chat-input-btn-row {
    display: flex;
    justify-content: flex-start; /* Center the buttons */
    align-items: flex-start;
    max-width: 70px;
    color: #128c7e;
    margin-left: 5px;
    gap: 6px;

}

.chat-input-btn-record-btn, .chat-input-btn-sent-template {
    color: #128c7e;
    background: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
}

.chat-input-btn-record-btn{
    font-size: 25px;
}
.chat-input-btn-second-row{
    padding-right: 5px;
}
.chat-input-btn > .MuiSvgIcon-root {
    font-size: 26px;
    color: #128c7e;
    cursor: pointer;
    margin-right: 5px;
}

.chat-input > form {
    flex: 1;
    display: flex;
    align-items: center;
}

.chat-input > form > textarea {
    flex: 1;
    border-radius: 15px;
    border: 1px solid #8B6F4F;
    outline: none;
    padding: 10px;
    margin-right: 10px;
    resize: none;
    font-family: inherit;
    font-size: 14px;
}

.chat-new-conversation-btn {
    background-color: #336D82; 
    color: white;
    font-size: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    display: block;
    margin: 0px auto;
  }

.chat-input-send-btn {
    background-color: #128c7e;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.chat-input-send-btn > .MuiSvgIcon-root {
    font-size: 22px;
    cursor: pointer;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.contact-name,
.contact-phone {
  direction: rtl;
  margin: 0;
  font-size: 14px;
  color: #333;
}

.selected-contact-name {
    font-weight: bold;
    margin-right: auto; 
    font-size: 16px;
    color: #333;
}

.chat-user-details {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .owner-label {
    font-size: 14px;
    color: #444;
    margin: 0;
  }

  .owner-label span {
    font-weight: bold;
  }

  
.owner-text {
    margin: 0;
    font-size: 14px;
    color: #555;
}


.change-owner-button {
    background-color: #27548A;
    color: white;
    font-size: 14px;
    padding: 8px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .owner-select {
    padding: 8px 12px;
      font-size: 14px;
      border-radius: 6px;
      border: 1px solid #8B6F4F;
      background-color: #fbfaf6;
      color: #333;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      flex: 1;
  }

.chat-new-category-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-new-category-modal-content {
    background: white;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
}

.chat-new-category-modal-content h3 {
    margin-top: 0;
}

.chat-new-category-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.chat-new-category-modal-actions button {
    padding: 8px 12px;
}

.chat-new-category-modal-actions-submit {
    background-color: var(--LogoColor);
}

.chat-new-category-modal-actions-cancel {
    background-color: red;
}

.chat-new-category-modal-details-text-area {
    width: 100%;
}

.chat-expiration-time .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-expiration-time {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid var(--LogoColor);
    color: var(--LogoColor);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fbfaf6;
    transition: all 0.3s ease-in-out;
    text-align: center;
    user-select: none;
  }
  
/* כפתור כוכב */
.chat-container-starred-messages-header {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
  }
  
  .chat-container-starred-messages-header:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  
.category-select label,
.chat-status-selector label {
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
}

/* .row-search-starred {
    gap: 10px;
  } */


  .category-status-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
    flex-direction: row;
    align-items: center;
    overflow-x: auto; 
  }

  .category-select,
  .chat-status-selector {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      padding: 8px 12px;
      /* background-color: #f9f9f9; */
      /* border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.05);
      border: 1px solid #e0e0e0; */
      max-width: 300px;
      margin-bottom: 10px;
  }
  
  .category-select label,
  .chat-status-selector label {
      font-weight: 600;
      white-space: nowrap;
      color: #333;
      min-width: 80px;
      font-size: 15px;
  }
  
  .category-select select,
  .chat-status-selector select {
      padding: 8px 12px;
      font-size: 14px;
      border-radius: 6px;
      border: 1px solid #8B6F4F;
      background-color: #fbfaf6;
      color: #333;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      flex: 1;
  }
  


.chat-container-media-preview {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    max-width: 150px;
}

.chat-container-media-preview-item {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-container-media-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
}

.chat-container-media-preview-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
}

.chat-container-media-preview-document {
    display: flex;
    align-items: center;    
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: #333;
    word-wrap: break-word;
    padding: 5px;
    height: 100%;
}

.chat-container-media-preview-document a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    overflow-wrap: anywhere;
}

.chat-container-media-preview-document a:hover {
    text-decoration: underline;
}
.chat-container-close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    padding: 1px;
    font-size: 12px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 10px; 
    height: 10px; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-container-close-icon:hover {
    background-color: rgba(255, 0, 0, 0.9);
    color: #fff;
}


