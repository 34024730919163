.action-input-field {
    position: relative;
    display: flex; /* Align the input and picker to the same line */
  }
  
  .picker-dropdown {
    position: absolute;
    top: 0;
    left: 100%; /* Align to the right of the input field */
    margin-left: 8px; /* Add some spacing between the input and the dropdown */
    width: 250px; /* Width of the dropdown */
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);   
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .picker-search {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ddd;
    outline: none;
    box-sizing: border-box;
  }
  
  .picker-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .picker-item:hover {
    background-color: #f0f0f0;
  }
  

  .InputActionInputField{
    width: 90%;
    font-family: 'Outfit', sans-serif !important;
    margin-top: 5px;
    padding: 8px 8px;
    border: 1px solid #aacbe3;
    border-radius: 6px;
    font-size: 14px;
    background-color: white !important;
    outline: none;
    flex: 1; 
  }
