.templatesHandler-location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.templatesHandler-location-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between each field */
    justify-content: center;
}

.templatesHandler-location-field {
    display: flex;
    flex-direction: column;
    min-width: 200px; /* Minimum width for each field, adjust as necessary */
}

.templatesHandler-location-field label {
    margin-bottom: 5px;
    font-weight: bold;
}

.templatesHandler-location-field input {
    padding: 5px;
    font-size: 14px;
}
.templatesHandler-tbl {
    width: 100%; /* Ensure the table takes the full width of the container */
    border-collapse: collapse; /* Remove gaps between table cells */
}

.templatesHandler-tbl th, .templatesHandler-tbl td {
    padding: 10px; /* Add padding for better spacing */
    border: 1px solid #ddd; /* Add border to cells */
    text-align: left; /* Align text to the left */
    white-space: nowrap; /* Prevent text wrapping */
    /* overflow: hidden;  */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}

.templatesHandler-tbl input[type="text"], 
.templatesHandler-tbl select {
    width: 100%; /* Make inputs and selects take the full width of the cell */
    box-sizing: border-box; /* Include padding and border in the element's width */
    padding: 5px; /* Add padding inside the input/select */
    font-size: 14px; /* Adjust font size */
    max-width: 100%; /* Ensure that the element doesn't exceed the cell width */
}

.TemplatesHandler-container-select-template {
    font-size: 14px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2%;
}
.TemplatesHandler-container-select-template.min-width {
    width: 61%;
}

.TemplatesHandler-container-select-template.full-width {
    width: 100%;
}



.SelectDataTemplate {
    width: 100% !important;
    display: block;
    box-sizing: border-box; /* מבטיח שה-padding וה-border לא יגדילו את האלמנט */
}

