.sidebar {
    min-width: 300px;
    width: 30%;
    border-right: 1px solid lightgray;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fbfaf6;
}

.sidebar {
    position: relative; 
}

.sidebar-header {
    height: 50px;
    padding: 5px 0 5px 10px;
    background-color: #fbfaf6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.sidebar-header-img {
    width: 42px;
}

.sidebar-header-img > img {
    width: 100%;
    cursor: pointer;
    border-radius: 100%;
}

.sidebar-header-btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    color: #8B6F4F;
    cursor: pointer;
    position: relative;
    left: 42px; 
    font-size: 21px;
}

.sidebar-header-btn > .MuiSvgIcon-root {
    font-size: 23px;
}

.sidebar-search {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #f6f6f6;
    background-color: #fbfaf6;
}

.sidebar-search-input {
    width: 90%;
    display: flex;
    align-items: center;
    height: 30px;

}


.sidebar-filter-btn {
    cursor: pointer;
    margin-left: 10px;
    color: #8B6F4F;
}

.sidebar-search-input > .MuiSvgIcon-root {
    font-size: 22px;
    color: #8B6F4F;
    margin-left: 10px;
}

.sidebar-search-input > input {
    border-color: transparent;
    border: none;
    outline: none;
    margin-left: 6px;
    margin-bottom: 2px;
    width: 80%;
    border-bottom: 0.5px solid #8B6F4F;
    background-color: #fbfaf6;

}

.sidebar-chat-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden; 
}


.sidebar-filter-panel {
    position: absolute;
    top: 108px; 
    right: 15px; 
    width: 280px; 
    background-color: #fbfaf6;
    padding: 15px;
    border-radius: 8px;
    z-index: 10; 
    display: flex;
    flex-direction: column;
    border: 1px solid #8B6F4F;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); 
}

.sidebar-filter-panel h3 {
    font-size: 18px;
    font-weight: bold;
    color: #5A3E2B;
    margin-bottom: 10px;
    text-align: center;
}

.sidebar-filter-panel label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    padding: 8px;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
}

.sidebar-filter-panel label:hover {
    cursor: pointer;
}

.sidebar-filter-panel label span {
    font-size: 14px;
    color: #333;
}

.sidebar-filter-panel label input[type="checkbox"] {
    accent-color: #8B6F4F;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.sidebar-filter-panel label select {
    border: none;
    background: none;
    width: 100%;
    padding: 6px 0;
    margin-left: 3%; 
    border-bottom: 1px solid #C9B194;
    cursor: pointer;
    font-size: 14px;
    outline: none;
    text-indent: 3%; 
}


.sidebar-filter-panel .close-btn {
    background: #D84040;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: background 0.3s ease-in-out;
    margin-top: 10px;
}

.sidebar-filter-panel .close-btn:hover {
    background: #B92A2A;
}

@media (max-width: 768px) {
    .sidebar-filter-panel {
        width: 90%; 
        right: 5%;
    }
}
