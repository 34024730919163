/* Adjusted classnames */
.campaignResult-container-dialog {
  height: 100%;
  width: 100%; /* Optional: set width to 100% for full width */
}

.campaignResult-container-dialog-title {
  text-align: center;
  color: var(--LogoColor);
}

.campaignResult-container-dialog-content {
  max-height: 100%;
  overflow: auto;
}

.campaignResult-container-dialog-tbl {
  width: 100%;
  border-collapse: collapse;
}
.campaignResult-StatusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures the icon stays vertically centered */
}
.campaignResult-container-dialog-tbl th,
.campaignResult-container-dialog-tbl td {
  border: 1px solid #ddd;
  padding: 8px;
}
.recipientStatus {
  display: flex;
  align-items: center; /* Vertically center the label and icon */
  gap: 5px; /* Add spacing between the label and icon */
}

.recipientStatusLabel {
  font-size: 14px; /* Adjust the font size for the label */
  color: #333; /* Optional: Adjust text color */
}

.campaignResult-container-dialog-tbl th {
  background-color: #f2f2f2;
}

.campaignResult-container-dialog-tbl-tablehead {
  color: var(--LogoColor);
  font-weight: bold;
}
.percentage-cell {
  text-align: center; /* Center-align both the number and percentage */
  font-size: 14px; /* Adjust the font size for readability */
}

.percentage {
  font-size: 12px; /* Slightly smaller font for percentage */
  color: var(--LogoColor); /* Optional: Use a lighter color for distinction */
  margin-top: 4px; /* Add spacing between the number and percentage */
  display: block; /* Ensure percentage is on a new line */
}