.outbound-message-action {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add space between elements */
  margin: 10px 0; /* Add margin to separate the component from others */
  padding: 20px; /* Add padding for better layout */
  border: 1px solid #ddd; /* Add a subtle border */
  border-radius: 8px; /* Add rounded corners */
  background-color: #f9f9f9; /* Set a light background color */
  font-family: 'Outfit', sans-serif !important;
}

.outbound-message-action label {
  font-weight: 500;
  margin-bottom: 5px;
  display: block; 
  font-size: 16px; 
  color: #123458 !important;
}

.outbound-message-action select {
  width: 100%; /* Full width for dropdown */
  padding: 10px; /* Add padding inside the select box */
  font-size: 14px; /* Set a consistent font size */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 4px; /* Add rounded corners */
  background-color: #fff; /* White background for dropdown */
  margin-top: 5px; /* Add space above the dropdown */
  font-family: 'Outfit', sans-serif !important;

}

.outbound-message-action textarea {
  height: 150px; /* Set a static height */
  resize: none; /* Disable resizing */
  overflow: auto; /* Enable scroll bars if content exceeds the area */
  padding: 10px; /* Add padding for better readability */
  font-size: 14px; /* Adjust font size for readability */
  border: 1px solid #ccc; /* Add border for better appearance */
  border-radius: 4px; /* Add rounded corners */
  background-color: #fff; /* Set a white background for textarea */
  margin-top: 5px; /* Add space above the textarea */
  font-family: 'Outfit', sans-serif !important;
}

.outbound-message-action .templates-handler {
  margin-top: 10px; /* Add space above the templates handler */
}

.outbound-message-action .action-input-field {
  margin-top: 10px; /* Add space above the action input field */
  width: 100%; /* Full width for the input field */
}

.outbound-message-action button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  padding: 10px 15px; /* Add padding inside buttons */
  font-size: 14px; /* Set a consistent font size */
  border: none; /* Remove borders */
  border-radius: 4px; /* Add rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
}

.outbound-message-action button:hover {
  background-color: #0056b3; /* Darken background on hover */
}

.outbound-message-action .delete-icon {
  font-size: 20px; /* Adjust icon size */
  color: #dc3545; /* Set delete icon color to red */
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: 10px; /* Add space to the left of the icon */
  transition: transform 0.2s, color 0.2s; /* Add hover animation */
}

.outbound-message-action .delete-icon:hover {
  color: #b52a37; /* Darken red on hover */
  transform: scale(1.1); /* Slightly enlarge icon on hover */
}
