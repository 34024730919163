/* Basic Info Form Styles */
.basic-info-form {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-top: -60px;
    direction: rtl;
}


.basic-info-form h2 {
    text-align: center;
    position: relative;
    display: inline-block;
    padding-bottom: 5px; 
    border-bottom: 1px solid #9DC08B; 
}

.basic-info-form label{
    text-align: center;
}

.basic-info-form .basic-info-form-input-row {
    display: flex;
    flex-direction: column; 
    gap: 20px; 
}

.basic-info-form .basic-info-form-input-row .basic-info-form-input-field {
    width: 100%; 
}

.basic-info-form .basic-info-form-input-row .basic-info-form-input-field input {
    width: 100%;
    min-width: 500px; 
    /* max-width: 600px; */
    font-family: "Varela Round", sans-serif; 
    margin-bottom: 2%;
}


.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.basic-info-form button {
    padding: 12px 30px;
    font-size: 16px;
    background-color: #578E7E;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 5px;
    min-width: 100px;
}

.basic-info-form button:hover {
    background-color: #86A788;
}

.basic-info-form button:active {
    transform: scale(0.96);
}

.basic-info-form .prev-button {
    background-color: #86A788; 
}

.basic-info-form .next-button {
    background-color: #578E7E; 
}

.basic-info-form .error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}



.basicinfo-phone-input-container {
    display: flex;
    width: 100%; 
    position: relative;
    justify-content: flex-end; 
    align-items: center; 
    
}

 .flag-dropdown {
    order: 0; 
    display: flex;
    align-items: center;
}


.basicinfo-phone-input-container input {
    width: 100%; 
    padding-left: 10px; 
}

.country-list {
    position: absolute;
    left: 50% !important; 
    transform: translateX(-50%) !important;
    z-index: 1000;
}

.country-list li {
    display: flex;
    align-items: center; 
}


.country-list li .country-name {
    margin-left: 5px; 
}
.basicinfo-phone-input-field{
    text-align: right;
}
.selected-flag {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100%;
    width: 100%;
}
.country{
    justify-content: flex-end;
}
.search-box{
    text-align: left;
}
.basicinfo-phone-input-field{
    text-align: left;
    
}



.swal2-confirm {
    background-color: #28a745 !important; 
    color: white !important;
    font-size: 18px;
    font-family: "Varela Round", sans-serif;
}

.swal2-cancel {
    background-color: #dc3545 !important; 
    color: white !important;
    font-size: 18px;
    font-family: "Varela Round", sans-serif;
}

.rtl-popup {
    direction: rtl;
    text-align: right;
    font-family: "Varela Round", sans-serif;
}


.error-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    font-weight: bold;
}
.basic-info-form input::placeholder {
    text-align: right; /* Align placeholder text to the right */
}


@media (max-width: 600px) {
    .country-list{
        transform: translateX(0) !important; 

    }
}

@media (max-width: 600px) {
    .basic-info-form {
        padding: 15px;
    }
    .basic-info-form .basic-info-form-input-row .basic-info-form-input-field input {
        max-width: 350px;
        min-width: 100px;
    }

    .basic-info-form .basic-info-form-input-row {
        flex-direction: column; 
    }

    .basic-info-form .basic-info-form-input-field {
        margin-bottom: 10px;
    }

    .basic-info-form .button-container {
        flex-direction: column;
        align-items: stretch; 
    }

    .basic-info-form button {
        width: 100%;
        margin-bottom: 10px;
    }
}