.scheduled-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left for better readability */
    max-width: 400px; /* Limit the max width for a more compact layout */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #a5a0a0;
    border-radius: 8px;
    background-color: #d7e6d864;
}

.scheduled-container label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.schedule-type-selector,
.scheduled-starting,
.scheduled-timezone,
.scheduled-repeat {
    width: 100%; /* Ensure each section takes the full width */
    margin-bottom: 20px;
}

.schedule-type-selector select,
.scheduled-starting input[type="time"],
.scheduled-starting .react-datepicker-wrapper,
.scheduled-timezone select,
.scheduled-input input[type="number"],
.scheduled-interval select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.scheduled-input-Repeat{
  align-items: center;
  justify-content: center;
  width: 96%;
}

.scheduled-starting {
    display: flex;
    flex-direction: column;
}

.scheduled-starting .react-datepicker-wrapper {
    width: calc(100% - 20px); /* Adjust to fit within container */
    margin-bottom: 10px;
}
.scheduled-starting .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.scheduled-starting input[type="time"] {
    width: 100%;
}

.scheduled-timezone {
    display: flex;
    flex-direction: column;
}

.scheduled-repeat {
    display: flex;
    flex-direction: column;
}

.scheduled-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.scheduled-input input[type="number"] {
    width: 50px; /* Adjust width for interval number input */
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.scheduled-interval select {
    flex-grow: 1;
    padding: 8px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
