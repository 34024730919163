.chat-message-bottom {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 100%;
}

.chat-message-status {
    display: flex;
}

.chat-message-actions {
    display: flex;
    font-size: 16px;
}


   .chat-message {
    direction: rtl;
    display: flex;
    flex-direction: column; 
    margin: 10px 16px;
    position: relative;
    max-width: 80%;
    padding: 8px 10px;
    border-radius: 12px;
    height: auto;
    box-sizing: border-box;
    word-break: break-word;
  }
  
.chat-message-text {
    font-size: 13px;
    margin-bottom: 6px;
    white-space: pre-wrap;
}

.chat-message-media {
    max-width: 100%;
    max-height: 200px; 
    object-fit: cover; 
    cursor: pointer; 
    border-radius: 5px;
}

.chat-message-media-expanded {
    width: 100%;
    height: auto;
    max-height: 90vh;
}

.chat-message-date,
.chat-message-sender {

    font-size: 11px;
    color: gray;
    font-weight: normal;
    align-self: flex-start;
}

.emoji-picker-react {
    position: absolute !important;
    top: -325px;
}

.media-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.media-modal img,
.media-modal video {
    max-width: 90%;
    max-height: 90vh;
    object-fit: contain;
}
