.contactFilterRow-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adjust spacing between rows as needed */
}

.contactFilterRow-cell {
    flex: 1;
    margin-right: 10px; /* Adjust spacing between cells as needed */
}

.contactFilterRow-cell:last-child {
    margin-right: 0; /* Remove right margin for the last cell */
}

/* Set specific width for the LookUp cell */
.contactFilterRow-cell.lookup-cell {
    flex: 2; /* Make this cell take twice the width of other cells */
}

.contactFilterRow-cell input[type="text"],
.contactFilterRow-cell select,
.contactFilterRow-cell.lookup-cell .lookup-component {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.contactFilterRow-cell button {
    padding: 5px 10px;
    background-color: #27548A;
    color: white;
    border: none;
    cursor: pointer;
}

.contactFilterRow-cell button:hover {
    background-color: #0056b3;
}

.contactFilterRow-delete-btn {
    color: #ffffff;
    padding: 8px 20px;
    border: none;
    border-radius: 8px; 
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 2%;   
    cursor: pointer;
    background-color: #BF3131;
}
