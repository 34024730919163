.onborarding-progress-container {
    max-width: 100%;
    width: 70%;
    margin-bottom: 50px;
    text-align: center;
    padding: 20px;
    direction: rtl;
    border: 1px solid #9DC08B;
    border-radius: 12px; 
}


.onborarding-progress-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.onborarding-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Varela Round", sans-serif;
    position: relative;
    margin-right: 10px;
}

.onborarding-step .onborarding-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #e6e6e6;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: bold;
    color: #e6e6e6;
    transition: all 0.3s ease;
    font-family: "Varela Round", sans-serif;
}

.onborarding-step.active .onborarding-circle {
    border: 1px solid #4d4c4c;
    background-color: #9DC08B;
    color: #fff;
    font-family: "Varela Round", sans-serif;
}

.onborarding-step .onborarding-label {
    font-size: 1em;
    color: #333;
    margin-top: 10px;
    font-weight: bold;
    font-family: "Varela Round", sans-serif;
}
@media (max-width: 768px) {
    .onborarding-progress-container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 10px;
    }
    .onborarding-progress-wrap{
        align-items: flex-start; /* Change alignment to flex-start or remove centering */

    }
}