.chat-contacts-timeline {
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: #fbfaf6;
    font-size: 14px;
    font-family: 'Outfit', sans-serif !important;
}

.contacts-keys-section,
.timeline-section {
    font-size: 14px;
    background-color: #fbfaf6 !important;
   
}

.timeline-entry {
    background-color: #fbfaf6;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
    font-size: 0.9rem;
}

.add-timeline-note textarea {
    font-family: 'Outfit', sans-serif !important;
    margin-bottom: 5px; 
    border: 1px solid #ccc;
    border-radius: 0px; 
    font-size: 14px;
    background-color: #fbfaf6;
}

/* .add-timeline-note button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px; 
    font-size: 0.85rem; 
    cursor: pointer;
} */

.chat-page {
    background-color: #fbfaf6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px; 
    font-family: 'Outfit', sans-serif !important;
}

.chat-contacts-timeline {
    width: 20%;
    margin-top: 10px; 
    background: #fbfaf6 !important;
    margin-top: 0%;
}

.chat-contacts-timeline-contactskeys{
    font-size: 8px;

}

.chat-contacts-timeline-contactskeys-header{
    font-size: 14px;
}

.update-keys-button {
    background-color: #27548A;
    color: #ffffff;
    font-size: 14px; 
    margin-top: 4px; 
    margin-bottom: 4px; 
    padding: 8px 14px; 
    border: none; 
    border-radius: 10px; 
    cursor: pointer; 
}



