/* MediaOptions.css */
.mediaoptions-container {
    height: 10%;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 30px; /* Adjust padding for larger screens */
    color: gray;
    margin: 10px;
}

/* Reduce padding for smaller screens */
@media (max-width: 768px) {
    .mediaoptions-container {
        padding-bottom: 10px; /* Adjust padding for smaller screens */
    }
}

.mediaoptions-items {
    /* Remove the border-radius style if it's not necessary */
}

.mediaoptions-items div {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    margin: 4px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.mediaoptions-items div:hover {
    border-color: var(--LogoColor);
    background-color: #f0f0f0;
}

/* Active state styles */
.mediaoptions-items div.active {
    border-color: var(--LogoColor);
    box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.2);
}

/* Icon styles if needed */
.mediaoptions-items svg {
    /* Icon-specific styles */
    margin-bottom: 4px;
}

/* Text styles */
.mediaoptions-items div > div {
    font-size: 0.8rem;
}
.mediaoptions-file-size-info {
    font-size: 0.8em;
    color: #3f3f3f;
    margin-top: 5px;
    display: block;
}
