/* .form-param-container {
  margin-top: 20px;
}

.options-list {
  margin-top: 10px;
}

.option-item {
  position: relative;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.sub-options-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  position: relative;
  background-color: #fff;
  border: 1px solid #3674B5;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  padding: 0;
}

.option-item:hover .sub-options-list {
  max-height: 200px;
  padding: 10px; 
}

.sub-options-list div {
  padding: 10px;
  cursor: pointer;
}

.sub-options-list div:hover {
  background-color: #e9e9e9;
}

.formParamButton {
  display: block;
  margin: 0 auto;
}

.formParamButton {
    text-align: center;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    background-color: #3674B5;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.formParamButton:hover {
  background-color: #578FCA;
}

.formParamButton svg {
  font-size: 20px;
} */


.form-param-container {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.options-list {
  margin-top: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-item {
  position: relative;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background: #f8f8f8;
  transition: background 0.3s;
  border-radius: 6px;
  margin-bottom: 10px; 
}


.option-item:hover {
  background: #e0e0e0;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.option-header svg {
  font-size: 20px;
  color: #3674B5;
  transition: transform 0.2s ease-in-out;
}

.sub-options-list {
  max-height: 200px; /* הגבלת גובה */
  overflow-y: auto; /* גלילה אם יש הרבה אופציות */
  background: #ffffff;
  border: 1px solid #3674B5;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  transition: max-height 0.3s ease-in-out;
}

/* עיצוב הסקרול בר */
.sub-options-list::-webkit-scrollbar {
  width: 8px;
}

.sub-options-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.sub-options-list::-webkit-scrollbar-thumb {
  background: #3674B5;
  border-radius: 4px;
}

.sub-options-list::-webkit-scrollbar-thumb:hover {
  background: #255a90;
}

.sub-option {
  padding: 8px;
  cursor: pointer;
  transition: background 0.2s;
  border-bottom: 1px solid #ddd;
}

.sub-option:last-child {
  border-bottom: none;
}

.sub-option:hover {
  background-color: #e9e9e9;
}

.formParamButton {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  background-color: #3674B5;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.formParamButton:hover {
  background-color: #578FCA;
}

.formParamButton svg {
  font-size: 20px;
}
