.outbound-message-trigger .inline-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space between fields */
  }
  
  .outbound-message-trigger .inline-field label {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 10px; /* Space between label and input */
  }
  
  .outbound-message-trigger .inline-field input {
    width: 100%;
    max-width: 500px; /* Limit the maximum width of the input */
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .outbound-message-trigger textarea {
    width: calc(100% - 20px); /* Adjust width to fit within the container, considering padding/margin */
    height: 150px; /* Set a static height */
    resize: none; /* Disable resizing */
    overflow: auto; /* Enable scroll bars if content exceeds the area */
    padding: 10px; /* Add padding for better readability */
    font-size: 16px; /* Adjust font size as needed */
    border: 1px solid #ccc; /* Add border for better appearance */
    border-radius: 4px; /* Add border radius */
  }
  