.user-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}

/* Header Section */
.user-form__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 10px 20px;
    margin: 0 auto;
}

/* Header Title */
.user-form__header h1 {
    font-size: 36px;
    font-weight: bold;
    color: var(--LogoColor);
    flex-grow: 1;
    text-align: center;
}

/* Form Section */
.user-form__form-container {
    width: 100%;
    max-width: 1300px;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

/* Form Input Section */
.user-form__input {
    margin-bottom: 20px;
}

/* Input & Select Styling */
.user-form__input input,
.user-form__input select {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-family: "Varela Round", sans-serif;
    font-size: 16px;
    transition: 0.3s;
}

/* Focus Effect on Inputs */
.user-form__input input:focus,
.user-form__input select:focus {
    border-color: #295F98;
    outline: none;
    box-shadow: 0 0 5px rgba(41, 95, 152, 0.3);
}

/* Submit Button */
.user-form-submit-btn {
    background-color: #295F98;
    color: white;
    padding: 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    transition: background 0.3s ease-in-out;
}

.user-form-submit-btn:hover {
    background-color: #578FCA;
}
.form-control.user-form-phone-number-input-field{
    width: 100%;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .user-form__header h1 {
        font-size: 28px;
    }

    .user-form-submit-btn {
        font-size: 16px;
    }
}
