.screen-progress-indicator {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.screen-indicator {
    width: 100px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.screen-indicator.completed {
    background-color: #4caf50; /* Green for completed screens */
    color: white;
}

.screen-indicator.incomplete {
    background-color: #ccc; /* Gray for incomplete screens */
    color: black;
}

.screen-indicator:hover {
    transform: scale(1.05);
}
