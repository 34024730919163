.FormFlowsEdit-content-section {
  padding: 20px;
  /* background-color: #fdfbfb; */
}

.FormFlowsEdit-content-section-h2 {
  margin-top: 0;
  padding-bottom: 5px; 
  border-bottom: 1px solid black; 
}

.FormFlowsEdit-content-section input[type="text"] {
  width: 60%;
  padding: 12px;
  margin-bottom: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background: #f7f7f7;
  transition: all 0.3s ease-in-out;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.759);
  color: #333;
}

.FormFlowsEdit-content-section input[type="text"]:hover {
  background: #eeeeee;
}

.FormFlowsEdit-content-section input[type="text"]:focus {
  outline: none;
  background: #ffffff;
  box-shadow: 0 0 8px #3674B5;
}

.FormFlowsEdit-content-section-screen-title {
  border-left: 4px solid #2DAA9E;
  padding-left: 14px;
}

.FormFlowsEdit-content-section-button-label {
  border-left: 4px solid #FF9800;
  padding-left: 14px;
}


.FormFlowsEdit-content-section button {
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.form-param-content {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: white;
}

.form-param-content.dragging {
  background-color: #f0f0f0;
}

.form-param-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-param-header button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.form-param-content textarea {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; 
  font-family: inherit;
}

.char-count {
  font-size: 12px;
  color: #666;
  text-align: right;
  margin-top: 5px;
}

.form-param-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.form-param-footer label {
  display: flex;
  align-items: center;
}

.form-param-footer input[type="checkbox"] {
  margin-left: 10px;
}
.formParamContent-delete-option-icon{
  padding-top :10px
}