
.contactFilter-tbl{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Outfit', sans-serif !important;
}

.contactFilter-header {
    border-bottom: 1px solid black;
    padding-bottom: 5px; 
    margin-left: 30%;
    margin-right: 30%;
}
  
.contactFilter-tbl-add-btn{
    font-family: 'Outfit', sans-serif !important;
    margin-left: 46%;
    color: #ffffff;
    background-color: #27548A;
    padding: 8px 20px;
    border: none;
    border-radius: 8px; 
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 2%;   
    cursor: pointer;
}