/* Styles for the TagInputContainer */
.tag-input-container {
    /* border: 1px solid #ccc; */
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    font-size: 10px; /* Reduced font size */
   
  }
  
  /* Styles for each tag */
  .tag {
    padding: 2px 5px; /* Compact padding */
    background-color: #e2e2e2;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 10px; /* Reduced font size */
    gap: 2px; /* Add a small gap between text and button */
  }
  
  
  /* Styles for the tag input field */
  .tag-input {
    flex: 1;
    border: none;
    padding: 2px 5px; /* Adjusted padding for compact input */
    font-size: 10px; /* Reduced font size */
  }
  
  .tag-input:focus {
    outline: none;
  }
  
/* Ensure the remove button styles are applied specifically */
.tag-input-container .remove-button {
    background: transparent; /* No background */
    border: none; /* Remove default button border */
    margin-left: 2px; /* Reduced margin */
    color: red; /* Default color for the button */
    cursor: pointer; /* Pointer cursor for better UX */
    padding: 0; /* Remove padding to eliminate extra space */
    line-height: 1; /* Ensure button height aligns with text */
    font-size: 10px; /* Match tag font size */
  }
  
  
  /* Apply hover styles */
  .tag-input-container .remove-button:hover {
    color: var(--LogoColor); /* Use your custom --LogoColor variable */
    text-decoration: none; /* Ensure no underline or other effects */
  }
  
  /* Ensure focus doesn't apply default browser styles */
  .tag-input-container .remove-button:focus {
    outline: none; /* Remove the default focus outline */
    color: var(--LogoColor); /* Optional: Set focus color */
  }
  .small-input .tag-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Space between rows */
  }
  
  .small-input .tag {
    flex: 0 0 auto; /* Ensure tags stay on one row */
  }
  
  .small-input .tag-input {
    flex: 1 1 100%; /* Force input to take a full new row */
    font-size: 14px; /* Smaller button font size */
    padding: 4px 10px; /* Adjust padding */
    border: 0.5px solid #8B6F4F;
    border-radius: 4px;
  }
  
/* Apply hover styles */
.tag-input-container .remove-button:hover {
    color: var(--LogoColor); /* Use your custom --LogoColor variable */
  }
  
  /* Ensure focus doesn't apply default browser styles */
  .tag-input-container .remove-button:focus {
    outline: none; /* Remove the default focus outline */
  }