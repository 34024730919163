
.blog-container {
    text-align: center;
    padding: 20px;
    font-family: "Varela Round", sans-serif;
}


.blog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    direction: rtl;
    text-align: right;
    padding: 40px;
    margin: 5% auto 20px;
    max-width: 1400px; 
    width: 90%;
    background: linear-gradient(135deg, #FFF5E4, #EBE8DB); 
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.blog-header-content {
    flex: 1;
    max-width: 50%;
    z-index: 2; 
}

.blog-header-image {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
}

.blog-header-image img {
    width: 100%;
    max-width: 450px; 
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.blog-header h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.blog-header h2 {
    font-size: 24px;
    color: #555;
}

.blog-header .highlight {
    color: #1F7D53; 
    font-weight: bold;
}

.graphic-element {
    position: absolute;
    z-index: 1;
}

.circle {
    width: 120px;
    height: 120px;
    background-color: #DCD7C9;
    border-radius: 50%;
    top: -30px;
    left: -30px;
}

.wave {
    width: 150px;
    height: 60px;
    background-color: #DCD7C9;
    border-radius: 100px 100px 0 0; 
    position: absolute;
    bottom: -10px;
    right: -30px;
    transform: rotate(-15deg); 
}





.trending-title-container {
    max-width: 1200px; 
    width: 100%;
    text-align: right; 
    margin: 0 auto;
    position: relative;
    padding: 20px 0; 
    transition: text-align 0.3s ease-in-out; 
}

.trending-title {
    font-size: 30px;
    font-weight: bold;
    text-align: inherit; 
    margin-bottom: 5px;
    position: relative;
    display: block; 
    width: 100%;
}

.trending-title::after {
    content: "";
    display: block;
    width: 100%; 
    height: 4px;
    background-color: #66D2CE;
    border-radius: 2px;
    margin-top: 5px;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(350px, 1fr)); 
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 40px;
    direction: rtl;
    max-width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .posts-grid {
        grid-template-columns: repeat(2, minmax(290px, 1fr));
        gap: 40px;
    }
}

@media (max-width: 768px) {
    .posts-grid {
        grid-template-columns: 1fr; 
        gap: 30px;
        justify-content: center; 
        display: flex; 
        flex-direction: column; 
        align-items: center; 
    }

    .post-card {
        width: 80%;
        max-width: 400px; 
        margin: 0 auto; 
    }

    .trending-title-container {
        text-align: center; 
        display: flex;
        justify-content: center;
    }
}



.filter-container {
    font-family: "Varela Round", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    padding: 10px;

    width: fit-content;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.filter-container select {
    font-family: "Varela Round", sans-serif;
    padding: 6px 10px; 
    font-size: 14px; 
    border: 0.5px solid #ccccccb6;
    border-radius: 15px;
    cursor: pointer;
    width: auto; 
    min-width: 100px; 
    text-align: center;
}


.tags-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}


.tag-button {
    font-family: "Varela Round", sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 6px 12px;
    font-size: 12px;
    border: 1px solid #dddddd8d;
    border-radius: 15px;
    cursor: pointer;
    background: none;
    transition: all 0.3s ease-in-out;
    color: #333;
}

.tag-button .tag-icon {
    font-size: 12px;
    transition: transform 0.3s ease-in-out;
}

.tag-button.active {
    background: #e4efe7bc;
}

.tag-button.active .tag-icon {
    transform: rotate(180deg);
}


.reset-button {
    font-family: "Varela Round", sans-serif;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid #dddddd8d;
    border-radius: 15px;
    background-color: #e4efe7bc;
    cursor: pointer;
}


/* התאמה למובייל */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        align-items: center;
    }

    .tags-filter {
        justify-content: center;
    }
}





.pagination {
    font-family: "Varela Round", sans-serif;
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 8px; 
    margin-top: 20px;
}

.pagination button {
    font-family: "Varela Round", sans-serif;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.pagination button:disabled {
    cursor: not-allowed;
}

.pagination .active {
    font-weight: bold;
    font-size: 18px;
}

