.activity-log-container {
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
}

.activity-log-header {
    color: var(--LogoColor);
    font-size: 40px;
    margin-bottom: 20px;
}

.activity-log-table-container {
    border-radius: 8px;
    max-height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ddd;
}

.activity-log-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.activity-log-table th,
.activity-log-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
}

.activity-log-table th {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #f4f4f4;
    z-index: 2;
    padding: 12px;
    font-weight: bold;
    text-align: left;
}

.activity-log-view-button {
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 0.8em;
    background-color: #3674B5;
}

.activity-log-view-button:hover {
    background-color: #295F98;
}

@media (max-width: 768px) {
    .activity-log-container {
        width: 100%;
        padding: 10px;
    }
}
