.condition-group {
    background-color: #f9fafe;
    border: 1px solid #dbe5f1;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    font-family: 'Outfit', sans-serif !important;
  }
  
  .condition-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    color: #123458;
  }
  
  .condition-group select {
    margin-top: 5px;
    padding: 6px 10px;
    border: 1px solid #aacbe3;
    border-radius: 6px;
    font-size: 14px;
    background-color: white;
    outline: none;
    flex: 1; 
  }
  
  .condition-group-add-action,
  .condition-group-remove-action {
    font-family: 'Outfit', sans-serif !important;
    margin-top: 15px;
    margin-right: 10px;
    padding: 8px 14px;
    background-color: #2a7ade;
    border: none;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
  }
  
  .condition-group-remove-action {
    background-color: #d14b4b;
  }
  
  /* .condition-group-add-action:hover {
    background-color: #1c5fc2;
  } */
  
  /* .condition-group-remove-action:hover {
    background-color: #b43c3c;
  }
   */
  .condition-group select:disabled {
    background-color: #eef3f8;
    color: #888;
  }
  