/* 
.import-results-popup {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 800px;
    margin: 0 auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    max-height: 500px;
    overflow-y: auto;
  }
  
  .import-results-popup h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .summary-section {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
    
  }
  
  .summary-section h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .summary-section p {
    margin: 5px 0;
  }
  
  .details-section table {
    width: 100%;
    border-collapse: collapse;

  }
  
  .details-section th,
  .details-section td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .details-section th {
    background-color: #f2f2f2;
  }
  
  .details-section td.status {
    font-weight: bold;
  }
  
  .details-section tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .details-section tr:hover {
    background-color: #ddd;
  }
  
  button {
    padding: 10px 20px;
    background-color: var(--LogoColor);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .status-success {
    color: green;
  }
  
  .status-failed {
    color: red;
  }
  
  .status-exist {
    color: rgb(13, 0, 255);
  } */


  /* רקע כהה מאחורי הפופאפ */
.import-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* רקע שקוף כהה */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* מעל כל האלמנטים האחרים */
}

/* הפופאפ עצמו */
.import-results-popup {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 800px;
  max-height: 80vh; /* שלא יהיה גבוה מדי */
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
}

/* כפתור סגירה */
.import-results-popup .close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

/* כפתור סגירה בעיצוב מעוגל */
.import-results-popup .close-button:hover {
  color: red;
}

/* טבלה מעוצבת יותר */
.details-section table {
  width: 100%;
  border-collapse: collapse;
}

.details-section th, .details-section td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.details-section th {
  background-color: #f2f2f2;
}

.details-section tr:hover {
  background-color: #ddd;
}

/* button {
  padding: 10px 20px;
  background-color: var(--LogoColor);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */



.CloseExelPopAp{
  margin-top: 15px;
  font-family: "Delius", serif; 
  font-size: 15px;
}