.form-flow-list-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px; 
    font-family: 'Outfit', sans-serif !important;
  }
  
.form-flow-list-container input{
  font-family: 'Outfit', sans-serif !important;
  width: 99%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

  .form-flow-list-header {
    color: var(--LogoColor); 
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  .form-flow-list-table-container {
    border-radius: 8px; 
    max-height: 540px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-right: 1px solid #ddd; 
    border-left: 1px solid #ddd; 
    border-bottom: 1px solid #ddd; 
    border-top: 1px solid #ddd; 
  }
  
  .form-flow-list-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
  }
  
  .form-flow-list-table th,
  .form-flow-list-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .form-flow-list-table th {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #f4f4f4; /* שמירה על רקע */
    z-index: 2; /* מבטיח שהכותרות יהיו מעל הנתונים */
    padding: 12px;
    font-weight: bold;
    text-align: left;  }
  
  .form-flow-edit-button,
  .form-flow-delete-button {
    font-family: 'Outfit', sans-serif !important;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 0.9em;
  }
  
  .form-flow-edit-button {
    background-color: rgba(0, 104, 239, 0); 
    color: #3674B5;
    font-size: 20px;
    border: none;
  }
  
  .form-flow-delete-button {
    background-color: #c8233300; 
    color: #A31D1D;
    font-size: 20px;
    border: none;
  }

  .form-flow-publish-button {
    background-color: #c8233300; 
    color: #77B254;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

  .form-flow-history-button{
    background-color: #c8233300; 
    color: #FF9D23;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

  .form-flows-action-buttons a {
    text-decoration: none;
  }
  
   .add-new-form-flow-button {
    font-family: 'Outfit', sans-serif !important;
    color: rgb(255, 255, 255);
    background-color: #27548A;
    padding: 8px 20px;
    margin-top: 1%; 
    border: none;
    border-radius: 8px; 
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    text-decoration: none;
    margin-bottom: 1%;
}

/* 
.add-new-form-flow-button:hover {
    color: #ffffff;
    background-color: #578FCA;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  } */


  .form-flow-deprecate-button {
    font-family: 'Outfit', sans-serif !important;
    background-color: #8174A0;
    color: white; 
    font-size: 13px; 
    padding: 4px 14px; 
    border: none; 
    border-radius: 6px; 
    cursor: pointer; 
    display: flex;
    align-items: center;
    gap: 8px; 
  }
  
  .form-flow-deprecate-button:hover {
    background-color: #7e5cad72; 
    color: black;
  }


  @media (max-width: 768px) {
    .form-flow-list-container {
      margin: 10px;
      padding: 10px;
    }
  
    .form-flow-list-header {
      font-size: 20px;
    }
  
    .form-flow-list-table-container {
      max-height: 300px;
    }
  }
  

  .form-flow-list-table-container::-webkit-scrollbar {
    width: 7px;
  }
  
  .form-flow-list-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
  
  .form-flow-list-table-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    min-height: 15%;
  }
  
  .form-flow-list-table-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  