
.HomePageMainSection {
  display: block; 
  visibility: visible;
}

.heading-xl {
  direction: ltr;
  margin: 0 auto;
  font-family: "Poppins", sans-serif; 
  font-weight: 300;
  font-size: 60px;
  color: #333333; 
  text-align: center; 
}

.PHomePageMainSection {
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(14px, 1.2rem, 1.5rem); 
  text-align: center;
  white-space: nowrap;
  flex-wrap: wrap;
  max-width: 85vw;
  padding: 10px;
  margin-bottom: -2%;
  gap: 10px;
}

.PHomePageMainSection img {
  font-size: 0;
  width: clamp(40px, 4rem, 6rem); 
  height: auto;
  max-width: 40px; 
}

.boldTextMainHhomePage {
  font-weight: bold;
}

.logomainHhomePage {
  direction: rtl;
  width: clamp(40px, 4rem, 6rem);
  height: auto;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}



.banner-column {
  position: relative;
  display: grid;
  align-items: center;
  row-gap: 3rem;
}

.mainHhomePage {
  margin-top: 6%;
  margin-left: 3%;
  margin-right: 3%;
  background-color: #f5f7fc;
  border-radius: 40px; 
  font-family: "Varela Round", sans-serif;
  direction: rtl;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 400% 400%;
  animation: gradientShift 20s ease infinite;
  overflow: hidden;
  position: relative;
}

.banner-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.75rem;
  width: 100%;
}
  
.heading-x {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;
    text-align: center;
  margin: 0 auto; 
}




  .paragraph {
    text-align: center; 
    font-size: 18px; 
    margin: 0 auto; 
    width: 50%; 
    line-height: 1.8;
    word-break: break-word; 
    direction: rtl; 
  }
  
  .banner-links {
    position: absolute;
    top: 30%;
    right: 1.5rem;
    display: grid;
    justify-items: center;
    row-gap: 0.5rem;
    opacity: 0;
    visibility: hidden;
  }
  
 
  .banner-links > * {
    font-size: 1.25rem;
    line-height: 1.25;
    color: rgb(0, 0, 0);
  }
  
  .banner-links::before {
    position: absolute;
    content: "";
    top: -3rem;
    width: 4rem;
    height: 2px;
    transform: rotate(90deg);
    background: rgb(0, 0, 0);
  }
  
  .banner-links::after {
    position: absolute;
    content: "";
    bottom: -3rem;
    width: 4rem;
    height: 2px;
    transform: rotate(90deg);
    background: rgb(0, 0, 0);
  }
  
  .btn-container {
    font-family: "Varela Round", sans-serif;
    display: flex;
    gap: 4rem; 
    justify-content: center; 
    align-items: center; 
    width: 100vw; 
    margin-top: -1%;
  }
  
  .btn {
    font-family: "Varela Round", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    padding: 0.75rem 2rem; 
    font-size: 1rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    background-color: #635DFF; 
    color: white; 
    border-radius: 50px; 
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

  
  .btn:hover {
    background-color: #5348e0; 
  }

  .btn:active {
    transform: scale(0.95); 
  }


  .btn-secondary {
    color: #ffffff;
    background-color: #28a745;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .btn-secondary:hover {
    background-color: #186129;
  }
  
  .btn i, .btn svg {
    font-size: 24px; 
    width: 24px; 
    height: 24px;
}



.scroll-arrow {
  position: absolute;
  bottom: 20px;
  left: 53%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #000000;
  cursor: pointer;
  animation: bounce 1.5s infinite;
}

.scroll-arrow:hover {
  color: #445073;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}





@media screen and (max-width: 1024px) {
  .mainHhomePage {
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
    height: auto;
    padding: 2rem;
    border-radius: 20px;
    flex-direction: column;
  }

  .heading-xl {
    font-size: 45px;
  }

  .paragraph {
    font-size: 16px;
    width: 80%;
  }

  .btn-container {
    flex-direction: column;
    gap: 2rem;
  }

  .btn {
    width: 80%;
    padding: 0.75rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .PHomePageMainSection {
    flex-direction: column;
    font-size: 14px;
    margin-top: -4%;
    gap: 3px;
  }

  .logomainHhomePage {
    width: 30px;
  }

  .heading-xl {
    font-size: 30px;
  }

  .paragraph {
    font-size: 15px;
    width: 90%;
  }

  .btn-container {
    gap: 1.5rem;
  }

  .btn {
    width: 90%;
    font-size: 15px;
  }

  .scroll-arrow {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .mainHhomePage {
    margin-top: 5%;
    padding: 1.5rem;
  }

  .PHomePageMainSection{
    margin-top: 15%;
  }

  .heading-x{
    font-size: 27px;
    width: 85%;
  }

  .heading-xl {
    font-size: 25px;
  }

  .paragraph {
    font-size: 15px;
    width: 85%;
    line-height: 1.5;
  }

  .btn {
    width: 50%;
    padding: 0.6rem;
    font-size: 15px;
  }

  .btn-container {
    gap: 1rem;
  }

  .scroll-arrow {
    display: none;
  }

  .mainHhomePage {
    /* margin-top: 37%; */
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 40px;
  }
}


@media screen and (min-width: 1920px) {
  .PHomePageMainSection {
    font-size: clamp(16px, 1.5rem, 2rem);
    gap: 12px;
  }
  .logomainHhomePage {
    width: clamp(50px, 5rem, 7rem);
  }
}

@media screen and (min-width: 2560px) {
  .PHomePageMainSection {
    font-size: clamp(18px, 2rem, 2.5rem);
    max-width: 75vw;
  }
  .logomainHhomePage {
    width: clamp(60px, 6rem, 8rem);
  }
}

@media screen and (min-width: 3840px) {
  .PHomePageMainSection {
    font-size: clamp(20px, 2.5rem, 3rem);
    max-width: 65vw;
  }
  .logomainHhomePage {
    width: clamp(70px, 7rem, 9rem);
  }
}

@media screen and (min-width: 5120px) {
  .PHomePageMainSection {
    font-size: clamp(22px, 3rem, 3.5rem);
    max-width: 60vw;
  }
  .logomainHhomePage {
    width: clamp(80px, 8rem, 10rem);
  }
}

@media screen and (min-width: 7680px) {
  .PHomePageMainSection {
    font-size: clamp(24px, 3.5rem, 4rem);
    max-width: 50vw;
  }
  .logomainHhomePage {
    width: clamp(100px, 10rem, 12rem);
  }
}