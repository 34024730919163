.GambotAiForm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}

/* ✅ מסגרת עם צל קל לטופס */
.GambotAiForm__form {
    width: 100%;
    max-width: 1300px;
    padding: 30px;
 /* background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e3e3e3; */
}

/* ✅ שיפור כותרת */
.GambotAiForm__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 10px 20px;
    position: relative;
    margin: 0 auto;
}

/* ✅ עיצוב הכותרת */
.GambotAiForm__header h2 {
    font-size: 36px;
    font-weight: bold;
    color: var(--LogoColor);
    flex-grow: 1;
    text-align: center;
}

/* ✅ שיפור מראה השדות */
.GambotAiForm__botName,
.GambotAiForm__botDescription,
.GambotAiForm__botPurpose,
.GambotAiForm__knowledgeBase,
.GambotAiForm__qnaPairs,
.GambotAiForm__botBehavior,
.GambotAiForm__language { 
    margin-bottom: 20px;
}

/* ✅ עיצוב לכל שדות הקלט */
.GambotAiForm__botName input,
.GambotAiForm__botDescription textarea,
.GambotAiForm__botPurpose select,
.GambotAiForm__knowledgeBase input,
.GambotAiForm__qnaPairs input,
.GambotAiForm__qnaPairs textarea,
.GambotAiForm__language select {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: "Varela Round", sans-serif;
    font-size: 16px;
    transition: 0.3s;
}

/* ✅ אפקט קליק לשדות */
.GambotAiForm__botName input:focus,
.GambotAiForm__botDescription textarea:focus,
.GambotAiForm__botPurpose select:focus,
.GambotAiForm__knowledgeBase input:focus,
.GambotAiForm__qnaPairs input:focus,
.GambotAiForm__qnaPairs textarea:focus,
.GambotAiForm__language select:focus {
    border-color: #295F98;
    outline: none;
    box-shadow: 0 0 5px rgba(41, 95, 152, 0.3);
}

/* ✅ עיצוב השאלות ותשובות */
.GambotAiForm__qnaItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.GambotAiForm__qnaItem input,
.GambotAiForm__qnaItem textarea {
    margin-bottom: 5px;
}

/* ✅ כפתורים עם אפקט מודרני */
.gambotaiform-remove-btn,
.gambotaiform-add-btn {
    width: 170px;
    height: 48px;
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;
}

/* ✅ כפתור הסרה */
.gambotaiform-remove-btn {
    background: rgba(255, 0, 0, 0.2);
    color: #D84040;
    display: block;
    margin: 20px auto;
    border-color: #D84040;
}

.gambotaiform-remove-btn:hover {
    background: rgba(255, 0, 0, 0.4);
    color: white;
}

/* ✅ כפתור הוספה */
.gambotaiform-add-btn {
    background: rgba(0, 123, 0, 0.2);
    color: #008000;
    border-color: #008000;
}

.gambotaiform-add-btn:hover {
    background: rgba(0, 123, 0, 0.4);
    color: white;
}

/* ✅ כפתור שליחה */
.GambotAiForm__submit {
    background-color: #295F98;
    color: white;
    padding: 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    transition: background 0.3s ease-in-out;
}

.GambotAiForm__submit:hover {
    background-color: #578FCA;
}

/* ✅ שיפור כפתור האייקון */
.IconButtonGambotAi {
    margin-left: 10px;
    padding: 8px;
    font-size: 18px;
    color: #295F98;
    transition: color 0.3s ease-in-out;
}
    
.IconButtonGambotAi:hover {
    color: #578FCA;
}

/* ✅ עיצוב הוראות לבוט */
.GambotAiForm__botInstructions {
    margin-bottom: 15px;
}

.GambotAiForm__botInstructions textarea {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    height: 120px;
    font-size: 16px;
}

/* ✅ התאמה למובייל */
@media (max-width: 768px) {
    .GambotAiForm__form {
        padding: 20px;
    }

    .GambotAiForm__header h2 {
        font-size: 28px;
    }

    .GambotAiForm__submit {
        font-size: 16px;
    }

    .gambotaiform-remove-btn,
    .gambotaiform-add-btn {
        width: 100%;
    }
}
