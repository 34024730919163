.activity-log-form-container {
    margin-top: 20px;
  }
  
  .activity-log-form-paper {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }
  
  .activity-log-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .MuiTextField-root {
    margin-bottom: 20px;
  }
  .activity-log__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 10px 20px;
    margin: 0 auto;
  }
  .activity-log__header h1{
    font-size: 36px;
    font-weight: bold;
    color: var(--LogoColor);
    flex-grow: 1;
    text-align: center;
  }
  @media (max-width: 768px) {
    .activity-log-form-container {
      padding: 10px;
    }
  }
  