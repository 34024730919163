/* General form param content styles */
.form-param-content {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  position: relative; /* Ensure relative positioning for the delete button */
}

.form-param-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-param-header-select-type {
  width: 100%;
  padding: 8px 12px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
}
.form-param-header-select-wrapper {
  position: relative;
  width: 100%;
}

.form-param-header-select-wrapper::after {
  content: '▼';
  position: absolute;
  top: 60%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
  font-size: 12px;
}

/* Aligns Required label, toggle switch, and delete button in one row */
.form-param-footer {
  display: flex;
  justify-content: space-between; /* Spread the items across the row */
  align-items: center;
  margin-top: 20px;
}

/* Group Required label and switch together */
.form-param-footer-left {
  display: flex;
  align-items: center;
}

.form-param-required-label {
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}

.react-switch {
  margin-left: 10px;
}
.formParamContent-delete-option-icon{
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  padding: 5px;
  display: flex;
  align-items: center;
}
/* Position delete button at the right end */
.form-param-content-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  /* color: #555; */
  color: #000;
  padding: 5px;
  display: flex;
  align-items: center;
}

.form-param-content-delete-button:hover {
  color: darkred;
}

/* Additional styling remains unchanged */
.form-param-content textarea,
.form-param-content input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
}

.form-param-content-inline-image-upload {
  display: flex;
  margin-top: 10px;
}

.form-param-content-inline-image-input {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  font-family: inherit;
  cursor: pointer;
}

/* Option container styling */
.formParamContent-options-container {
  margin-top: 10px;
}

.formParamContent-options-container span {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.formParamContent-option-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.formParamContent-option-item input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
}



.formParamContent-add-option-button {
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
  color: #000;
  font-family: inherit;
}

.formParamContent-add-option-button i {
  margin-right: 5px;
}

.formParamContent-add-option-button {
  background-color: #007bff;
  color: #fff;
}

.custom-date-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.formParamContent-label {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.formParamContent-options-container {
  margin-top: 10px;
}

.formParamContent-option-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.formParamContent-option-input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.formParamContent-option-item-delete-button {
  background: none;
  border: none;
  margin-left: 10px;
  color: #000;
  cursor: pointer;
}
.form-param-content-inline-image-upload {
  display: flex;
  flex-direction: column;
}

.form-param-content-image-input {
  margin-bottom: 10px; /* Adds space between the two input fields */
  width: 100%;
}

.form-param-content-inline-image-input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.form-param-content-image-height {
  display: flex;
  align-items: start;
}

.form-param-content-image-height-label {
  margin-right: 10px;
}

.form-param-content-inline-image-height-input {
  width: 100px; /* Adjust width as needed */
  padding: 5px;
  box-sizing: border-box;
}
.form-param-content-image-height-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the left side */
}

.form-param-content-image-height {
  display: flex;
  align-items: center; /* Align label and input horizontally */
  margin-bottom: 10px;
}

.form-param-content-image-height-label {
  margin-right: 10px; /* Space between label and input */
}

.form-param-content-inline-image-height-input {
  max-width: 100px; /* Adjust input width */
}

.form-param-content-file-info {
  display: flex;
  flex-direction: column; /* Stack spans vertically */
  align-items: flex-start; /* Align to the left */
}

.file-info-text {
  color: #6c757d; /* Adjust text color */
  font-size: 0.875rem; /* Adjust font size */
}
/* Container for the paragraph content */
.form-param-content-paragraph-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

/* Make label and input fields inline */
.form-param-content-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Add space between label and input */
}

/* Input fields styling */
.form-param-content-paragraph-container input[type="text"] {
  width: 70%; /* Adjust width to fit the layout */
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Character count for input fields */
.form-param-content-paragraph-char-count {
  color: #555;
  font-size: 0.875rem;
  margin-left: 5px;
}

/* Label styling */
.form-param-content-inline label {
  width: 20%; /* Adjust to fit layout */
  color: #333;
  font-weight: bold;
}
/* Container for the short answer content */
.form-param-content-short-answer-text-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

/* Make label and input fields inline */
.form-param-content-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Add space between label and input */
}

/* Input fields styling */
.form-param-content-short-answer-text-input-container input[type="text"] {
  width: 70%; /* Adjust width to fit the layout */
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Character count for input fields */
.form-param-content-short-answer-text-char-count {
  color: #555;
  font-size: 0.875rem;
  margin-left: 5px;
}

/* Label styling */
.form-param-content-inline label {
  width: 20%; /* Adjust to fit layout */
  color: #333;
  font-weight: bold;
}

/* Group Required label, Send On Change toggle, and delete button in one row */
.form-param-footer {
  display: flex;
  justify-content: space-between; /* Spread the items across the row */
  align-items: center;
  margin-top: 20px;
}

/* Group Required label, Send On Change toggle together */
.form-param-footer-left {
  display: flex;
  align-items: center;
}

.form-param-required-label,
.form-param-send-onchange-label {
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  /* margin-right: 200px;  */
}

.react-switch {
  margin-left: 10px;
  margin-right: 20px; /* Space between switch and next label */
}

/* Position delete button at the right end */
.form-param-content-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  padding: 5px;
  display: flex;
  align-items: center;
}

.form-param-content-delete-button:hover {
  color: darkred;
}
.form-param-content-inline select {
  flex-grow: 1; /* Allow inputs to expand and align properly */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
}
