.payment-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    box-sizing: border-box; 
    text-align: right; 
}

.payment-container-header {
    border-bottom: 1px solid #9DC08B; 
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.payment-container-plan-info,
.payment-container-sim-price,
.payment-container-total-amount {
    font-size: 18px;
    margin: 10px 0;
    text-align: right;
    direction: rtl;
    display: flex;
    align-items: center;
    gap: 8px; 
    
}

.checkmark-icon {
    font-size: 22px;
    margin-left: 5px; 
    flex-shrink: 0;
    color: #A0C878;
}


.payment-container-plan-info {
    font-size: 18px;
    margin: 10px 0;
    text-align: right; 
    direction: rtl; 
}

.payment-container-plan-info u {
    direction: ltr;
    display: inline; 
    text-align: right; 
}


.payment-container-purchase-btn {
    font-family: "Varela Round", sans-serif;
    background-color: #578E7E;
    color: white;
    padding: 12px 30px;
    font-size: 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; 
}

.payment-icon {
    font-size: 24px; 
    flex-shrink: 0; 
}


.payment-container-purchase-btn:hover {
    background-color: #86A788;
}

.payment-container-purchase-btn:active {
    transform: scale(0.96);
}

.payment-container-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    gap: 10px;
    font-family: "Varela Round", sans-serif;
}

.payment-container-next-button,
.payment-container-prev-button {
    padding: 12px 30px;
    font-size: 16px;
    background-color: #578E7E;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 100px;
    width: 48%;
}

.payment-container-next-button:hover,
.payment-container-prev-button:hover {
    background-color: #86A788;
}

.payment-container-next-button:active,
.payment-container-prev-button:active {
    transform: scale(0.96);
}



.payment-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .payment-container input,
  .payment-container select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  
  @media (max-width: 600px) {
    .payment-container {
        padding: 15px;
    }

    .payment-container-button-container {
        flex-direction: column;
        align-items: stretch;
    }

    .payment-container-next-button,
    .payment-container-prev-button {
        width: 100%; 
        margin-bottom: 10px;
    }
}











.payment-container {
    text-align: center;
    padding: 20px;
  }
  
  .payment-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .payment-button:hover {
    background-color: #0056b3;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  