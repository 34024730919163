
.blog-post-page {
  width: 80%;
  padding-top: 80px; 
  margin: 0 auto;
  padding-inline: 20px;
  font-family: "Varela Round", sans-serif;
  direction: rtl;
  box-sizing: border-box;
}

.blog-post-page {
  scroll-margin-top: 100px;
}

.post-full-content p {
  margin: 10px 0;
  line-height: 1.6;
  font-size: 16px;
}

  .breadcrumb {
    margin-bottom: 20px;
    font-size: 14px;
    color: #666;
  }
  .breadcrumb span {
    cursor: pointer;
    color: #336D82;
  }
  .breadcrumb .current {
    color: #999;
    pointer-events: none;
  }
  
  .post-content-wrapper h1 {
    font-size: 28px;
    color: #333;
  }
  
  .post-meta {
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
  }

  .post-full-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 20px 0;
    max-width: 100%; /* חשוב מאוד */
  }
  
  
  .post-description {
    font-size: 18px;
    line-height: 1.8;
    color: #444;
  }
  
  .post-tags {
    margin-top: 20px;
  }
  .post-tags .tag {
    display: inline-block;
    background-color: #e3f2fd;
    padding: 6px 10px;
    margin: 5px;
    border-radius: 20px;
    font-size: 14px;
    color: #336D82;
  }
  
  .back-button {
    margin-top: 30px;
    background-color: #336D82;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    cursor: pointer;
  }
  