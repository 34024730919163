/* Add this CSS to style the FooterText component */
.footer-text-container {
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    text-align: left;
  }
  
  .footer-text-label {
    font-weight: bold;
    
  }
  
  .Footer-Text {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Style the input when it's focused */
  .Footer-Text:focus {
    outline: none;
    border-color: #007bff; /* Add your desired focus color */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a box shadow for focus effect */
  }
  .footer-text-p{
    color: gray;
    font-size: x-small;
    margin-top: 2px;
  }