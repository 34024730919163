.onboarding-process-container {
    margin: 0 auto;
    min-height: 100vh;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center; /* ממקם את האלמנטים במרכז לרוחב */
    justify-content: flex-start; /* מתחיל את האלמנטים מהחלק העליון של הדף */
    font-family: "Varela Round", sans-serif;
    position: relative; /* מונע בעיות של כיסוי */
    overflow: visible; /* מוודא שהתוכן לא מוסתר */
}

.onboarding-process-title {
    font-size: 2rem;
    color: #004a5e;
    font-weight: bold;
    font-family: "Varela Round", sans-serif;
    margin-top: 80px; /* מוודא שהכותרת לא צמודה מדי לראש הדף */
    padding: 10px 0; /* מוסיף ריווח כדי שלא תימחק */
    position: relative; /* מוודא שהיא מעל אלמנטים אחרים */
    z-index: 10; /* מעלה את הכותרת מעל כל תוכן אחר */
}

.onboarding-process-step-content {
    margin-top: 20px; /* נותן קצת ריווח אחרי הכותרת */
}
