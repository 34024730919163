.chat-widget-list-container {
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
}

.chat-widget-list-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.chat-widget-list-header {
    color: var(--LogoColor); /* Adjust color as needed */
    font-size: 40px;
    margin-bottom: 20px;
}

.chat-widget-list-table-container {
    border-radius: 8px;
    max-height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.search-bar {
    margin-bottom: 20px;
    margin-right: 20px;
}

.chat-widget-list-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures the table does not exceed container width */
}

.chat-widget-list-table th,
.chat-widget-list-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word; /* Ensures content wraps within cells */
}

.chat-widget-list-table th {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #f4f4f4;
    z-index: 2;
    padding: 12px;
    font-weight: bold;
    text-align: left;
}

.widget-edit-button,
.widget-delete-button {
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 0.8em;
    margin: 2px;
}

.widget-edit-button {
    background-color: rgba(0, 104, 239, 0);
    color: #3674B5;
    font-size: 20px;
}

.widget-delete-button {
    background-color: #c8233300;
    color: #A31D1D;
    font-size: 20px;
}

.add-new-widget-button {
    color: rgb(255, 255, 255);
    background-color: #295F98;
    padding: 8px 20px;
    margin: 0;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-decoration: none;
    margin-bottom: 2%;
}

.add-new-widget-button:hover {
    color: #ffffff;
    background-color: #578FCA;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.chat-widget-list-table-container::-webkit-scrollbar {
    width: 7px;
}

.chat-widget-list-table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.chat-widget-list-table-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    min-height: 15%;
}

.chat-widget-list-table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 768px) {
    .chat-widget-list-container {
        width: 100%;
        padding: 10px;
    }
}
