
.IntroductionFormContainer-IntroductionForm {
    font-family: "Varela Round", sans-serif;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    width: 90%;
    border-radius: 25px;
    /* border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 0 auto;
  }

  .IntroductionFormContainer-IntroductionForm {
    background: linear-gradient(-45deg, #F5EEE6, #F6D6D6, #F3D7CA, #E6A4B4);
    background-size: 400% 400%;
    animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}




  .IntroductionHeader {
    margin-top: 3%;
    font-size: 20px;
    font-weight: 400;
    color: #777;
    margin-bottom: 10px;
  }

  .IntroductionTitle {
    direction: rtl;
    font-size: 36px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }

  .IntroductionSubtitle {
    direction: rtl;
    font-size: 16px;
    font-weight: 300;
    color: #555;
    margin-bottom: 30px;
  }

  .IntroductionForm {
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .InputField-IntroductionForm {
    direction: rtl;
    font-family: "Varela Round", sans-serif;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
  }

  .SubmitButton-IntroductionForm {
    font-family: "Varela Round", sans-serif;
    padding: 10px 20px;
    background-color: #16404D;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 100%; 
  }

  .SubmitButton-IntroductionForm:hover {
    background-color: #A6CDC6;
    color: #000;
  }




  @media (max-width: 768px) {
    .IntroductionFormContainer-IntroductionForm {
      width: 80%;
      padding: 15px;
      border-radius: 20px;
    }
  
    .IntroductionHeader {
      font-size: 16px;
      margin-bottom: 8px;
    }
  
    .IntroductionTitle {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    .IntroductionSubtitle {
      font-size: 14px;
      margin-bottom: 20px;
    }
  
    .IntroductionForm {
      flex-direction: column; /* סידור אנכי */
      gap: 15px;
    }
  
    .InputField-IntroductionForm {
      width: 80%; /* גודל מלא */
      font-size: 14px;
      padding: 8px;
    }
  
    .SubmitButton-IntroductionForm {
      width: 90%; /* גודל מלא */
      font-size: 14px;
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .IntroductionFormContainer-IntroductionForm {
      width: 80%;
      padding: 10px;
    }
  
    .IntroductionHeader {
      font-size: 14px;
    }
  
    .IntroductionTitle {
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    .IntroductionSubtitle {
      font-size: 12px;
      margin-bottom: 15px;
    }
  
    .InputField-IntroductionForm {
      font-size: 12px;
      padding: 8px;
    }
  
    .SubmitButton-IntroductionForm {
      font-size: 12px;
      padding: 8px;
    }
  }
  