.button-options {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.button-options h2 {
    margin-bottom: 0; /* Reduced from 5px */
    color: var(--LogoColor);
    text-align: left;
}

.button-text-p {
    text-align: left;
    margin-top: 0; /* Reduced from -5px */
    color: gray;
    font-size: small; /* Adjusted from x-small for better readability */
    margin-top: 5px; /* Adjust top margin for better spacing */
}

.add-button-section {
    display: flex;
    flex-direction: column; /* Changed to column to stack fields */
    gap: 10px; /* Adds space between elements */
    width: 100%; /* Ensure full width */
}

.button-options select,
.button-options input,
.button-options-add-button {
    width: 100%; /* Ensures all elements take 100% width */
    height: 40px; /* Example fixed height for all elements */
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
    margin-right: 0; /* Remove margin-right as we're using full width */
}

.phone-number-example {
    font-size: 14px;
    color: #888; /* Light grey */
    margin-top: 5px;
    font-style: italic;
}

.button-options-add-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 0; /* Adjust padding to control height */
    margin-top: 10px; /* Margin between button and inputs */
}

/* Responsive button sizing */
@media (max-width: 768px) {
    .button-options-add-button {
        width: 100%;
        margin-top: 10px; /* Stack button under the inputs on smaller screens */
    }
}

.buttons-list {
    list-style: none;
    padding: 0;
    width: 100%;
}

.button-item {
    display: flex;
    justify-content: space-between; /* Space out label and remove button */
    align-items: center;
    margin-bottom: 10px;
}

.button-options-remove {
    padding: 5px 15px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* For the URL section */
.button-options-url-div {
    display: flex;
    flex-direction: column; /* Stack the URL inputs */
    gap: 10px; /* Space between URL input and example input */
}

.button-options-url-div-options {
    width: 100%; /* Make select field full width */
}

.button-options-url-field {
    width: 100%; /* Ensure the URL input field takes 100% width */
}

.button-options-example-field {
    width: 100%; /* Ensure the dynamic string input field takes 100% width */
}
