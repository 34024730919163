.new-message-template-container {
  max-width: 800px; /* ניתן לשנות את הרוחב המקסימלי */
  padding: 20px;
  display: flex;
  flex-direction: column; /* מסדר את האלמנטים אנכית */
  align-items: center; /* ממרכז את האלמנטים בתוך הדיב */
  position: absolute;
  top: 40%;
  left: 55%;
  transform: translate(-50%, -50%); /* מעביר את האלמנט לאמצע */
}

  
  .new-message-template-form {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
  }
  
  .new-message-template-container h1 {
    color: var(--LogoColor);
  }
  
  .template-name-input,
  .category-button,
  .LookUp__control,
  .new-message-template-action-buttons-cancel,
  .new-message-template-action-buttons-continue {
    width: 100%; /* Set all elements to 100% width */
    padding: 10px;
    margin-bottom: 20px;
    /* border: 1px solid var(--LogoColor); */
    border-radius: 5px;
    font-size: 16px;
  }
  .template-name-input{
    width: 97%;
  }
  .category-selection {
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .category-button {
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    padding: 10px 30px; /* Increase the padding to make them wider */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    flex-grow: 1;
    margin: 0 5px;
    color: var(--LogoColor);
  }
  
  .category-button.active {
    background-color: #e7e7e7;
    border-color: #007bff;
    color: #007bff;
  }
  
  .new-message-template-action-buttons {
    display: flex;
    gap: 15px; 
    justify-content: flex-end;
    margin-right: 15px; 
  }
  
  .language-selection {
    margin-top: 1%;
    text-align: left;
    width: 99%;
    padding-left: 5px;
  }
  
  
  
  .new-message-template-action-buttons-cancel {
    background-color: #ccc;
    color: #333;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    margin-right: 20px; 
  }

  .new-message-template-action-buttons-continue {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
    margin-left: 10px;
  }

  .new-message-template-container-input-container {
    position: relative;
  }
  
  .new-message-template-container-input-hint {
    font-size: 12px;
    color: gray;
    display: block;
    margin-top: -15px;
    margin-bottom: 10px;
    text-align: left;
  }
  .new-message-template-container-error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }

  @media (max-width: 600px) {
   .new-message-template-container{
    left: 500px;
   }
}
