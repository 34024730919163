.condition-action {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* .condition-groups .condition-group-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
} */

.group-relation-select {
  margin-left: 10px;
}

.add-group-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.condition-scopes {
  display: flex;
  justify-content: space-between;
}

.scope {
  flex: 1;
  margin-right: 20px;
}

.scope:last-child {
  margin-right: 0;
}

.scope h4 {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif !important;
}

.scope-action {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
}

.scope button {
  background-color: #5C8374;
  font-family: 'Outfit', sans-serif !important;
  margin-top: 15px;
  margin-right: 10px;
  padding: 8px 14px;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.condition-action-add-action {
  background-color: #2D9596;
}

/* .condition-action-add-action:hover {
  color: var(--LogoColor);
  background-color: #80d5be;
} */
.condition-group-add-action,.condition-action-add-group-wrapper{
  background-color: #5C8374;

}
/* .condition-group-add-action:hover{
  background-color: #9EC8B9; 
  color: var(--LogoColor);
}
.condition-action-add-group-wrapper:hover{
  background-color: #9EC8B9; 
  color: var(--LogoColor);
} */
/* .delete-action-button{
  background-color: #dc3545;
} */
.condition-group-remove-action{
  background-color: #dc3545;
}