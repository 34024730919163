

.login-page {
  font-family: "Varela Round", sans-serif;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-name {
  direction: rtl;
  --_margin: 15px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  font-size: 2.2em;
  font-weight: 700;
  padding-inline: 1%;
  gap: var(--_margin); 
  white-space: nowrap; 
}

.login-name::before,
.login-name::after {
  content: "";
  width: 350px; 
  border: 1px solid #000;
  flex-shrink: 0; 
}



.emailrecoverylogin {
  background-color: #ffffff4c;
  width: 400px; 
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  font-family: "Varela Round", sans-serif;
  text-align: right; 
}

.login-page-inputs input[type="email"], .login-page-inputs input[type="password"] {
    background-color: #ffffff4c;
    width: 400px; 
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;
    font-family: "Varela Round", sans-serif;
    text-align: right; 
}
.login-page-inputs input[type="email"] :focus, .login-page-inputs input[type="password"] :focus{
    border-color: #640D5F;
    box-shadow: 0 0 5px #640D5F;
    background: #fff;
    font-family: "Varela Round", sans-serif;
}

.personal-area-btn {
    font-family: "Varela Round", sans-serif;
    display: inline-block;
    padding: 12px 24px;
    background: #2D336B;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .personal-area-btn:hover {
    background-color: #7886C7;
    transform: scale(1.05); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    color: black;
  }
  
  .personal-area-btn:active {
    transform: scale(0.95); 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  

  .Email-recovery {
    margin-top: 2%;
    font-family: "Varela Round", sans-serif;
    display: inline-block;
    padding: 12px 24px;
    background: #2D336B;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .Email-recovery:hover {
    background-color: #7886C7;
    transform: scale(1.05); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    color: black;
  }
  
  .Email-recovery:active {
    transform: scale(0.95); 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  

.forgot-password, .back-to-login {
    color: #000000a3;
    margin-top: 20px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    transition: color 0.3s ease, transform 0.3s ease; 
    font-family: "Varela Round", sans-serif;
    text-decoration: none;
    display: inline-block; 
    line-height: 1; 
    font-family: "Varela Round", sans-serif;
}

.forgot-password:hover, .back-to-login:hover {
    color: #000000;
    text-decoration: none;
    transform: scale(1.1); 
}


.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fbf4fb;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .personal-area-btn:disabled {
    cursor: not-allowed;
  }
  

  .Email-recovery:disabled {
    background: #ada798; 
    cursor: not-allowed;
  }
  


  .login-container-error-message {
    color: #d32f2f;
    font-size: 14px;
    margin: 5px 0 0 0;
  }
  

  .login-container-general-error-message {
    color: #d32f2f;
    font-size: 16px;
    margin: 10px 0 0 0;
    text-align: center;
  }
  









  @media (max-width: 390px) {
    .emailrecoverylogin,
    .login-page-inputs input[type="email"],
    .login-page-inputs input[type="password"] {
      width:30%; /* התאמת רוחב ל-85% מהרוחב הזמין */
      padding: 10px; /* ריווח פנימי קטן יותר */
      font-size: 14px; /* הקטנת גופן */
      margin: 8px auto; /* יישור מרכזי ומרווח קטן יותר */
    }
  
    .personal-area-btn,
    .Email-recovery {
      width: 10%; /* התאמת רוחב הכפתורים */
      font-size: 14px; /* גודל טקסט מתאים */
      padding: 10px; /* ריווח פנימי מותאם */
    }
  }
  

  
  @media (min-width: 430px) {
    .emailrecoverylogin,
    .login-page-inputs input[type="email"],
    .login-page-inputs input[type="password"] {
      width:40%; /* התאמת רוחב ל-85% מהרוחב הזמין */
      padding: 10px; /* ריווח פנימי קטן יותר */
      font-size: 14px; /* הקטנת גופן */
      margin: 8px auto; /* יישור מרכזי ומרווח קטן יותר */
    }
  
    .personal-area-btn,
    .Email-recovery {
      width: 20%; /* התאמת רוחב הכפתורים */
      font-size: 14px; /* גודל טקסט מתאים */
      padding: 10px; /* ריווח פנימי מותאם */
    }
  }
  
