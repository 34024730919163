.template-list-container {
  margin: 20px;
  padding: 20px;
  border-radius: 8px; 
}


.template-list-container input{
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.template-list-search-bar {
  margin-bottom: 20px;
  margin-right: 20px;
}

.template-list-search-bar input{
   font-size: 15px;
}

.template-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; 
}

/* מסירים קו תחתון מהשורה האחרונה */
.template-list-table tbody tr:last-child td {
  border-bottom: none;
}

/* עיגול לפינות חיצוניות */
.template-list-table thead th:first-child {
  border-top-left-radius: 8px;
}

.template-list-table thead th:last-child {
  border-top-right-radius: 8px;
}

.template-list-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.template-list-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

/* כותרות הטבלה */
.template-list-table thead th {
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  background-color: #f4f4f4; /* שמירה על רקע */
  z-index: 2; /* מבטיח שהכותרות יהיו מעל הנתונים */
  padding: 12px;
  font-weight: bold;
  text-align: left;
}

/* תאים בטבלה */
.template-list-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;  /* שובר מילים ארוכות */
  overflow-wrap: break-word; /* מאפשר למילים ארוכות להישבר */
  white-space: normal;  /* מונע התנהגות של טקסט בשורה אחת */
  max-width: 200px; /* מגדיר רוחב מקסימלי למניעת גלישה */
}

.template-list-table-container {
  border-radius: 8px; /* עיגול פינות */
  max-height: 540px; 
  overflow-y: auto; 
  overflow-x: hidden;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd; 
  border-bottom: 1px solid #ddd; 
  border-top: 1px solid #ddd; 
}

.template-list-header {
  color: var(--LogoColor); 
  font-size: 40px;
  margin-bottom: 20px;
}

.template-list-table-container::-webkit-scrollbar {
  width: 7px;
}

.template-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

.template-list-table-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
  min-height: 15%;
}

.template-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



.template-edit-button,
.template-delete-button,
.template-play-button,
.template-result-button {
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.8em;
  margin: 2px;
}

.template-scheduled-button {
  border: none;
  cursor: pointer;
  margin-right: 5px;
  font-size: 0.8em;
  margin: 2px;
}

.template-result-button {
  background-color: var(--LogoColor);
}

.template-edit-button {
  background-color: rgba(0, 104, 239, 0); 
  color: #3674B5;
  font-size: 20px;
}

.template-delete-button {
  background-color: #c8233300; 
  color: #A31D1D;
  font-size: 20px;
}



.template-play-button {
  background-color: rgb(0, 173, 0)
}



.templates-action-buttons {
  display: flex;
  justify-content: flex-start; 
  padding-left: 0; 
}

.add-new-template-button {
  color: rgb(255, 255, 255);
  background-color: #295F98;
  padding: 8px 20px;
  margin: 0; /* מסיר רווחים נוספים */
  border: none;
  border-radius: 8px; /* מסיר את הפינות המעוגלות */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  text-decoration: none;
  margin-bottom: 6%;
}


.add-new-template-button:hover {
  color: #ffffff;
  background-color: #578FCA;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}


.add-new-template-button svg {
  font-size: 20px; /* התאמת גודל האייקון */
}

.add-new-template-button {
  text-decoration: none; /* ביטול קו תחתון */
}


.templates-action-buttons a {
  text-decoration: none;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 30px; 
  padding: 0 6px;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.status-badge.approved {
  background-color: rgba(0, 128, 0, 0.714);
}

.status-badge.pending {
  background-color: yellow;
  color: black;
}

.status-badge.rejected {
  background-color: red;
}

  .status-rejected-reason-tooltip {
    position: relative;
    display: inline-block;
    margin-left: 8px;
  }
  
  .status-rejected-tooltip-icon {
    font-size: 12px;
    color: #ff6f61;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #ff6f61;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .status-rejected-tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .status-rejected-reason-tooltip:hover .status-rejected-tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  