.new-form-flow-container {
  width: 90%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  font-family: 'Outfit', sans-serif !important;
}

.form-flow-name-inputform-flow-name-input{
  font-family: 'Outfit', sans-serif !important;
  font-size: 16px;
}

.new-form-flow-container label {
  display: block;
  margin-bottom: 5px;
  color: #2f6477;
}

.new-form-flow-container input,
.new-form-flow-container select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
} 

.new-form-flow-container label {
  text-align: left;
  font-weight:500;
  display: inline-block; 
}


.new-form-flow-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  padding: 10px 20px;
}

.new-form-flow-action-buttons-go-back {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.new-form-flow-header {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: var(--LogoColor);
  flex-grow: 1; 
}


.new-form-flow-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto; 
  padding: 80px;
  background-color: #ffffff; 
  border-radius: 12px; 
  border: 1px solid #e1e1e1; 
  display: flex;
  flex-direction: column;
  gap: 20px;
}



.form-flow-name-input,
.form-flow-endpoint-input,
.form-flow-send-all-answers-select,
.form-flow-webhook-input,
.form-flow-endpoint-type-select {
  font-size: 16px;
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif !important;
}

.form-flow-send-all-answers-select,
.form-flow-endpoint-type-select {
  font-size: 16px;
  margin-left: 0%;
  
}

.form-flow-webhook-input {
  width: 100%;
}

.form-flow-send-all-answers-select{
  align-items: center;
  width: 103% !important;
  padding: 10px;
  font-size: 16px;
}

.new-form-flow-form-category-selection {
  align-items: center;
  width: 102.5%;
  padding: 10px;
  font-size: 16px;
  margin-left: -1%;
}

.new-form-flow-endpoint-type-selection{
  align-items: center;
  width: 102%;
  padding: 10px;
  margin-left: -1%;
}


.new-form-flow-action-buttons {
  margin-top: -5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}



.new-form-flow-action-buttons-cancel,
.new-form-flow-action-buttons-continue {
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Outfit', sans-serif !important;
}

.new-form-flow-action-buttons-cancel {
  background-color: #BF3131;
  color: #ffffff;
}

.new-form-flow-action-buttons-continue {
  background-color: #27548A;
  color: white;
}

.new-form-flow-flow-type-selection {
  text-align: center;
}

.new-form-flow-flow-type-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.new-form-flow-flow-type-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.new-form-flow-flow-type-icon:hover {
  background-color: #fffffd;
  border: 2px dashed #a19389; 
}

.new-form-flow-flow-type-icon p {
  margin-top: 8px;
}

.new-form-flow-flow-type-icon.selected {
  border-color: #c9bbb1; 
  color: #000000; 
  background-color: #fffffd;
}

.new-form-flow-flow-type-selection-label {
  margin-bottom: 3% !important;
}


.new-form-flow-template--selection {
  background-color: white;
  width: 102.5%;
}

.react-select__option--is-selected {
  background-color: #E1EACD;
  color: black;
}

.react-select__option--is-focused {
  background-color: lightgray;
  color: black;
}

.new-form-flow-form-category-selection .react-select__control {
  background-color: white;
  border-color: var(--LogoColor);
}

.new-form-flow-form-category-selection .react-select__menu {
  background-color: white;
}

.new-form-flow-form-category-selection .react-select__option {
  background-color: white;
  color: black;
}

.new-form-flow-form-category-selection .react-select__option--is-focused {
  background-color: lightgray;
}

.new-form-flow-form-category-selection .react-select__option--is-selected {
  background-color: lightblue;
  color: black;
}



.new-form-flow-form label{
  margin-bottom: -3%;
}
